/* eslint-disable eqeqeq */
import React, { useState, Component, useEffect } from "react";
import { Col, Row, Form, Alert } from "react-bootstrap";
import {
  Route,
  Switch,
  Redirect,
  Link,
  NavLink,
  useHistory,
} from "react-router-dom";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import { Validators } from "../../../Utilities/Validator";
import publiclogo from "../../../assests/img/logo-public.png";
import {
  Navbar,
  Button,
  Nav,
  ButtonDropdown,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  NavDropdown,
  Image,
} from "react-bootstrap";
import StartupHeader from "../../../Components/StartupHeader";
export default function TermOfUse() {
  // const { password, confirmPassword } = this.state;

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (event) => {
    setState((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const history = useHistory();
  const navigateTo = () => history.push(`${process.env.PUBLIC_URL}/register`); //eg.history.push('/login');

  useEffect(() => {
    document.body.classList.add("home-page");

    return function cleanup() {
      document.body.classList.remove("home-page");
    };
  }, []);

  return (
    <React.Fragment>
      <StartupHeader />

      <div className="main-content">
        <div className="main-banner">
          <div className="dashboard-wrapper resumes-page">
            <div className="container">
              <div className="dashboard-box dashboard-col-bg public-wrapper outline-form">
                {/* <div className="text-right">
                  <span className="icon icon-close ml-auto black cursor-pointer"></span>
                </div> */}
                <Form noValidate>
                  <Row>
                    <Col md={9} className="mx-auto">
                      <h1 className="mb-5">Terms of Use</h1>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum. <br />
                        <br />
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptatem quia voluptas sit aspernatur aut
                        odit aut fugit, sed quia consequuntur magni dolores eos
                        qui ratione voluptatem sequi nesciunt. Neque porro
                        quisquam est, qui dolorem ipsum quia dolor sit amet,
                        consectetur, adipisci velit, sed quia non numquam eius
                        modi tempora incidunt ut labore et dolore magnam aliquam
                        quaerat voluptatem. Ut enim ad minima veniam, quis
                        nostrum exercitationem ullam corporis suscipit
                        laboriosam, nisi ut aliquid ex ea commodi consequatur?
                        Quis autem vel eum iure reprehenderit qui in ea
                        voluptate velit esse quam nihil molestiae consequatur,
                        vel illum qui dolorem eum fugiat quo voluptas nulla
                        pariatur.
                        <br /> <br />
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptatem quia voluptas sit aspernatur aut
                        odit aut fugit, sed quia consequuntur magni dolores eos
                        qui ratione voluptatem sequi nesciunt. Neque porro
                        quisquam est, qui dolorem ipsum quia dolor sit amet,
                        consectetur, adipisci velit, sed quia non numquam eius
                        modi tempora incidunt ut labore et dolore magnam aliquam
                        quaerat voluptatem. Ut enim ad minima veniam, quis
                        nostrum exercitationem ullam corporis suscipit
                        laboriosam, nisi ut aliquid ex ea commodi consequatur?
                        Quis autem vel eum iure reprehenderit qui in ea
                        voluptate velit esse quam nihil molestiae consequatur,
                        vel illum qui dolorem eum fugiat quo voluptas nulla
                        pariatur.
                        <br /> <br />
                      </p>

                      <Row>
                        <Col md={8} className="square-radius mt-5">
                          <Buttons
                            variant="outline-primary"
                            title="Back"
                            type="submit"
                            size="xs"
                            icon="icon icon-arrow-left mr-4"
                            onClick={navigateTo}
                          ></Buttons>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
