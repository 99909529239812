/* eslint-disable eqeqeq */

import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  InputGroup,
  FormControl,
  Form,
  Badge,
} from "react-bootstrap";
import SupportConnectionList from "../../../Components/SupportConnectionList";
import {
  getConnectionsList,
  getConnectionsResponses,
} from "../../../Services/SupportConnectionsService";
import alphabates from "../../../Components/AlphabatesSorting";
import ListPagination from "../../../Components/Pagination";
import { FaCommentAlt, FaSearch } from "react-icons/fa";
import { supportConnectionsPerPage } from "../../../Utilities/Helper";
import { toast } from "react-toastify";
import { SortingAlphabate } from "../../../Components/Shared/SortingAlphabate";

export default function SeekersConnections() {
  const [connection, setConnections] = useState([]);
  const [totalConnection, setTotalConnection] = useState();
  const [totalRequest, setTotalRequest] = useState(null);
  const [totalResponse, setTotalResponse] = useState(null);
  const [percentage, setPercentage] = useState();

  const [currentPage, setCurrentPage] = useState({ value: "", filter: "" });
  const [sort, setSort] = useState({ value: "", filter: "" });
  const [refreshTable, setRefreshTable] = useState(true);
  const [connectionResponses, setConnectionResponses] = useState([]);
  const [filters, setFilters] = useState("");
  const [showPagination, setShowPagination] = useState(true);

  const [showSpinnerForConnection, setShowSpinnerForConnection] =
    useState(true);
  // getting Network list
  useEffect(() => {
    async function connectionData() {
      setShowPagination(true);
      const filter =
        "recordperpage=" +
        supportConnectionsPerPage +
        currentPage.filter +
        sort.filter +
        filters;
      getConnectionsList(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setConnections(result.jobSeekers);
          setTotalConnection(result.totalRecord);
          setTotalResponse(result.responses);
          setTotalRequest(result.requests);
          setPercentage(result.percentage);
          setShowSpinnerForConnection(false);
        })
        .catch((err) => {
          toast.error(err.response, { toastId: "custom_toast" });
          setShowSpinnerForConnection(false);
        });
    }
    connectionData();
  }, [refreshTable]);

  useEffect(() => {
    const connectionsResponses = () => {
      getConnectionsResponses()
        .then((res) => {
          setConnectionResponses(res.result);
        })
        .catch((err) => {
          toast.error(err.response);
        });
    };
    connectionsResponses();
  }, []);

  const refreshTableHandler = () => {
    setShowSpinnerForConnection(true);
    setCurrentPage((prev) => ({ ...prev, filter: "" }));
    setRefreshTable((prev) => !prev);
    setShowPagination(false);
  };

  const sortingChangeHandler = (value) => {
    if (sort.value !== value && value !== "clear") {
      setSort((prev) => ({ value: value, filter: `&sort=${value}` }));
      refreshTableHandler();
    }
    if (sort.value !== value && value === "clear") {
      setSort((prev) => ({ value: value, filter: "" }));
      refreshTableHandler();
    }
  };

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setShowSpinnerForConnection(true);
      setCurrentPage((prev) => ({ ...prev, filter: `&page=${page}` }));
      setRefreshTable((prev) => !prev);
    }
  };

  const handelresponseschange = (e) => {
    const responseid = e.target.value;
    if (responseid == "0") setFilters("");
    else setFilters("&JobSeekerConnectionResponseId=" + responseid);

    refreshTableHandler();
  };

  const refreshHandlerForListing = () => {
    setShowSpinnerForConnection(true);
    setRefreshTable((prev) => !prev);
  };

  return (
    <div className="container p-0">
      <div className="header-with-search">
        <h1 className="page-title">
          Connections{" "}
          <Badge
            pill
            bg="danger"
            className="bg-warning text-white px-3 ml-3 font-weight-normal"
          >
            {totalConnection}
          </Badge>
        </h1>
        {/* <div className="sort w-100">
          <InputGroup className="messages-search float-right">
            <InputGroup.Prepend>
              <InputGroup.Text id="search">
                <FaSearch />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Search"
              aria-label="search"
              aria-describedby="search"
            />
          </InputGroup>
          <Form.Control as="select" className="inputclass float-right search-control w-25 mr-3">
              <option>Sort By</option>
              <option>Name</option>
              <option> Email </option>
              <option> Phone</option>
            </Form.Control>
        </div> */}
      </div>

      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            <div className="dashboard-box dashboard-col-bg sponsership-page network-page">
              <div className="d-flex mb-3">
                {totalResponse != null && (
                  <h6>
                    Network Responses/Requests
                    {"   " + totalResponse + "/" + totalRequest}={percentage}%
                  </h6>
                )}
                <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  style={{ marginLeft: "auto" }}
                >
                  <Form.Control
                    as="select"
                    className="inputclass form-control"
                    onChange={handelresponseschange}
                  >
                    <option value="0">Select Response</option>
                    {connectionResponses.map((item, index) => (
                      <option
                        key={index}
                        value={item.jobSeekerConnectionResponseId}
                      >
                        {item.shortResponse}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="d-flex mb-3">
                <SupportConnectionList
                  responses={connectionResponses}
                  connections={connection}
                  showSpinner={showSpinnerForConnection}
                  refreshTable={refreshHandlerForListing}
                />
                <SortingAlphabate ChangeHandler={sortingChangeHandler} />
              </div>
              <div className="d-flex">
                {connection.length !== 0 && showPagination && (
                  <ListPagination
                    recordPerPage={supportConnectionsPerPage}
                    TotalRecord={totalConnection}
                    className="mt-3"
                    onPageChange={pageChangeHandler}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
// export default SeekersConnections;
