import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import FormInput from "../../../Components/Shared/FormInput";
import { Spinner } from "react-bootstrap";
import {
  putCreateDemoPresentation,
  putResetDemoDatabase,
} from "../../../Services/DemoServices";
import { getCountry, getStateByCountry } from "../../../Services/GetFields";
import { toast } from "react-toastify";
import { ContentBox } from "./ContentBox";
import { getGroups } from "../../../Services/GetFields";
import { fetchNearbyCities } from "../../../utils/cityUtils";
import { ConformationModal } from "../../../Components/ConformationModal";

const BASE_TOOL_URL = process.env.PUBLIC_URL + "/admin/demotools/presentation/";

const DEMO_SETTINGS = {
  construction: {
    industry: "Construction",

    jobseeker_email: "j1",
    jobseeker_name: "Dave Thompson",
    resume_title: "Construction Laborer",

    position_to_network_index: 5,

    stm_email: "s55",
    stm_name: "Robert Watson",
    stm_position_name: "Site Foreman",
    stm_company_name: "Evergreen Builders",

    reset_db_base_id: 97,
  },
  finance: {
    industry: "Finance",
    jobseeker_email: "j3",
    jobseeker_name: "Debbie Thompson",
    resume_title: "Financial Analyst",

    position_to_network_index: 2,

    stm_email: "s6",
    stm_name: "Roger King",
    stm_position_name: "Equity Research Analyst",
    stm_company_name: " Allure Investments",

    reset_db_base_id: 97,
  },
};
const DEMO_SETTINGS_KEYS = Object.keys(DEMO_SETTINGS);

// https://learn.microsoft.com/en-us/azure/ai-services/speech-service/language-support?tabs=tts
const VOICE_OVER_OPTIONS = [
  {
    name: "Default (American Male)",
    value: "en-US-EchoTurboMultilingualNeural",
    sample_url:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/echo_turbo_multilingual_neural.wav",
  },
  {
    name: "American Female 1 (Ava Neural)",
    value: "en-US-AvaNeural",
    sample_url:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/ava_neural.wav",
  },
  {
    name: "American Female 2 (Jane Neural)",
    value: "en-US-JaneNeural",
    sample_url:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/jane_neural.wav",
  },
  {
    name: "American Female 3 (Cora Neural)",
    value: "en-US-CoraNeural",
    sample_url:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/cora_neural.wav",
  },
  {
    name: "American Male 1 (Andrew Neural)",
    value: "en-US-AndrewNeural",
    sample_url:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/andrew_neural.wav",
  },
  {
    name: "American Male 2 (Davis Neural)",
    value: "en-US-DavisNeural",
    sample_url:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/davis_neural.wav",
  },
  {
    name: "American Male 3 (Christopher Neural)",
    value: "en-US-ChristopherNeural",
    sample_url:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/christopher_neural.wav",
  },
];

const MEMBER_NOUNS = ["member", "client", "student", "employee"];

export const AdminDemoCreatePresentation = () => {
  const history = useHistory();
  const { step } = useParams();

  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [demoDetails, setDemoDetails] = useState({
    group_name: "Sample Group",
    member_noun: MEMBER_NOUNS[0],
    voice_over_voice: VOICE_OVER_OPTIONS[0].value,
    using_demo: DEMO_SETTINGS_KEYS[0],
    notify_when_ready_email: "davy@northctrl.com",
  });

  const [locationDemoForm, setLocationDemoForm] = useState({
    country: 0,
    countryList: [],
    countryState: ["0", "0", "0", "0", "0"],
    stateName: [""],
    city: ["", "", "", "", ""],
    countryStateList: [],
    showStateTextBox: false,
    locationType: "multi_state",
  });

  useEffect(() => {
    getGroups()
      .then((res) => {
        const result = JSON.parse(res.response);
        if (result.length > 0) {
          setDemoDetails((old) => ({
            ...old,
            group_name: result[0].groupName,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getCountry()
      .then((res) => {
        const result = JSON.parse(res.response);
        const countryList = result.filter((item) => item.countryId !== 3);
        setLocationDemoForm({ ...locationDemoForm, countryList: countryList });
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const handleStateChange = (key) => (e) => {
    var updatedForm = { ...locationDemoForm };
    updatedForm.countryState[key] = e.target.value;

    // CU-86ay8402f If the first state is selected, then update all the states to match if the city isn't already filled
    if (key === 0) {
      for (let i = 1; i < 5; i++) {
        if (updatedForm.city[i] === "") {
          updatedForm.countryState[i] = e.target.value;
        }
      }
    }

    setLocationDemoForm(updatedForm);
  };

  const handleResetCityChange = (key) => (value) => {
    var updatedForm = { ...locationDemoForm };
    updatedForm.city[key] = value;
    setLocationDemoForm(updatedForm);
  };

  const handleCountryChange = (e) => {
    let countryValue = 0;
    countryValue = e.target.value;
    var index = e.nativeEvent.target.selectedIndex;
    const countryText = e.nativeEvent.target[index].text;

    setLocationDemoForm((prev) => ({
      ...prev,
      countryStateList: [],
      country: countryValue,
      countryState: ["0", "0", "0", "0", "0"],
      stateName: "",
    }));

    if (countryText === "Other")
      setLocationDemoForm((prev) => ({ ...prev, showStateTextBox: true }));
    else setLocationDemoForm((prev) => ({ ...prev, showStateTextBox: false }));

    if (countryValue !== "0") {
      getStateByCountry(countryValue)
        .then((res) => {
          const result = JSON.parse(res.response);
          setLocationDemoForm((prev) => ({
            ...prev,
            countryStateList: result,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const resetDemoSite = (callback) => {
    setShowLoader(true);
    toast.warning("Database reset started...", { autoClose: 2500 });

    // If city is not set then remove state selection as well
    const resetDemoFormApiParam = {
      ...locationDemoForm,
      groupName: demoDetails.group_name,
      subCategoryName: demoDetails.group_name,
      BaseDemoId: DEMO_SETTINGS[demoDetails.using_demo].reset_db_base_id,
    };
    for (let i = 0; i < 5; i++) {
      if (resetDemoFormApiParam.city[i].trim() === "") {
        resetDemoFormApiParam.countryState[i] = "0";
      }
    }

    putResetDemoDatabase({
      ...resetDemoFormApiParam,
    })
      .then((res) => {
        toast.success("Database reset successfully.");
        if (callback && typeof callback === "function") {
          callback();
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        toast.error("Unable to reset demo site.");
        setTimeout(() => setShowLoader(false), 3000);
      });
  };

  const handleUserClickedCreatePresentation = () => {
    setShowLoader(true);

    const createDemoPayload = {
      jobseeker_email: DEMO_SETTINGS[demoDetails.using_demo].jobseeker_email,
      jobseeker_name: DEMO_SETTINGS[demoDetails.using_demo].jobseeker_name,
      stm_email: DEMO_SETTINGS[demoDetails.using_demo].stm_email,
      industry: DEMO_SETTINGS[demoDetails.using_demo].industry,
      group_name: demoDetails.group_name,
      position_to_network_index:
        DEMO_SETTINGS[demoDetails.using_demo].position_to_network_index,
      resume_title: DEMO_SETTINGS[demoDetails.using_demo].resume_title,
      member_noun: demoDetails.member_noun,
      notify_when_ready_email: demoDetails.notify_when_ready_email,
      stm_name: DEMO_SETTINGS[demoDetails.using_demo].stm_name,
      stm_position_name:
        DEMO_SETTINGS[demoDetails.using_demo].stm_position_name,
      stm_company_name: DEMO_SETTINGS[demoDetails.using_demo].stm_company_name,
      voice_over_voice: demoDetails.voice_over_voice,
    };

    putCreateDemoPresentation({ ...createDemoPayload })
      .then((res) => {
        toast.success("Demo Slides Successfully Started.");
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowLoader(false);
        }, 2000);
      })
      .catch((err) => {
        toast.error(
          "Unable to create demo presentation. Please contact support."
        );
        setTimeout(() => setShowLoader(false), 3000);
      });
  };

  const handleAutoPopulateCities = async () => {
    // Check if first city and state are populated
    if (!locationDemoForm.city[0] || locationDemoForm.countryState[0] === "0") {
      toast.warning(
        "Please enter a city and state in the first location fields"
      );
      return;
    }

    const firstCity = locationDemoForm.city[0];

    // Get state name from selected state ID
    const stateId = parseInt(locationDemoForm.countryState[0]);
    const selectedState = locationDemoForm.countryStateList.find(
      (state) => state.stateId === stateId
    );

    if (!selectedState) {
      toast.warning("Please select a valid state from the dropdown");
      return;
    }

    const countryName = locationDemoForm.countryList.find(
      (country) => country.countryId.toString() === locationDemoForm.country
    )?.countryName;

    try {
      setShowLoader(true);
      // Use fetchNearbyCities from cityUtils.js to get nearby cities
      const nearbyCities = await fetchNearbyCities(
        firstCity,
        selectedState.stateName,
        countryName,
        10000, // Min population
        1, // 1 degree radius
        5 // We need 5 additional cities
      );

      if (nearbyCities.length < 1) {
        toast.warning("No nearby cities found in the same state");
        setShowLoader(false);
        return;
      }

      // Remove the first city from the list if it's in the nearby cities
      const firstCityIndex = nearbyCities.findIndex(
        (city) => city.name.toLowerCase() === firstCity.toLowerCase()
      );
      if (firstCityIndex !== -1) {
        nearbyCities.splice(firstCityIndex, 1);
      }

      // Update city fields with results
      const updatedForm = { ...locationDemoForm };
      for (let i = 0; i < Math.min(nearbyCities.length, 4); i++) {
        updatedForm.city[i + 1] = nearbyCities[i].name;

        // Find the state ID that matches the state name returned from the API
        if (nearbyCities[i].state) {
          const matchingState = updatedForm.countryStateList.find(
            (state) =>
              state.stateName.toLowerCase() ===
              nearbyCities[i].state.toLowerCase()
          );

          if (matchingState) {
            updatedForm.countryState[i + 1] = matchingState.stateId.toString();
          } else {
            // If we can't find an exact match, keep the state selection the same as first state
            updatedForm.countryState[i + 1] = updatedForm.countryState[0];
          }
        } else {
          // If no state info was returned, keep the state selection the same as first state
          updatedForm.countryState[i + 1] = updatedForm.countryState[0];
        }
      }

      setLocationDemoForm(updatedForm);
      toast.success(
        "Successfully auto-populated nearby cities with their correct states"
      );
    } catch (error) {
      console.error("Error auto-populating cities:", error);
      toast.error(error.message || "Failed to auto-populate cities");
    } finally {
      setShowLoader(false);
    }
  };

  const handleCombinedAction = () => {
    setShowLoader(true);
    resetDemoSite(() => {
      handleUserClickedCreatePresentation();
    });
  };

  const renderOrganizationDetails = () => {
    return (
      <ContentBox
        headerText="Select The Organization Details"
        nextText="Next"
        disableNext={false}
        onNext={() =>
          history.push(BASE_TOOL_URL + (parseInt(step) + 1).toString())
        }
        rootPage={"presentation/"}
      >
        <p className="mb-0 font-weight-bold">Enter the organization name:</p>
        <Row>
          <Col md={12}>
            <Form.Group controlId="org-name-input">
              <FormInput
                name="group_name"
                value={demoDetails.group_name}
                type="text"
                placeholder="Organization Name"
                maxLength={50}
                required="required"
                disabled={showLoader}
                onChange={(e) => {
                  if (showLoader) return;

                  setDemoDetails({
                    ...demoDetails,
                    group_name: e,
                  });
                }}
              />
            </Form.Group>
            <p className="m-0">
              <span className="font-weight-bold">Note: </span>
              Enter the name of the organization for which you're creating this
              presentation.
            </p>
          </Col>
        </Row>

        <p className="mb-0 mt-4 font-weight-bold">
          Noun for organization member:
        </p>
        <Row>
          <Col md={12}>
            {/* Dropdown group list */}
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                name="org-member-noun-saved-demo"
                value={demoDetails.member_noun}
                disabled={true === showLoader}
                onChange={(e) =>
                  setDemoDetails({
                    ...demoDetails,
                    member_noun: e.target.value,
                  })
                }
                className="inputclass form-control"
              >
                {MEMBER_NOUNS.map((item, index) => (
                  <option key={"omn-" + index} value={item}>
                    {toTitleCase(item)}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <p className="m-0">
              <span className="font-weight-bold">Examples: </span> <br />
              Employed Church of Jesus Christ of Latter-day Saints <b>
                member
              </b>{" "}
              reviews Job Seeker's resume. <br />
              vs <br />
              Employed Upwardly Global <b>client</b> reviews Job Seeker's
              resume. <br />
            </p>
          </Col>
        </Row>
      </ContentBox>
    );
  };

  const renderIndustryDetails = () => {
    return (
      <ContentBox
        headerText="Select Industry Details"
        nextText="Next"
        disableNext={false}
        onNext={() =>
          history.push(BASE_TOOL_URL + (parseInt(step) + 1).toString())
        }
        rootPage={"presentation/"}
      >
        <p className="mb-0 font-weight-bold">Select Industry:</p>
        <Row>
          <Col md={12}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                name="demo-industry-select"
                value={demoDetails.using_demo}
                disabled={true === showLoader}
                onChange={(e) => {
                  setDemoDetails((old) => ({
                    ...old,
                    using_demo: e.target.value,
                  }));
                }}
                className="inputclass form-control"
              >
                {DEMO_SETTINGS_KEYS.map((item, index) => (
                  <option key={"js-" + index} value={item}>
                    {toTitleCase(item)}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <p className="mt-4 mb-0 font-weight-bold">Details</p>
        <Row>
          <Col md={12}>
            <div className="p-3 mt-2 bg-light rounded">
              {Object.entries(DEMO_SETTINGS[demoDetails.using_demo])
                .filter(
                  ([key]) =>
                    !["position_to_network_index", "reset_db_base_id"].includes(
                      key
                    )
                )
                .map(([key, value], index, filteredArray) => (
                  <div key={index} className="mb-2">
                    <Row>
                      <Col md={4} className="font-weight-bold text-capitalize">
                        {key.replace(/_/g, " ").replace(/\bstm\b/gi, "STM")}:
                      </Col>
                      <Col md={8}>{toTitleCase(value)}</Col>
                    </Row>
                    {index < filteredArray.length - 1 && (
                      <hr className="my-2" />
                    )}
                  </div>
                ))}
            </div>
          </Col>
        </Row>
      </ContentBox>
    );
  };

  const renderVoiceSelection = () => {
    return (
      <ContentBox
        headerText="Select Voice Options"
        nextText="Next"
        disableNext={false}
        onNext={() =>
          history.push(BASE_TOOL_URL + (parseInt(step) + 1).toString())
        }
        rootPage={"presentation/"}
      >
        <p className="mb-0 font-weight-bold">
          Select Voice for Presentation Narration:
        </p>
        <Row>
          <Col md={12}>
            <Form.Group controlId="voice-select">
              <Form.Control
                as="select"
                name="voice-over-select"
                value={demoDetails.voice_over_voice}
                disabled={showLoader}
                onChange={(e) => {
                  setDemoDetails((old) => ({
                    ...old,
                    voice_over_voice: e.target.value,
                  }));
                }}
                className="inputclass form-control"
              >
                {VOICE_OVER_OPTIONS.map((voice, index) => (
                  <option key={"voice-" + index} value={voice.value}>
                    {voice.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <p className="mt-4 mb-0 font-weight-bold">Voice Sample</p>
        <Row>
          <Col md={12}>
            <div className="p-3 mt-2 bg-light rounded">
              {VOICE_OVER_OPTIONS.find(
                (voice) => voice.value === demoDetails.voice_over_voice
              )?.sample_url && (
                <div className="text-center">
                  <audio
                    controls
                    src={
                      VOICE_OVER_OPTIONS.find(
                        (voice) => voice.value === demoDetails.voice_over_voice
                      )?.sample_url
                    }
                    className="w-100 mt-2"
                  >
                    Your browser does not support the audio element.
                  </audio>
                  <p className="mt-2 text-muted">
                    Listen to the sample to hear how the selected voice sounds.
                  </p>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <p className="mt-4 mb-2">
          <span className="font-weight-bold">Note: </span>
          This voice will be used for the narration in your presentation. Select
          the one that best represents your organization's tone and style.
        </p>
      </ContentBox>
    );
  };

  const renderMultiStateForm = () => {
    return (
      <>
        <Row>
          <Col md={12}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                name="country"
                value={locationDemoForm.country}
                onChange={handleCountryChange}
                className="inputclass form-control"
              >
                <option value="0">Select Country *</option>
                {locationDemoForm.countryList.map((item) => (
                  <option key={"cl-" + item.countryId} value={item.countryId}>
                    {item.countryName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                className="inputclass form-control"
                name="countryState"
                value={locationDemoForm.countryState[0]}
                onChange={handleStateChange(0)}
              >
                <option value="0">
                  {locationDemoForm.country === 2
                    ? "Province 1 *"
                    : "State 1 *"}
                </option>
                {locationDemoForm.countryStateList.map((item) => (
                  <option key={"csl-" + item.stateId} value={item.stateId}>
                    {item.stateName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6} className="position-relative">
            <div className="d-flex">
              <div className="flex-grow-1">
                <FormInput
                  name="city"
                  value={locationDemoForm.city[0]}
                  type="text"
                  placeholder="City 1 *"
                  maxLength={30}
                  required="required"
                  onChange={handleResetCityChange(0)}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                className="inputclass form-control"
                name="countryState"
                value={locationDemoForm.countryState[1]}
                onChange={handleStateChange(1)}
              >
                <option value="0">
                  {locationDemoForm.country === 2 ? "Province 2" : "State 2"}
                </option>
                {locationDemoForm.countryStateList.map((item) => (
                  <option key={"csl-" + item.stateId} value={item.stateId}>
                    {item.stateName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <FormInput
              name="city"
              value={locationDemoForm.city[1]}
              type="text"
              placeholder="City 2"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(1)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                className="inputclass form-control"
                name="countryState"
                value={locationDemoForm.countryState[2]}
                onChange={handleStateChange(2)}
              >
                <option value="0">
                  {locationDemoForm.country === 2 ? "Province 3" : "State 3"}
                </option>
                {locationDemoForm.countryStateList.map((item) => (
                  <option key={"csl-" + item.stateId} value={item.stateId}>
                    {item.stateName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <FormInput
              name="city"
              value={locationDemoForm.city[2]}
              type="text"
              placeholder="City 3"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(2)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                className="inputclass form-control"
                name="countryState"
                value={locationDemoForm.countryState[3]}
                onChange={handleStateChange(3)}
              >
                <option value="0">
                  {locationDemoForm.country === 2 ? "Province 4" : "State 4"}
                </option>
                {locationDemoForm.countryStateList.map((item) => (
                  <option key={"csl-" + item.stateId} value={item.stateId}>
                    {item.stateName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <FormInput
              name="city"
              value={locationDemoForm.city[3]}
              type="text"
              placeholder="City 4"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(3)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                className="inputclass form-control"
                name="countryState"
                value={locationDemoForm.countryState[4]}
                onChange={handleStateChange(4)}
              >
                <option value="0">
                  {locationDemoForm.country === 2 ? "Province 5" : "State 5"}
                </option>
                {locationDemoForm.countryStateList.map((item) => (
                  <option key={"csl-" + item.stateId} value={item.stateId}>
                    {item.stateName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <FormInput
              name="city"
              value={locationDemoForm.city[4]}
              type="text"
              placeholder="City 5"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(4)}
            />
          </Col>
        </Row>
      </>
    );
  };

  const renderLocationSelection = () => {
    const canReset = () =>
      locationDemoForm.city[0]?.trim() !== "" &&
      locationDemoForm.country !== 0 &&
      (locationDemoForm.countryState[0] !== "0" ||
        locationDemoForm.stateName?.trim() !== "");

    return (
      <ContentBox
        headerText={` Where is ${demoDetails.group_name} located?`}
        nextText="Next"
        disableNext={!canReset()}
        onNext={() =>
          history.push(BASE_TOOL_URL + (parseInt(step) + 1).toString())
        }
        rootPage={"presentation/"}
      >
        <Row>
          <Col md={12}>
            <p className="bp-2">
              The users accounts will be updated to include the state and city
              names that you select here.
            </p>
            <p className="bp-2 font-italic">
              Only 1 location is required but you can add up to 5.
            </p>
            <p className="bp-2">
              If you add more than 1 location, the user accounts will be updated
              with a random location from this list.
            </p>
          </Col>
          <Col md={12} className="my-3">
            <Button
              className="px-4 auto-populate-btn"
              variant="outline-primary"
              onClick={handleAutoPopulateCities}
              disabled={
                showLoader || locationDemoForm.city[0].trim().length <= 0
              }
              title="Auto-populate other cities based on this city"
            >
              {showLoader ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Auto Populate"
              )}
            </Button>

            <p className="text-muted mt-1">
              Click 'Auto Populates' to fill other cities based on the first
              city entered.
            </p>
          </Col>
        </Row>
        {renderMultiStateForm()}
      </ContentBox>
    );
  };

  const renderConfirmDetails = () => {
    return (
      <ContentBox
        headerText="Ready to Create The Presentation?"
        hideNext={true}
        disableNext={true}
        rootPage={"presentation/"}
      >
        <p className="m-0">
          <span className="font-weight-bold">Note: </span>
          Clicking the <i>Create Presentation</i> button will replace the
          current demo site so that we can create the presentation.{" "}
        </p>
        <p>
          <b>
            If you want to save the current demo site, make sure to create a
            backup before continuing.
          </b>
        </p>

        <Button
          type="button"
          className="btn btn-success w-100 mt-lg-5 mb-lg-5"
          disabled={showLoader}
          onClick={handleCombinedAction}
        >
          {showLoader ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Create Presentation"
          )}
        </Button>
      </ContentBox>
    );
  };

  return (
    <Container>
      <h1 className="page-title">Create Demo Presentation</h1>
      <div className="dashboard-wrapper">
        {step === "0" && renderOrganizationDetails()}
        {step === "1" && renderLocationSelection()}
        {step === "2" && renderVoiceSelection()}
        {step === "3" && renderIndustryDetails()}
        {step === "4" && renderConfirmDetails()}
      </div>

      <ConformationModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        onPrimary={() => {
          setShowSuccessModal(false);
          history.push("/admin/demotools");
        }}
        showSecondary={false}
        primaryText="OK"
        title="Presentation Being Created"
        bodyText={
          "The presentation is now being generated. You will receive an email with details on how to access the presentation when it's ready. This process can take 20-30 minutes."
        }
      />
    </Container>
  );
};
