/**
 * Utility functions for city data fetching and processing
 */

/**
 * Fetches nearby cities based on input parameters
 * @param {string} city - The target city name
 * @param {string} state - The state of the target city
 * @param {number} population - Minimum population filter
 * @param {number} radius - Search radius in degrees
 * @param {number} maxResults - Maximum number of results to return
 * @returns {Promise<Array>} - Array of nearby cities
 */
export const fetchNearbyCities = async (
  city,
  state,
  countryCode,
  population,
  radius,
  maxResults
) => {
  try {
    // Fetch coordinates from OpenStreetMap
    const osmResponse = await fetch(
      `https://nominatim.openstreetmap.org/search?city=${city}&state=${state}&country=USA&format=json`
    );
    const osmData = await osmResponse.json();
    if (!osmData.length) {
      throw new Error("City not found");
    }

    const { lat, lon } = osmData[0];
    const north = parseFloat(lat) + radius;
    const south = parseFloat(lat) - radius;
    const east = parseFloat(lon) + radius;
    const west = parseFloat(lon) - radius;

    // Fetch nearby cities from GeoNames
    const geoUsername = "davyonongemoname";
    const geoResponse = await fetch(
      `https://secure.geonames.org/citiesJSON?north=${north}&south=${south}&east=${east}&west=${west}&maxRows=100&level=2&username=${geoUsername}`
    );
    const geoData = await geoResponse.json();

    if (!geoData.geonames) {
      throw new Error("No nearby cities found");
    }

    // Filter cities by country code
    let filteredCities = geoData.geonames.filter(
      (city) => city.countrycode === countryCode
    );

    // Filter cities by population and sort
    filteredCities = filteredCities
      .filter((city) => city.population >= population)
      .map((city) => ({
        ...city,
        distance: calculateDistance(lat, lon, city.lat, city.lng),
      }))
      .sort((a, b) => b.population - a.population)
      .slice(0, maxResults);

    // Fetch state information for each city
    const citiesWithState = await Promise.all(
      filteredCities.map(async (city) => {
        try {
          const state = await getStateFromCoordinates(city.lat, city.lng);
          return {
            name: city.name,
            population: city.population,
            distance: city.distance,
            countryCode: city.countrycode,
            state: state,
            lat: city.lat,
            lng: city.lng,
            geoNameId: city.geonameId,
          };
        } catch (error) {
          console.warn(`Could not fetch state for ${city.name}:`, error);
          return {
            name: city.name,
            population: city.population,
            distance: city.distance,
            countryCode: city.countrycode,
            state: "Unknown",
            lat: city.lat,
            lng: city.lng,
            geoNameId: city.geonameId,
          };
        }
      })
    );

    return citiesWithState;
  } catch (error) {
    console.error("Error fetching city data:", error);
    throw error;
  }
};

/**
 * Calculates the distance between two geographical points
 * @param {number} lat1 - Latitude of first point
 * @param {number} lon1 - Longitude of first point
 * @param {number} lat2 - Latitude of second point
 * @param {number} lon2 - Longitude of second point
 * @returns {number} - Distance in miles
 */
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const toRad = (value) => (value * Math.PI) / 180;
  const R = 6371; // Radius of the Earth in km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceInKm = R * c; // Distance in km
  return distanceInKm * 0.621371; // Convert km to miles
};

/**
 * Formats a number with commas as thousands separators
 * @param {number} population - The population number to format
 * @returns {string} - Formatted population string
 */
export const formatPopulation = (population) => {
  return population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Gets the state information for a location based on coordinates
 * @param {number} lat - Latitude of the location
 * @param {number} lng - Longitude of the location
 * @returns {Promise<string>} - State name or code
 */
export const getStateFromCoordinates = async (lat, lng) => {
  try {
    const geoUsername = "davyonongemoname";
    const response = await fetch(
      `https://secure.geonames.org/findNearbyJSON?lat=${lat}&lng=${lng}&username=${geoUsername}`
    );

    const data = await response.json();

    if (!data.geonames || data.geonames.length === 0) {
      throw new Error("No location data found for the provided coordinates");
    }

    // Try to get the adminName1 (state name) or adminCode1 (state code)
    const stateInfo =
      data.geonames[0].adminName1 || data.geonames[0].adminCode1;

    if (!stateInfo) {
      throw new Error(
        "State information not available for the provided coordinates"
      );
    }

    return stateInfo;
  } catch (error) {
    console.error("Error fetching state data from coordinates:", error);
    throw error;
  }
};
