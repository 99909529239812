/* eslint-disable eqeqeq */

import React, { useState, useEffect, Component, useRef } from "react";
import { Col, Row, Form, Alert, Badge } from "react-bootstrap";
import alphabates from "../../../Components/AlphabatesSorting";
import { Helper, allUserPerPage } from "../../../Utilities/Helper";
import ListPagination from "../../../Components/Pagination";
import { toast } from "react-toastify";
import {
  getReportAbuseList,
  getUserTypes,
  userActiveAndInactive,
  getExportReportList,
} from "../../../Services/AdminManageUsersServices";
import AdminUserListing from "../../../Components/AdminUserListing";
import AdminUserSearch from "../../../Components/AdminUserSearch";
import { SortingAlphabate } from "../../../Components/Shared/SortingAlphabate";
import { getGroups } from "../../../Services/GetFields";

import { generatePdf } from "../../../Utilities/Helper";

export default function AdminManageUsers() {
  const [users, setUsers] = useState([]);
  const [usersTypes, setUsersTypes] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const [searchParams, setSearchParams] = useState("");
  const [currentPage, setCurrentPage] = useState({ value: "", filter: "" });
  const [sort, setSort] = useState({ value: "", filter: "" });
  const [refreshTable, setRefreshTable] = useState(true);
  const [showPagination, setShowPagination] = useState(true);
  const [showSpinnerForUser, setShowSpinnerForUser] = useState(true);
  const [groups, setGroups] = useState([]);
  const [showAlertSuccess, setShowAlertSuccess] = useState({
    show: false,
    message: "",
  });
  const [showAlertError, setShowAlertError] = useState({
    show: false,
    message: "",
  });

  const name = "UserList.xlsx";

  //data for export
  const prepareData = (rawData) => {
    const userData = rawData.jinUsers;
    let data = [
      {
        Name: null,
        Email: null,
        "User Type": null,
        "Group Name": null,
        Status: null,
      },
    ];
    if (userData.length > 0) {
      data = userData.map((u) => {
        const active = "Active";
        const inactive = "InActive";
        return {
          Name: u.fullName,
          Email: u.email,
          "User Type": u.userType,
          "Group Name":
            u.groupName === null && u.groups.length !== 0
              ? u.groups.map((gr) => gr.groupName).toString()
              : u.groupName === null && u.groups.length === 0
              ? "-"
              : u.groupName,
          Status: u.isActive ? active : inactive,
        };
      });
    }
    return data;
  };

  const hideAlerts = () => {
    setTimeout(() => {
      setShowAlertSuccess({ show: false, message: "" });
      setShowAlertError({ show: false, message: "" });
    }, 5000);
  };

  useEffect(() => {
    function getAllUserTypes() {
      getUserTypes()
        .then((res) => {
          setUsersTypes(res.result);
        })
        .catch((err) => {
          console.log(err);
          // toast.error("something went wrong");
        });
    }
    function allGroups() {
      getGroups()
        .then((res) => {
          const result = JSON.parse(res.response);
          setGroups(result);
        })
        .catch((err) => {
          console.log("Group List: ", err);
        });
    }
    getAllUserTypes();
    allGroups();
  }, []);

  useEffect(() => {
    setShowPagination(true);
    async function AdminUserListData() {
      const filter =
        "recordperpage=" +
        allUserPerPage +
        currentPage.filter +
        sort.filter +
        searchParams;
      getReportAbuseList(filter)
        .then((res) => {
          const result = res;
          setUsers(result.jinUsers);
          setTotalUsers(result.totalRecord);
          setShowSpinnerForUser(false);
        })
        .catch((err) => {
          console.log("JobSeeker: ", err);
          setShowSpinnerForUser(false);
        });
    }
    AdminUserListData();
  }, [refreshTable]);

  const refreshTableHandler = () => {
    setShowSpinnerForUser(true);
    setCurrentPage((prev) => ({ value: "", filter: "" }));
    setRefreshTable((prev) => !prev);
  };

  const onSerchParamHandler = (params) => {
    setShowPagination(false);
    setSearchParams(params);
    setCurrentPage((prev) => ({ value: "", filter: "" }));
    refreshTableHandler();
  };

  const dataToExportAsPdf = (res) => {
    const userData = [];

    res.jinUsers.forEach((element, index) => {
      let groupNames = element.groupName;
      if (element.groupName == null) {
        if (element.groups.length > 0)
          groupNames = element.groups.map((gr) => gr.groupName);
        else groupNames = "-";
      }
      userData.push([
        element.fullName,
        element.email,
        element.userType,
        groupNames,
        element.isActive ? "Active" : "Inactive",
      ]);
    });

    const pdfHeaders = ["Name", "Email", "User Type", "Group", "Status"];

    generatePdf(pdfHeaders, userData, "UserList.pdf", "User List");
  };

  const handleExportToPdf = () => {
    const filter = "recordperpage=" + totalUsers + sort.filter + searchParams;
    setShowSpinnerForUser(true);

    getReportAbuseList(filter)
      .then((res) => {
        dataToExportAsPdf(res);
        setShowSpinnerForUser(false);
      })
      .catch((err) => {
        console.log("JobSeeker: ", err);
        setShowSpinnerForUser(false);
      });
  };

  const sortingChangeHandler = (value) => {
    if (sort.value !== value && value !== "clear") {
      setSort((prev) => ({ value: value, filter: `&sort=${value}` }));
      refreshTableHandler();
    }
    if (sort.value !== value && value === "clear") {
      setSort((prev) => ({ value: value, filter: "" }));
      refreshTableHandler();
    }
  };

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ value: page, filter: `&page=${page}` }));
      setRefreshTable((prev) => !prev);
      setShowSpinnerForUser(true);
    }
  };

  const changeStatusHandler = (data) => {
    const userdata = {
      Id: data.id,
      IsActive: !data.status,
      UserTypeId: data.userTypeId,
      FullName: data.fullName,
    };
    console.log("users", users);
    userActiveAndInactive(userdata)
      .then((res) => {
        if (data.status == true) {
          setShowAlertError({ message: res.message, show: true });
        } else {
          setShowAlertSuccess({ message: res.message, show: true });
        }
        hideAlerts();
        setRefreshTable((prev) => !prev);
        setShowSpinnerForUser(true);
      })
      .catch((err) => {
        console.log("StatusUpdate: ", err);
      });
  };

  const handelExportData = async () => {
    setShowSpinnerForUser(true);
    const filter = "recordperpage=" + totalUsers + sort.filter + searchParams;
    await getReportAbuseList(filter)
      .then((result) => {
        const newData = prepareData(result);
        //excel generation
        Helper.excelGeneration(newData, name);
      })
      .catch((err) => {
        console.log("export error: ", err);
      });
    setShowSpinnerForUser(false);
  };

  return (
    <React.Fragment>
      <Alert
        className="confirm-alert"
        variant="success"
        show={showAlertSuccess.show}
        onClose={() => setShowAlertSuccess({ show: false, message: "" })}
        dismissible
      >
        <p className="text-center mb-0 py-3">{showAlertSuccess.message}</p>
      </Alert>

      <Alert
        className="confirm-alert"
        variant="danger"
        show={showAlertError.show}
        onClose={() => setShowAlertError({ show: false, message: "" })}
        dismissible
      >
        <p className="text-center mb-0 py-3">{showAlertError.message}</p>
      </Alert>
      <div className="container p-0">
        <h1 className="page-title">
          All Users{" "}
          <Badge
            pill
            bg="danger"
            className="bg-warning text-white px-3 ml-3 font-weight-normal"
          >
            {totalUsers}
          </Badge>
        </h1>
        <div className="dashboard-wrapper">
          <Row>
            <Col md={12}>
              <div className="dashboard-box dashboard-col-bg sponsership-page">
                <div className="sort d-flex justify-content-end">
                  {/* <Form.Group controlId="exampleForm.ControlSelect1" className="w-25">
                <Form.Control as="select" className="inputclass form-control">
                  <option>Sort By</option>
                  <option>Name</option>
                  <option> Email </option>
                  <option>Phone</option>
                  <option> Job Title & Company</option>
                </Form.Control>
              </Form.Group> */}
                </div>
                <div className="search">
                  <AdminUserSearch
                    //groupId={jobSeekerGroupId}
                    userTypeList={usersTypes}
                    groupsList={groups}
                    searchParam={onSerchParamHandler}
                    exportUsersList={handelExportData}
                    exportToPdf={handleExportToPdf}
                  />
                </div>
                <div className="d-flex">
                  <AdminUserListing
                    userList={users}
                    onChangeStatus={changeStatusHandler}
                    showSpinner={showSpinnerForUser}
                  />
                  <SortingAlphabate ChangeHandler={sortingChangeHandler} />
                </div>
                <div className="d-flex">
                  {users.length !== 0 && showPagination && (
                    <ListPagination
                      recordPerPage={allUserPerPage}
                      TotalRecord={totalUsers}
                      className="mt-3"
                      onPageChange={pageChangeHandler}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
