import React, { useState } from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import FormInput from "../../../Components/Shared/FormInput";
import { fetchNearbyCities, formatPopulation } from "../../../utils/cityUtils";

export const AdminDemoCities = () => {
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [population, setPopulation] = useState(30000);
  const [radius] = useState(1);
  const [maxResults, setMaxResults] = useState(10);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFetchCities = async () => {
    setLoading(true);
    setResults([]);

    try {
      const cities = await fetchNearbyCities(
        city,
        state,
        "US",
        population,
        radius,
        maxResults
      );
      setResults(cities);
    } catch (error) {
      alert(error.message || "Failed to fetch data");
    }
    setLoading(false);
  };

  return (
    <>
      <Container>
        <h1 className="page-title">Find Nearby Cities</h1>
        <div className="dashboard-wrapper">
          <Row className="justify-content-md-center">
            <Col sm={12} md={6} className="mt-0">
              <div className="dashboard-box dashboard-col-bg mb-4">
                <h2 className="sub-form-title mb-6 d-flex">
                  Find Nearby Cities
                </h2>

                <label htmlFor="city">City</label>
                <FormInput
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e)}
                  className="block w-full p-2 border rounded mb-2"
                  id="city"
                />

                <label htmlFor="state">State</label>
                <FormInput
                  type="text"
                  placeholder="State"
                  value={state}
                  onChange={(e) => setState(e)}
                  className="block w-full p-2 border rounded mb-2"
                  id="state"
                />

                <label htmlFor="population">Min Population</label>
                <FormInput
                  type="number"
                  placeholder="Min Population"
                  value={population}
                  onChange={(e) => setPopulation(e)}
                  className="block w-full p-2 border rounded mb-2"
                  id="population"
                />

                <label htmlFor="maxResults">Max Results</label>
                <FormInput
                  type="number"
                  placeholder="Max Results"
                  value={maxResults}
                  onChange={(e) => setMaxResults(e)}
                  className="block w-full p-2 border rounded mb-2"
                  id="maxResults"
                />

                {/* <label htmlFor="radius">Radius (degrees)</label>
                <FormInput
                  type="number"
                  placeholder="Radius"
                  value={radius}
                  onChange={(e) => {
                    if (e > 3) {
                      alert("Max radius is 3");
                      setRadius(3);
                    }
                    if (e < 0) {
                      alert("Min radius is 0");
                      setRadius(0);
                    } else {
                      setRadius(e);
                    }
                  }}
                  className="block w-full p-2 border rounded mb-2"
                  id="radius"
                /> */}

                <Button
                  onClick={handleFetchCities}
                  className="mt-3"
                  disabled={loading || !city || !state}
                >
                  {loading ? "Loading..." : "Search"}
                </Button>

                <ul className="mt-4">
                  {results.map((city, index) => (
                    <li key={index} className="p-2 border-b">
                      <strong>{city.name}</strong>: Population:{" "}
                      <strong>{formatPopulation(city.population)}</strong> |
                      Distance: <strong>{city.distance?.toFixed(2)}</strong>{" "}
                      miles
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
