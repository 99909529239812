/* eslint-disable eqeqeq */

import React, { input, useEffect, useState } from "react";
import { Col, Row, Button, InputGroup, FormControl } from "react-bootstrap";
import { FaCommentAlt, FaSearch } from "react-icons/fa";
import InboxMenu from "../../../Components/InboxMenu";
import { useHistory } from "react-router";
import InboxMessagesList from "../../../Components/InboxMessagesList";
import InboxMessagesActions from "../../../Components/InboxMessageActions";
import InboxMessage from "../../../Components/InboxMessage";
import InboxNewMessageModal from "../../../Components/InboxNewMessageModal";
import {
  getUserFlaggedMessages,
  getUserInboxMessages,
  getUserSentMessages,
  getUserTrashMessages,
} from "../../../Services/MessagesService";
import { getUserDetails } from "../../../Services/GetFields";
import { messagesPerPage, Roles } from "../../../Utilities/Helper";
import { useDispatch, useSelector } from "react-redux";
import { globalSearchAction } from "../../../Redux/GlobalSearch/Action";
import { readNotification } from "../../../Services/NotificationService";
import ListPagination from "../../../Components/Pagination";
import SpinnerLoader from "../../../Components/SpinnerLoader";

export default function Messages() {
  //Using Redux
  const store = useSelector((res) => res.GlobalSearchReducer.messages);
  const history = useHistory();
  const [showResults, setShowResults] = React.useState(false);
  const [showPagination, setShowPagination] = React.useState(true);
  const [inboxMessageIndex, setInboxMessageIndex] = useState(true);
  // const [id, setId] = React.useState("");
  const onClick = () => setShowResults(true);
  const onClickHide = () => setShowResults(false);
  const [messages, setMessages] = React.useState([]);
  const [currentMessage, setCurrentMessage] = React.useState("");
  const [tab, setTab] = React.useState(
    history.location.search.split("?")[2] == "s" ? 1 : 0
  );
  const [active, setActive] = React.useState(
    history.location.search.split("?")[2] == "s" ? 1 : 0
  );
  const [allowSetIndex, setAllowSetIndex] = React.useState(
    history.location.search.split("?")[1] < 3 ? true : false
  );
  const [messageIndex, setMessageIndex] = React.useState(
    history.location.search.split("?")[1] < 3
      ? Number(history.location.search.split("?")[1])
      : null
  );
  const [totalRecords, setTotalRecords] = React.useState("");
  const [load, setLoad] = React.useState(false);
  const [msgCount, setMsgCount] = React.useState("");
  const [msgType, setMSGType] = useState(null);
  const Results = () => <InboxNewMessageModal setShowResults={onClickHide} />;
  const [currentPage, setCurrentPage] = useState({ value: "", filter: "" });
  const [refreshTable, setRefreshTable] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);
  const [tabStatus, setTabStatus] = useState(true);
  const [refreshOnDelete, setRefreshOnDelete] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const messagesList = () => {
      const filter = "recordperpage=" + messagesPerPage + currentPage.filter;
      if (allowSetIndex == true) {
        setAllowSetIndex(false);
        setMessageIndex(messageIndex);
      } else {
        setMessageIndex(null);
      }
      setShowPagination(true);
      if (tab === 0) {
        getUserInboxMessages(filter)
          .then((res) => {
            const result = JSON.parse(res.response);
            setMessages(result.messageModels);
            setTotalRecords(result.totalRecord);
            setMSGType("Inbox");
            setShowSpinner(false);
            if (messageIndex == null) {
              setCurrentMessage("");
            }
            if (
              messageIndex != null &&
              result.messageModels.length > 0 &&
              messageIndex < result.messageModels.length
            ) {
              setCurrentMessage(result.messageModels[messageIndex]);
              setRefreshOnDelete(!refreshOnDelete);
            } else {
              if (currentPage > 1) {
                pageChangeHandler(currentPage - 1);
              }
              setCurrentMessage("");
            }
          })
          .catch((err) => {
            console.log(err);
            setShowSpinner(false);
          });
      } else if (tab === 1) {
        getUserSentMessages(filter)
          .then((res) => {
            const result = JSON.parse(res.response);
            setMessages(result.messageModels);
            setTotalRecords(result.totalRecord);
            setMSGType("Sent");
            setShowSpinner(false);
            if (messageIndex == null) {
              setCurrentMessage("");
            }
            if (
              messageIndex != null &&
              result.messageModels.length > 0 &&
              messageIndex < result.messageModels.length
            ) {
              setCurrentMessage(result.messageModels[messageIndex]);
            } else {
              if (currentPage > 1) {
                pageChangeHandler(currentPage - 1);
              }
              setCurrentMessage("");
            }
          })
          .catch((err) => {
            console.log(err);
            setShowSpinner(false);
          });
      } else if (tab === 2) {
        getUserFlaggedMessages(filter)
          .then((res) => {
            const result = JSON.parse(res.response);
            setMessages(result.messageModels);
            setTotalRecords(result.totalRecord);
            setMSGType("Flagged");
            setShowSpinner(false);
            if (messageIndex == null) {
              setCurrentMessage("");
            }
            if (
              messageIndex != null &&
              result.messageModels.length > 0 &&
              messageIndex < result.messageModels.length
            ) {
              // console.log("Not Null", messageIndex);
              setCurrentMessage(result.messageModels[messageIndex]);
            } else {
              if (currentPage > 1) {
                pageChangeHandler(currentPage - 1);
              }
              setCurrentMessage("");
            }
          })
          .catch((err) => {
            console.log(err);
            setShowSpinner(false);
          });
      } else if (tab === 3) {
        getUserTrashMessages(filter)
          .then((res) => {
            const result = JSON.parse(res.response);
            setMessages(result.messageModels);
            setTotalRecords(result.totalRecord);
            setMSGType("Trash");
            setShowSpinner(false);

            if (messageIndex == null) {
              setCurrentMessage("");
            }
            if (
              messageIndex != null &&
              result.messageModels.length > 0 &&
              messageIndex < result.messageModels.length
            ) {
              setCurrentMessage(result.messageModels[messageIndex]);
            } else {
              if (currentPage > 1) {
                pageChangeHandler(currentPage - 1);
              }
              setCurrentMessage("");
            }
          })
          .catch((err) => {
            console.log(err);
            setShowSpinner(false);
          });
      }
    };
    if (store !== null) {
      setShowSpinner(true);
      setMessages(store.items);
      setTotalRecords(store.totalRecord);
      setTabStatus((prev) => !prev);
      if (messageIndex == null) {
        setCurrentMessage("");
      }
      setShowSpinner(false);
    } else {
      messagesList();
    }
  }, [tab, refreshTable, store]);

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ value: page, filter: `&page=${page}` }));
      refreshTableHandler(page);
      setMessageIndex(null);
    }
  };

  const refreshTableHandler = (page = 0) => {
    if (store !== null && page !== 0) {
      dispatch(
        globalSearchAction({
          recordperpage: store.recordperpage,
          page: currentPage.value,
          type: store.type,
          SerachText: store.SerachText,
          GlobalSearchTypeId: store.GlobalSearchTypeId,
          searchTypeText: store.searchTypeText,
        })
      );
      setShowSpinner(true);
    } else {
      setRefreshTable((prev) => !prev);
      setShowSpinner(true);
      dispatch(
        globalSearchAction({
          type: "clear",
        })
      );
    }
  };

  const sendDataToParent = (currentTab) => {
    setCurrentPage({ value: "", filter: "" });
    setTab(currentTab);
    setCurrentMessage("");
    setShowPagination(false);
    setMessageIndex(null);
    if (currentTab !== tab) setShowSpinner(true);
    if (currentTab != 0) {
      dispatch(
        globalSearchAction({
          type: "clear",
        })
      );
    }
  };

  const getSelectedMessage = (message) => {
    // console.log(message, 'message index');
    setCurrentMessage(messages[message]);
    // setMessageIndex(message);
  };

  const loadMore = (loadMsgs) => {
    // console.log(loadMsgs, "load");
    setLoad(loadMsgs);
    let count = 1;
    if (loadMsgs > 2) {
      const filter = "recordperpage=5&page=" + (count + 1);
      if (tab === 0) {
        getUserInboxMessages(filter)
          .then((res) => {
            // console.log(res);
            const result = JSON.parse(res.response);
            // console.log(messages, result.messageModels);
            for (let i = 0; i < result.messageModels.length; i++) {
              messages.push(result.messageModels[i]);
            }
            // console.log(messages);
            setMessages(messages);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const pagination = (count) => {
    pageChangeHandler(count);
  };

  const RefreshTableOnIsSeen = (id, type, isSeen, index) => {
    setMessageIndex(index);
    if (type == "Inbox" && isSeen == false) {
      // let data = JSON.stringify({
      //   userId: id,
      // });
      let data = id;
      readNotification(data)
        .then((res) => {
          // if (res.status === 200) {
          setAllowSetIndex(true);
          setRefreshTable((prev) => !prev);
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="container p-0">
      <div className="message-header">
        <h1 className="page-title">Messages</h1>
      </div>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            <div className="dashboard-box dashboard-col-bg p-0">
              {/* New message modal satrt*/}
              {showResults ? <Results /> : null}
              {/* New message modal end*/}

              <div className="inbox-wrap">
                <InboxMenu
                  sendDataToParent={sendDataToParent}
                  selectTab={tabStatus}
                  markActive={active}
                />
                <div className="inbox-messages">
                  {/* <Button variant="warning" size="sm" onClick={onClick}>
                    <FaCommentAlt className="mr-2" /> new message
                  </Button>   */}
                  {showSpinner && <SpinnerLoader />}
                  {messages.length === 0 && !showSpinner && (
                    <span>No Message found</span>
                  )}
                  <InboxMessagesList
                    messages={messages}
                    type={msgType}
                    getSelectedMessage={getSelectedMessage}
                    totalRecords={totalRecords}
                    loadMore={loadMore}
                    pagination={pagination}
                    messageIndexChange={inboxMessageIndex}
                    refreshTable={RefreshTableOnIsSeen}
                    messageIndex={messageIndex}
                  />
                  {messages.length != 0 && showPagination && (
                    <ListPagination
                      recordPerPage={messagesPerPage}
                      TotalRecord={totalRecords}
                      className="mt-3"
                      onPageChange={pageChangeHandler}
                    />
                  )}
                </div>
                <div className="inbox-message">
                  <InboxMessage
                    message={currentMessage}
                    messageType={msgType}
                    refreshTableHandler={() => {
                      setAllowSetIndex(true);
                      setRefreshTable((prev) => !prev);
                    }}
                    refreshOnDelete={refreshOnDelete}
                    refreshMessageSend={() => {
                      setAllowSetIndex(true);
                      {
                        msgType == "Sent" &&
                          setMessageIndex(
                            messageIndex !== 0 ? messageIndex + 1 : 1
                          );
                      }
                      refreshTableHandler();
                    }}
                    refreshMessageRestore={() => {
                      setAllowSetIndex(true);
                      setMessageIndex(
                        messageIndex !== 0 ? messageIndex - 1 : 0
                      );
                      refreshTableHandler();
                    }}
                    refreshMessageFlag={() => {
                      setAllowSetIndex(true);
                      {
                        msgType === "Flagged" &&
                          setMessageIndex(
                            messageIndex !== 0 ? messageIndex - 1 : 0
                          );
                      }
                      {
                        msgType !== "Flagged" && setMessageIndex(messageIndex);
                      }
                      refreshTableHandler();
                    }}
                    refreshMessageTrash={() => {
                      {
                        messageIndex === 0 &&
                          currentPage.value > 1 &&
                          pageChangeHandler(currentPage.value - 1);
                      }
                      setAllowSetIndex(true);
                      setMessageIndex(
                        messageIndex !== 0 ? messageIndex - 1 : 0
                      );
                      refreshTableHandler();
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
// export default Messages;
