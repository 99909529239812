/* eslint-disable eqeqeq */
import { useHistory } from "react-router-dom";
import { getSearchResult } from "../../Services/GlobalSearchServices";
import { Roles, SearchTypes } from "../../Utilities/Helper";
import { getData } from "../../Utilities/StorageHelper";

const intial = {
  items: [],
  totalRecord: 0,
};

export const globalSearchAction = (state) => (dispatch) => {
  if (state.type === "clear") {
    dispatch({
      type: "clear",
    });
    return;
  }

  var data = `recordperpage=${state.recordperpage}&page=${state.page}&SerachText=${state.SerachText}&GlobalSearchTypeId=${state.GlobalSearchTypeId}`;
  getSearchResult(data)
    .then((res) => {
      var userRole = getData("role");
      if (
        state.searchTypeText == SearchTypes.supportTeamMember &&
        userRole === Roles.groupLeader
      ) {
        dispatch({
          type: "Support",
          recordperpage: state.recordperpage,
          SerachText: state.SerachText,
          GlobalSearchTypeId: state.GlobalSearchTypeId,
          searchTypeText: state.searchTypeText,
          items: res.supportTeamMembers,
          totalRecord: res.totalRecord,
        });
        state.history.push("/groupleader/supportteammembers");
      } else if (
        state.searchTypeText == SearchTypes.jobs &&
        userRole === Roles.groupLeader
      ) {
        dispatch({
          type: "Job",
          recordperpage: state.recordperpage,
          SerachText: state.SerachText,
          GlobalSearchTypeId: state.GlobalSearchTypeId,
          searchTypeText: state.searchTypeText,
          items: res.jobs,
          totalRecord: res.totalRecord,
        });
        state.history.push("/groupleader/groupleaderjobs");
      } else if (
        state.searchTypeText == SearchTypes.jobs &&
        userRole === Roles.jobSeeker
      ) {
        dispatch({
          type: "Job",
          recordperpage: state.recordperpage,
          items: res.jobs,
          SerachText: state.SerachText,
          GlobalSearchTypeId: state.GlobalSearchTypeId,
          searchTypeText: state.searchTypeText,
          totalRecord: res.totalRecord,
        });
        state.history.push("/jobseeker/jobs");
      } else if (
        state.searchTypeText == SearchTypes.message &&
        userRole === Roles.jobSeeker
      ) {
        dispatch({
          type: "Message",
          recordperpage: state.recordperpage,
          SerachText: state.SerachText,
          GlobalSearchTypeId: state.GlobalSearchTypeId,
          searchTypeText: state.searchTypeText,
          items: res.messageModels,
          totalRecord: res.totalRecord,
        });
        state.history.push("/jobseeker/messages");
      } else if (
        state.searchTypeText == SearchTypes.network &&
        userRole === Roles.jobSeeker
      ) {
        dispatch({
          type: "Network",
          recordperpage: state.recordperpage,
          SerachText: state.SerachText,
          GlobalSearchTypeId: state.GlobalSearchTypeId,
          searchTypeText: state.searchTypeText,
          items: res.networkModels,
          totalRecord: res.totalRecord,
        });
        state.history.push("/network");
      } else if (
        state.searchTypeText == SearchTypes.connections &&
        userRole === Roles.jobSeeker
      ) {
        dispatch({
          type: "Connection",
          recordperpage: state.recordperpage,
          SerachText: state.SerachText,
          GlobalSearchTypeId: state.GlobalSearchTypeId,
          searchTypeText: state.searchTypeText,
          items: res.connections,
          totalRecord: res.totalRecord,
        });
        state.history.push("/seekersconnections");
      } else if (
        state.searchTypeText == SearchTypes.message &&
        userRole === Roles.stm
      ) {
        dispatch({
          type: "Message",
          recordperpage: state.recordperpage,
          SerachText: state.SerachText,
          GlobalSearchTypeId: state.GlobalSearchTypeId,
          searchTypeText: state.searchTypeText,
          items: res.messageModels,
          totalRecord: res.totalRecord,
        });
        state.history.push("/support/messages");
      } else if (
        state.searchTypeText == SearchTypes.publicResume &&
        userRole === Roles.employer
      ) {
        dispatch({
          type: "Public Resume",
          recordperpage: state.recordperpage,
          SerachText: state.SerachText,
          GlobalSearchTypeId: state.GlobalSearchTypeId,
          searchTypeText: state.searchTypeText,
          items: res.jinTemplatesModelList,
          totalRecord: res.publicResumeCardsTotalCount,
        });
        state.history.push("/resumes");
      } else if (
        state.searchTypeText == SearchTypes.privateResume &&
        userRole === Roles.employer
      ) {
        dispatch({
          type: "Private Resume",
          recordperpage: state.recordperpage,
          SerachText: state.SerachText,
          GlobalSearchTypeId: state.GlobalSearchTypeId,
          searchTypeText: state.searchTypeText,
          items: res.jinTemplatesModelList,
          totalRecord: res.publicResumeCardsTotalCount,
        });
        state.history.push("/resumes");
      } else if (
        state.searchTypeText == SearchTypes.jobs &&
        userRole === Roles.employer
      ) {
        dispatch({
          type: "Job",
          recordperpage: state.recordperpage,
          SerachText: state.SerachText,
          GlobalSearchTypeId: state.GlobalSearchTypeId,
          searchTypeText: state.searchTypeText,
          items: res.jobs,
          totalRecord: res.totalRecord,
        });
        state.history.push("/jobslist");
      } else if (
        state.searchTypeText == SearchTypes.message &&
        userRole === Roles.employer
      ) {
        dispatch({
          type: "Message",
          recordperpage: state.recordperpage,
          SerachText: state.SerachText,
          GlobalSearchTypeId: state.GlobalSearchTypeId,
          searchTypeText: state.searchTypeText,
          items: res.messageModels,
          totalRecord: res.totalRecord,
        });
        state.history.push("/employer/messages");
      }
    })
    .catch((err) => {
      console.log(err);
      // toast.error("something went wrong");
    });
};
