import { API_URL, API_DEMO_PRESENTATION_URL } from "../Utilities/Helper";
import { post, put, get } from "./AxiosService";

export function putResetDemoDatabase(data) {
  return put(`${API_URL}data/resetDemoDatabase`, data).then((res) => res.data);
}

export function getDemoDatabaseBackups() {
  return get(`${API_URL}data/getDemoDatabaseBackups`).then((res) => res.data);
}

export function putRemoveSavedDemo(data) {
  return put(`${API_URL}data/RemoveSavedDemo`, data).then((res) => res.data);
}

export function putRestoreSavedDemoDatabase(data) {
  return post(`${API_URL}data/restoreDemoDatabase`, data).then(
    (res) => res.data
  );
}

export function putBackupDemoDatabase(data) {
  return post(`${API_URL}data/saveDemoDatabase`, data).then((res) => res.data);
}

export function putSetupDemoPresentationText(data) {
  return post(`${API_DEMO_PRESENTATION_URL}setup_presentation_text`, data).then(
    (res) => res.data
  );
}

export function putSetupDemoPresentationVideo(data) {
  return post(
    `${API_DEMO_PRESENTATION_URL}setup_presentation_video`,
    data
  ).then((res) => res.data);
}

export function putCreateDemoPresentation(data) {
  return post(`${API_DEMO_PRESENTATION_URL}create_demo`, data).then(
    (res) => res.data
  );
}

export function getSupportTeamMemberDetails(userName) {
  return get(
    `${API_URL}SupportTeamMember/GetSupportTeamMember?UserName=${userName}`
  ).then((res) => res.data);
}
