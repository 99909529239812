/* eslint-disable eqeqeq */

import React, { useState, Component, useEffect } from "react";
import { Col, Row, Form, Alert } from "react-bootstrap";
import { Route, Switch, Redirect, Link, NavLink } from "react-router-dom";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import {
  passwordRegex,
  validateCharacterAndSpace,
  Validators,
} from "../../../Utilities/Validator";
import publiclogo from "../../../assests/img/logo-public.png";
import {
  Navbar,
  Button,
  Nav,
  ButtonDropdown,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  NavDropdown,
  Image,
} from "react-bootstrap";
import StartupHeader from "../../../Components/StartupHeader";
import axios from "axios";
import { useHistory } from "react-router";
import { ResetPassword } from "../../../Services/GetFields";
import { toast } from "react-toastify";

export default function ForgotPassword(props) {
  // const { password, confirmPassword } = this.state;
  const history = useHistory();
  const toastId = "custom_toast";

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [register, setRegister] = useState({
    firstName: "",
    lastName: "",
  });

  const [forgotPassword, setForgotPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [forgotPasswordError, setForgotPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState();
  const [passwordMatch, setPasswordMatch] = useState();
  const [regexError, setRegexError] = useState(false);
  const [displayExpired, setDisplayExpired] = useState(false);

  const handleInputChange = (event) => {
    // console.log(event.target.name, event.target.value);
    var regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    setState((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
    if (event.target.name === "forgotpassword") {
      if (event.target.value === "" || event.target.value === undefined) {
        setForgotPasswordError(true);
      } else {
        if (!passwordRegex.test(event.target.value)) {
          setRegexError(true);
        } else {
          setRegexError(false);
        }
        setForgotPassword(event.target.value);
        setForgotPasswordError(false);
      }
    } else {
      if (event.target.name === "confirmpassword") {
        if (event.target.value === "" || event.target.value === undefined) {
          setConfirmPasswordError(true);
        } else {
          if (!passwordRegex.test(event.target.value)) {
            setRegexError(true);
          } else {
            setRegexError(false);
          }
          setConfirmPassword(event.target.value);
          setConfirmPasswordError(false);
        }
      }
    }
  };

  useEffect(() => {
    const email = props.match.params.email;
    const getToken = props.location.search;
    const token = getToken.replace("?token=", "");
  }, []);

  const resetPassword = () => {
    const email = props.match.params.email;
    const getToken = props.location.search;
    const token = getToken.replace("?token=", "");

    const data = JSON.stringify({
      email: email,
      token: token,
      password: forgotPassword,
      confirmPassword: confirmPassword,
    });

    ResetPassword(data).then((response) => {
      // console.log(response.value);
      if (response.value === "Your password saved successfully.") {
        toast.success("Password reset successfully.", { toastId: toastId });
        history.push(process.env.PUBLIC_URL + "/home");
      } else if (response.value === "Invalid token.") {
        setPasswordMatchError(false);
        setDisplayExpired(true);
      }
    });
  };

  const validate = (forgotPassword, confirmPassword) => {
    let result = true;
    if (forgotPassword === "" || forgotPassword === undefined) {
      result = false;
      setForgotPasswordError(true);
    } else {
      result = true;
      setForgotPasswordError(false);
    }
    if (confirmPassword === "" || confirmPassword === undefined) {
      result = false;
      setConfirmPasswordError(true);
    } else {
      result = true;
      setConfirmPasswordError(false);
    }
    if (
      forgotPassword === "" ||
      forgotPassword === undefined ||
      confirmPassword === "" ||
      confirmPassword === undefined
    ) {
      return false;
    } else return result;
  };

  const handleSubmit = () => {
    let value = validate(forgotPassword, confirmPassword);
    if (value) {
      if (forgotPassword === confirmPassword) {
        if (regexError == false) {
          setRegexError(false);
          setPasswordMatch(true);
          setPasswordMatchError(false);
          resetPassword();
        } else {
          setPasswordMatchError(false);
          setPasswordMatch(false);
        }
      } else {
        setPasswordMatch(false);
        setPasswordMatchError(true);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    document.body.classList.add("home-page");

    return function cleanup() {
      document.body.classList.remove("home-page");
    };
  }, []);

  return (
    <React.Fragment>
      <StartupHeader />

      <div className="main-content">
        <div className="main-banner">
          <div className="dashboard-wrapper resumes-page">
            <div className="container">
              <div className="dashboard-box dashboard-col-bg public-wrapper outline-form">
                {/* <div className="text-right">
                                    <span className="icon icon-close ml-auto black cursor-pointer"></span>
                                </div> */}
                {!displayExpired && (
                  <Form noValidate>
                    <Row>
                      <Col md={9} className="mx-auto">
                        <h1>Reset Password</h1>
                        <h1 className="mt-5 mb-4 pb-3 pt-3">
                          {/* Reset Password */}
                        </h1>
                        <Row>
                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="password"
                                name="forgotpassword"
                                placeholder="Password *"
                                value={state.forgotPassword}
                                onChange={handleInputChange}
                                className="inputclass form-control"
                                maxLength={50}
                              />
                            </div>
                            {forgotPasswordError && (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: 12,
                                  padding: 6,
                                }}
                              >
                                Enter new password
                              </p>
                            )}
                          </Col>
                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="password"
                                name="confirmpassword"
                                placeholder="Confirm Password *"
                                value={state.confirmPassword}
                                onChange={handleInputChange}
                                className="inputclass form-control"
                                maxLength={50}
                              />
                            </div>
                            {confirmPasswordError && (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: 12,
                                  padding: 6,
                                }}
                              >
                                Enter confirm password
                              </p>
                            )}
                          </Col>
                        </Row>
                        {passwordMatch && (
                          <p
                            style={{
                              color: "blue",
                              fontSize: 12,
                              padding: 6,
                            }}
                          >
                            Passwords matches
                          </p>
                        )}
                        {passwordMatchError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: 12,
                              padding: 6,
                            }}
                          >
                            Passwords do not match
                          </p>
                        )}
                        {regexError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: 12,
                              padding: 6,
                            }}
                            className="password-note"
                          >
                            Be a minimum of 8 characters
                            <br />
                            Include at least one uppercase letter (A-Z)
                            <br />
                            Include at least one lowercase letter (a-z)
                            <br />
                            One number (0-9) and symbol (@, #, $, %, etc.)
                          </p>
                        )}
                        <Row>
                          <Col md={8} className="square-radius mt-5">
                            <Buttons
                              //variant="warning"
                              title="Reset"
                              // type="submit"
                              // size="xs"
                              color="white"
                              onClick={handleSubmit}
                            ></Buttons>
                            <span style={{ marginLeft: 18 }}></span>
                            <Buttons
                              // variant="warning"
                              title="Cancel"
                              // type="submit"
                              // size="xs"
                              color="white"
                              onClick={() => {
                                history.push(process.env.PUBLIC_URL + "/home");
                              }}
                            ></Buttons>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                )}
                {displayExpired && (
                  <Form noValidate>
                    <Row>
                      <Col md={9} className="mx-auto">
                        <h1>Reset Password</h1>
                        <h1 className="mt-5 mb-4 pb-3 pt-3">
                          <p className="password-note">
                            The link you are trying to access is expired.
                          </p>
                        </h1>
                      </Col>
                    </Row>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
