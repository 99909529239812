import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button, ProgressBar } from "react-bootstrap";

import StartupHeader from "../../../Components/StartupHeader";
import VideoPlayer from "./VideoPlayer";

// VNEXT: Replace the following hard-coded data with data from the database
// const groupName = "Church of Jesus Christ of Latter-day Saints";
// const industryName = "Construction";
// const jobSeekerName = "David Thompson";
const introVideoParts = [
  {
    categoryTitle: "Introduction",
    categoryDescription: "",
    videoUrl:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/v1-sample-group-intro.mp4",
    videoStyle: "landscape",
    timeStampDetails: [
      {
        title: "Introduction to JobInfoNetwork",
        startTime: 0,
        endTime: 10,
        description: "",
      },
      {
        title: "Two Important Points",
        startTime: 10,
        endTime: 58,
        description:
          "**a.** Employed people are motivated to assist job seekers within their Church, Charitable Organization, etc.\n\n**b.** Employed people should be able to decide if/when job seeker receives their contact info.",
      },
      {
        title: "Enormous, Untapped Potential",
        startTime: 58,
        endTime: 60 + 18,
        description: "",
      },
      {
        title: "JobInfoNetwork Leverages This Potential",
        startTime: 60 + 18,
        endTime: 60 + 38,
        description: "",
      },
      {
        title: "Emails Received Re:JobInfoNetwork",
        startTime: 60 + 38,
        endTime: 60 + 52,
        description: "",
      },
    ],
  },
  // Placeholder for demo video
  {
    categoryTitle: "Job Seeker Sends A Networking Request",
    timeStampDetails: [{ startTime: 0, endTime: 0 }],
  },
  {
    categoryTitle: "Employed Member Receives Networking Request",
    timeStampDetails: [{ startTime: 0, endTime: 0 }],
  },
  {
    categoryTitle: "Job Seeker Receives Response",
    timeStampDetails: [{ startTime: 0, endTime: 0 }],
  },
];
const demoVideoParts = [
  {
    categoryTitle: "Introduction",
    timeStampDetails: [{ startTime: 0, endTime: 0 }],
  },
  {
    categoryTitle: "Job Seeker Sends A Networking Request",
    categoryDescription: "",
    videoUrl:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/bothel-stake-v2.mp4",
    videoStyle: "portrait",
    timeStampDetails: [
      {
        title: "Login as a Job Seeker",
        startTime: 0,
        endTime: 32,
        description: "",
        slideText: `**Employed Members:**\n Employed Individuals who are members of The Bothell Stake\n\n**Dave Thompson:**\nSample Construction Job Seeker`,
      },
      {
        title: "Navigate to the Networking Page",
        startTime: 32,
        endTime: 53,
        description: "",
        slideText: `Dave Networks`,
      },
      {
        title: "Filter by Industry and Group",
        startTime: 53,
        endTime: 60 + 24,
        description: "",
        slideText: `Dave Filters by Industry and Group`,
      },
      {
        title: "Select an employed Member to network with",
        startTime: 60 + 24,
        endTime: 60 * 2 + 31,
        description: "",
        slideText: "Dave identifies ideal network candidates",
      },
      {
        title: "Send a Saved Resume with the Networking Request",
        startTime: 60 * 2 + 31,
        endTime: 60 * 2 + 47,
        description: "",
        slideText:
          "Job Seeker Dave sends resume & request to network to chosen Employed Members",
      },
      {
        title: "Job Seeker Logs Out",
        startTime: 60 * 2 + 47,
        endTime: 60 * 3 + 8,
        description: "",
        slideText: "Dave's Networking was Quick and Simple",
      },
    ],
  },
  {
    categoryTitle: "Employed Member Receives Networking Request",
    categoryDescription: "",
    videoUrl:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/bothel-stake-v2.mp4",
    videoStyle: "portrait",
    timeStampDetails: [
      {
        title: "Employed Member logs in to account",
        startTime: 60 * 3 + 8,
        endTime: 60 * 3 + 26,
        description: "",
        slideText:
          "Employed Member & Site Foreman, **Robert Watson**\n logs in",
      },
      {
        title: "Networking Request Notification",
        startTime: 60 * 3 + 26,
        endTime: 60 * 3 + 37,
        description: "",
        slideText:
          "Employed Member Robert received notice of networking request in Messages and in his email",
      },
      {
        title: "View Networking Request in Messages",
        startTime: 60 * 3 + 37,
        endTime: 60 * 3 + 46,
        description: "",
        slideText:
          "Employed Member Robert received notice of networking request in Messages and in his email",
      },
      {
        title: "Navigate to the Connections Page",
        startTime: 60 * 3 + 46,
        endTime: 60 * 4 + 6,
        description: "",
        slideText: "Robert goes to **Connections**",
      },
      {
        title: "Reviews Job Seeker's resume",
        startTime: 60 * 4 + 6,
        endTime: 60 * 4 + 34,
        description: "",
        slideText: `Employed Member Robert reviews Dave's resume, thinking of ways to help`,
      },
      {
        title: "Review 3 response options",
        startTime: 60 * 4 + 34,
        endTime: 60 * 5 + 19,
        description: "",
        slideText: "Employed Member selects 1 of 3 potential responses",
      },
      {
        title: "Respond to the Job Seeker",
        startTime: 60 * 5 + 19,
        endTime: 60 * 5 + 50,
        description: "",
        slideText:
          "Employed Member Robert chooses the 1st response and provides contact information",
      },
      {
        title: "Employed Member Logs Out",
        startTime: 60 * 5 + 49,
        endTime: 60 * 6 + 4,
        description: "",
        slideText:
          "It was Quick and Simple for Employed Member Robert to Respond",
      },
    ],
  },
  {
    categoryTitle: "Job Seeker Receives Response",
    categoryDescription: "",
    videoUrl:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/bothel-stake-v2.mp4",
    videoStyle: "portrait",
    timeStampDetails: [
      {
        title: "Job Seeker logs in to account ",
        startTime: 60 * 6 + 4,
        endTime: 60 * 6 + 24,
        description: "",
        slideText:
          "Job Seeker received notice in his account **and** in email that the Site Foreman at Evergreen Builders requested contact.",
      },
      {
        title: "Navigate to the Connections Page",
        startTime: 60 * 6 + 24,
        endTime: 60 * 6 + 44,
        description: "",
        slideText: `Job Seeker Dave goes to **Connections**`,
      },
      {
        title: "View the Employed Member's response",
        startTime: 60 * 6 + 44,
        endTime: 60 * 7 + 11,
        description: "",
        slideText:
          "Dave clicks on the Response and the full message is displayed",
      },
      {
        title: "Summary of Networking Example",
        startTime: 60 * 7 + 11,
        endTime: 60 * 8 + 25,
        description: "",
        slideText:
          "There is Real Untapped Potential:\n\n**a.** Employed Members in the Bothell Stake are motivated to assist job seekers in the Bothell Stake. \n\n**b.** Bothell Stake job seekers can network in their industry with those who are motivated to help.",
      },
    ],
  },
];

export default function ShowVideoBothel() {
  const [activeSection, setActiveSection] = useState(0);
  const [timerPaused, setTimerPaused] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(10);

  const emailImageUrl =
    "https://elqmq.upcloudobjects.com/demo-videos/mod/emails.png";

  useEffect(() => {
    document.body.classList.add("home-page");

    // Preload the image
    const img = new Image();
    img.src = emailImageUrl;

    return function cleanup() {
      document.body.classList.remove("home-page");
    };
  }, []);

  useEffect(() => {
    let interval;
    if (activeSection === 1 && !timerPaused && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeRemaining === 0 && activeSection === 1) {
      setTimeRemaining(10);
      setActiveSection(2); // Move to the next section when time runs out
    }

    return () => clearInterval(interval);
  }, [activeSection, timerPaused, timeRemaining]);

  const togglePause = () => {
    if (timerPaused) {
      setTimeRemaining(10); // Reset timer when proceeding to next section
      setActiveSection(2);
      setTimerPaused(false);
    } else {
      setTimerPaused(!timerPaused); // Toggle pause state
    }
  };

  // Calculate progress percentage
  const progress = ((10 - timeRemaining + 1) / 10) * 100;

  return (
    <React.Fragment>
      <StartupHeader />

      <div className="main-content">
        <div className="main-banner" style={{ height: "100vh" }}>
          <div className="dashboard-wrapper resumes-page">
            <Container fluid style={{ maxWidth: "1500px" }}>
              <div className="dashboard-col-bg public-wrapper outline-form">
                <Row className="justify-content-md-center">
                  <Col md={12} className="mx-auto">
                    <h1 className="mb-5 ml-3">JobInfoNetwork</h1>

                    {/* Intro Video */}
                    {activeSection === 0 && (
                      <VideoPlayer
                        videoUrl={
                          "https://elqmq.upcloudobjects.com/demo-videos/mod/v1-sample-group-intro.mp4"
                        }
                        timeStampCategories={introVideoParts}
                        useLandscape={true}
                        onCategoryClick={(index) => {
                          if (index === 0) {
                            setActiveSection(0);
                          } else {
                            setActiveSection(2);
                          }
                        }}
                        onVideoEnd={() => setActiveSection(1)}
                      />
                    )}

                    {/* Emails */}
                    {activeSection === 1 && (
                      <div>
                        <img
                          style={{ width: "100%" }}
                          src="https://elqmq.upcloudobjects.com/demo-videos/mod/emails.png"
                        />
                        <div className="mt-3">
                          <ProgressBar now={progress} animated={true} />
                          <Button onClick={togglePause} className="mt-3">
                            {timerPaused ? "Next" : "Pause"}
                          </Button>
                        </div>
                      </div>
                    )}

                    {/* Main Demo Video */}
                    {activeSection === 2 && (
                      <VideoPlayer
                        videoUrl={
                          "https://elqmq.upcloudobjects.com/demo-videos/mod/bothel-stake-v2.mp4"
                        }
                        timeStampCategories={demoVideoParts}
                        useLandscape={false}
                        onCategoryClick={(index) => {
                          if (index === 0) {
                            setActiveSection(0);
                          } else {
                            setActiveSection(2);
                          }
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
