/* eslint-disable eqeqeq */

import React, { useState, Component, Fragment, useEffect, useRef } from "react";
import {
  FaRegCommentAlt,
  FaRegBell,
  FaSearch,
  FaTimes,
  FaWindowClose,
  FaCross,
} from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import { MdLockOutline } from "react-icons/md";
import { API_URL, Roles, SearchTypes, timerDelay } from "../Utilities/Helper";
import { Navbar, Button, Dropdown, Modal } from "react-bootstrap";

import { NavLink, useLocation } from "react-router-dom";
import profile from "../assests/img/profile.png";
import Logout from "../Services/LogoutService";
import { useHistory } from "react-router";
import Buttons from "./Shared/Button";
import { toast } from "react-toastify";
import { getUserDetails } from "../Services/GetFields";
import {
  getNotificationsList,
  getNotificationsUnreadCount,
  readNotification,
} from "../Services/NotificationService";
import {
  getSearchResult,
  getSearchTypes,
} from "../Services/GlobalSearchServices";
import { useDispatch } from "react-redux";
import { globalSearchAction } from "../Redux/GlobalSearch/Action";
import { FaBars } from "react-icons/fa";
import { get } from "../Services/AxiosService";
import { getPictureAPI, getUserDetailsAPI } from "../Utilities/APIHelper";
import { getData, setData } from "../Utilities/StorageHelper";

export default function Header({ isOpen, toggle, imageChange, imagetoggle }) {
  const history = useHistory();
  const signout = () => {
    Logout();
    sessionStorage.clear();
    setDisplaySearchOption((prev) => false);
    ClearSearch();
    setUnreadCount("");
    imagetoggle();
    dispatch(
      globalSearchAction({
        type: "clear",
      })
    );
    setUnReadMessageCount("");
    setUnreadCount("");
    history.push(process.env.PUBLIC_URL + "/home");
  };

  const [showAbuseReport, setShowAbuseReport] = useState(false);
  const [showSponsorshipRequest, setShowSponsorshipRequest] = useState(false);
  const [reportAbuseUrl, setReportAbuseUrl] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userName, setUserName] = useState("");
  const [id, setId] = useState("");
  const [image, setImage] = useState(false);
  const [displaySearchOption, setDisplaySearchOption] = useState(false);
  const [globalSearch, setGlobalSearch] = useState([]);
  const [searchForm, setSearchForm] = useState({
    recordperpage: 10,
    SerachText: "",
    GlobalSearchTypeId: 0,
  });
  const [notification, setNotifications] = useState([]);
  const [searchTypeText, setSearchTypeText] = useState("");
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [unreadCount, setUnreadCount] = useState("");
  const [unReadMessageCount, setUnReadMessageCount] = useState("");
  const [showUserMenu, setShowUserMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    console.log(location);

    let path = window.location.href;
    const pathParts = path.split("/");
    if (
      6 === pathParts.length &&
      (pathParts[4] === "accountinformation") & (pathParts[5] === "menu")
    ) {
      dropdownClickHandler();
      setShowUserMenu(true);
    }
  }, [location]);

  useEffect(() => {
    let isLoggedIn = getData("token");
    if (isLoggedIn) {
      get(getUserDetailsAPI)
        .then((res) => {
          let response = res.data.response;
          setData("name", response.firstName);
        })
        .catch((err) => {
          console.log(err);
        });

      get(getPictureAPI)
        .then((res) => {
          if (res.data.response) {
            setImage(res.data.response);
          }
        })
        .catch((err) => {
          setImage(false);
          console.log(err);
        });
    } else {
      setImage(false);
    }
  }, [imageChange]);

  useEffect(() => {
    getNotifications();
    getUnreadCount();
    getUnreadMessageCount();
  }, [refresh, imageChange]);

  useEffect(() => {
    let isLoggedIn = getData("token");

    try {
      if (isLoggedIn) {
        setInterval(async () => {
          getUnreadCount();
          getUnreadMessageCount();
        }, timerDelay);
      }
    } catch (e) {
      console.log(e);
    }
  }, [image, refresh]);

  const markAsRead = (id) => {
    // let data = JSON.stringify({
    //   userId: id,
    // });
    let data = id;
    readNotification(data)
      .then((res) => {
        // if (res.status === 200)
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNotifications = () => {
    let isLoggedIn = getData("token");
    if (isLoggedIn) {
      let filter = "?recordperpage=" + 10;
      getNotificationsList(filter)
        .then((res) => {
          // console.log(res);
          setNotifications(res?.notificationModels);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getUnreadCount = () => {
    let isLoggedIn = getData("token");
    if (isLoggedIn) {
      // let filter = "?recordperpage=" + 10;
      // getNotificationsUnreadCount()
      //   .then((res) => {
      //     // console.log(res.totalUnreadCount, 'totalUnreadCount');
      //     setUnreadCount(res.totalUnreadCount);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //     if (error.response) {
      //       console.log(error.response); //data.error
      //     } else if (error.request) {
      //       console.log(error.request);
      //     } else {
      //       // Something happened in setting up the request that triggered an Error
      //       console.log("Error", error.message);
      //       // if (error.message === "Unauthorized") {
      //       //  RefreshTokenService();
      //       // }
      //     }
      //   });

      get(`${API_URL}Notification/GetCountForAllUsers`)
        .then((response) => {
          if (response.status === 200) {
            setUnreadCount(response.data.totalUnreadCount);
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
  };

  const getUnreadMessageCount = () => {
    let isLoggedIn = getData("token");
    if (isLoggedIn) {
      get(`${API_URL}user/unreadmessagecount`)
        .then((response) => {
          if (response.status === 200) {
            setUnReadMessageCount(response.data.response);
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
  };

  const dropDownToggleHandler = (isOpen) => {
    setShowUserMenu(isOpen);
  };

  const dropdownClickHandler = () => {
    //document.body.classList.toggle('modal-open');

    //setShowUserMenu((prev) => !prev);

    let isLoggedIn = getData("token");
    if (isLoggedIn) {
      let headerRole = getData("role");
      let name = getData("name");

      setUserRole(headerRole);
      setUserName(name);

      // get(API_URL + "user/userdetails").then((res) => {
      //   // console.log(res.data.response);
      //   let response = res.data.response;
      //   setId(response.id);
      // })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      getUserDetails()
        .then((res) => {
          // console.log(res.response);
          let response = res.response;
          setId(response.id);
          setUserName(response.firstName);
        })
        .catch((err) => {
          console.log(err);
        });

      if (getData("role") === Roles.jobSeeker) {
        setShowSponsorshipRequest(true);
      } else {
        setShowSponsorshipRequest(false);
      }

      if (getData("role") !== null) {
        const role = getData("role").toString();
        if (role == Roles.groupLeader || role == Roles.admin) {
          setShowAbuseReport(false);
        } else {
          if (role == Roles.jobSeeker)
            setReportAbuseUrl("jobseeker/reportabuse");
          if (role == Roles.stm) setReportAbuseUrl("support/reportabuse");
          if (role == Roles.employer) setReportAbuseUrl("employer/reportabuse");
          setShowAbuseReport(true);
        }
      }
    }
  };

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState({
    show: false,
    id: null,
  });

  const openDeleteConfirmModal = () => {
    setShowDeleteConfirmModal({
      show: true,
      id: null,
    });
  };

  const closeDeleteConfirmModal = () => {
    setShowDeleteConfirmModal({
      show: false,
      id: null,
    });
  };

  const deleteAccountHandler = () => {
    const headers = {
      Authorization: "Bearer " + getData("token"),
      "content-type": "application/json",
    };
    const toastId = "custom_toast";
    let path = "";
    if (userRole === "Employer") {
      path = API_URL + "Employer/DeleteEmployer?EmployerId=";
    } else if (userRole === "Support Team Member") {
      path =
        API_URL +
        "SupportTeamMember/DeleteSupportTeamMember?SupportTeamMemberId=";
    } else if (userRole === "Job Seeker") {
      path = API_URL + "Jobseeker/DeleteJobseeker?JobseekerId=";
    } else if (userRole === "Group Leader") {
      path =
        API_URL +
        "AffiliateGroupLeader/DeleteAffiliateGroupLeader?AffiliateGroupLeaderId=";
    }
    // axios.get(path + id, { headers: headers })
    get(path + id)
      .then((response) => {
        // console.log(response, response.message, "response success");
        if (response.status === 200) {
          toast.success("Account Deleted", { toastId: toastId });
          sessionStorage.clear();
          history.push(process.env.PUBLIC_URL + "/home");
          // window.location.reload();
        }
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(error.response);
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        //  console.log(error.config);
      });
  };

  const searchTypeChange = (e) => {
    const val = e.target.value;
    var index = e.nativeEvent.target.selectedIndex;
    const Text = e.nativeEvent.target[index].text;
    setSearchTypeText(Text);
    setSearchForm((prev) => ({ ...prev, GlobalSearchTypeId: val }));
  };

  const searchTextChange = (e) => {
    const val = e.target.value;
    setSearchForm((prev) => ({ ...prev, SerachText: val }));
  };

  const ClearSearch = () => {
    dispatch(globalSearchAction({ type: "clear" }));
    setSearchForm((prev) => ({
      ...prev,
      SerachText: "",
      GlobalSearchTypeId: "0",
    }));
  };

  const onSearchClick = () => {
    if (displaySearchOption === false) {
      getSearchTypes()
        .then((res) => {
          const result = res;
          setGlobalSearch(result);
        })
        .catch((err) => {
          console.log(err);
          // toast.error("something went wrong");
        });
      ClearSearch();
      setDisplaySearchOption((prev) => true);
    } else {
      if (
        searchForm.GlobalSearchTypeId === "0" ||
        searchForm.SerachText.trim() === ""
      ) {
        return;
      }

      var recordperpage = 0;
      if (
        searchTypeText == SearchTypes.privateResume ||
        searchTypeText == SearchTypes.publicResume ||
        searchTypeText == SearchTypes.jobs
      ) {
        recordperpage = 9;
      } else {
        recordperpage = 10;
      }

      dispatch(
        globalSearchAction({
          recordperpage: recordperpage,
          page: 0,
          SerachText: searchForm.SerachText,
          GlobalSearchTypeId: searchForm.GlobalSearchTypeId,
          searchTypeText: searchTypeText,
          history: history,
        })
      );
    }
  };

  const onSearchCloseClick = () => {
    setDisplaySearchOption((prev) => !prev);
    dispatch({ type: "clear" });
    setGlobalSearch([]);
  };

  return (
    <Navbar
      fixed="top"
      bg="white"
      // className="navbar shadow-sm p-3 d-flex bg-white rounded"
      // className={isOpen ? "is-open" : ""}
      className={
        isOpen ? "is-open navbar shadow-sm p-3 d-flex bg-white rounded " : ""
      }
    >
      <div className="container">
        {/* <Button variant="outline-info" onClick={this.props.toggle}>
          <FontAwesomeIcon icon={faAlignLeft} />
        </Button> */}

        <Button
          className="d-xl-none menu-btn"
          variant="outline-primary"
          onClick={toggle}
        >
          {isOpen ? <span>X</span> : <FaBars />}
        </Button>
        {/* onClick={onSearchClick} */}

        {/* onClick={toggle} */}
        {displaySearchOption && (
          <div className="search-box">
            <input
              type="text"
              value={searchForm.SerachText}
              className="form-control ml-3"
              onChange={(e) => searchTextChange(e)}
            />
            <select
              className="form-control mx-3"
              value={searchForm.GlobalSearchTypeId}
              onChange={(e) => searchTypeChange(e)}
            >
              <option value="0">Select Type</option>
              {globalSearch.map((item, index) => (
                <option key={index} value={item.globalSearchTypeId}>
                  {item.globalSearchType}
                </option>
              ))}
            </select>
          </div>
        )}
        {getData("role") !== Roles.admin && (
          <Button className="search-btn" onClick={onSearchClick}>
            <FaSearch />
          </Button>
        )}
        {displaySearchOption && (
          <Button
            className="btn btn-danger"
            style={{ padding: "8px 13px", marginLeft: "5px" }}
            onClick={onSearchCloseClick}
          >
            <b>X</b>
          </Button>
        )}

        <ul className="ml-auto nav-right d-flex">
          <li className="icon mx-3 position-relative">
            {getData("role") === Roles.employer && (
              <NavLink
                activeClassName="is-active"
                to={`${process.env.PUBLIC_URL}/employer/messages`}
              >
                <FaRegCommentAlt />
                {unReadMessageCount != 0 && (
                  <span class="num-count">{unReadMessageCount}</span>
                )}
              </NavLink>
            )}
            {getData("role") === Roles.jobSeeker && (
              <NavLink
                activeClassName="is-active"
                to={`${process.env.PUBLIC_URL}/jobseeker/messages`}
              >
                <FaRegCommentAlt />{" "}
                {unReadMessageCount != 0 && (
                  <span class="num-count">{unReadMessageCount}</span>
                )}
              </NavLink>
            )}
            {getData("role") === Roles.stm && (
              <NavLink
                activeClassName="is-active"
                to={`${process.env.PUBLIC_URL}/support/messages`}
              >
                <FaRegCommentAlt />{" "}
                {unReadMessageCount != 0 && (
                  <span class="num-count">{unReadMessageCount}</span>
                )}
              </NavLink>
            )}
            {/* {getData("role") === Roles.groupLeader && (
              <NavLink
                activeClassName="is-active"
                to={`${process.env.PUBLIC_URL}/messages`}
              >
                <FaRegCommentAlt />
              </NavLink>
            )} */}
          </li>
          {/* <li className="icon mx-2">
            <a href="javascript:void(0)" activeClassName="is-active">
              <FaRegBell />
            </a>
          </li> */}
          <li className="notification-dropdown">
            <Dropdown
              onClick={() => {
                getNotifications();
              }}
            >
              <Dropdown.Toggle>
                <FaRegBell />
                {unreadCount != 0 && (
                  <span class="num-count">{unreadCount}</span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {notification?.length != 0 &&
                  notification?.map((item) => (
                    <h1
                      key={`notification-${item.id}`}
                      onClick={() => {
                        if (!item.isSeen) markAsRead(item.userId);
                      }}
                      style={{
                        backgroundColor: item.isSeen ? "#ffffff" : "#f3f9fd",
                        cursor: "pointer",
                      }}
                      className="notification-text"
                    >
                      <FaRegBell style={{ marginRight: 14, marginLeft: -2 }} />
                      <small>{item.message}</small>
                    </h1>
                  ))}
                <div className="view-notification-btn">
                  {notification?.length != 0 && (
                    <Dropdown.Item as="button" className="profile-btns mt-4">
                      <NavLink
                        activeClassName="is-active"
                        to={`${process.env.PUBLIC_URL}/notifications`}
                        className="text-decoration-none btn btn-warning fill-signout"
                      >
                        View more
                      </NavLink>
                    </Dropdown.Item>
                  )}
                </div>
                {notification?.length == 0 && (
                  <Dropdown.Item disabled>
                    No recent notifications!
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="ml-4 profile-dropdown signed-profile-dropdown">
            <Dropdown
              onClick={dropdownClickHandler}
              onToggle={(isOpen) => dropDownToggleHandler(isOpen)}
              show={showUserMenu}
            >
              <Dropdown.Toggle className="profile" id="dropdown-basic">
                {image ? (
                  <img
                    src={`https://data.elqmq.upcloudobjects.com/ProfilePhotos/${image}`}
                    alt="Logo"
                  />
                ) : (
                  <img className="img-fluid" src={profile} alt="Logo" />
                )}
              </Dropdown.Toggle>
              <div className="overlay-dropdown d-none"></div>
              <Dropdown.Menu>
                <h1 className="sub-form-title mb-1">
                  Welcome {userName}! <br />
                  <small>({userRole})</small>
                </h1>
                <Dropdown.Item as="button" className="profile-btns mb-3">
                  <NavLink
                    activeClassName="is-active"
                    className="text-decoration-none btn btn-dark  w-100"
                    to={`${process.env.PUBLIC_URL}/accountinformation`}
                  >
                    <span className="icon icon-user-solid-square"></span> View
                    Account Information
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item as="button" className="profile-btns mb-3">
                  <NavLink
                    activeClassName="is-active"
                    className="text-decoration-none btn btn-dark  w-100"
                    to={`${process.env.PUBLIC_URL}/changepassword`}
                  >
                    <span className="icon icon-lock"></span> Change Password
                  </NavLink>
                </Dropdown.Item>
                {showSponsorshipRequest && (
                  <Dropdown.Item as="button" className="profile-btns mb-3">
                    <NavLink
                      activeClassName="is-active"
                      className="text-decoration-none btn btn-dark  w-100"
                      to={`${process.env.PUBLIC_URL}/jobseeker/sponsorship`}
                    >
                      <span className="icon icon-people_outline"></span>{" "}
                      Sponsorship Request
                    </NavLink>
                  </Dropdown.Item>
                )}
                {showAbuseReport && (
                  <Dropdown.Item as="button" className="profile-btns mb-3">
                    <NavLink
                      activeClassName="is-active"
                      className="text-decoration-none btn btn-dark  w-100"
                      to={`${process.env.PUBLIC_URL}/${reportAbuseUrl}`}
                    >
                      <span className="icon icon-documents"></span> Report Abuse
                    </NavLink>
                  </Dropdown.Item>
                )}
                {getData("role") !== Roles.admin && (
                  <Dropdown.Item as="button" className="profile-btns mb-3">
                    <Button
                      className="text-decoration-none btn btn-dark  w-100"
                      onClick={openDeleteConfirmModal}
                    >
                      {" "}
                      <span className="icon icon-trash"></span> Delete Account
                    </Button>
                  </Dropdown.Item>
                )}
                <Dropdown.Item as="button" className="profile-btns mt-4">
                  <NavLink
                    activeClassName="is-active"
                    onClick={signout}
                    to={``}
                    className="text-decoration-none btn btn-warning fill-signout"
                  >
                    Sign out
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="icon ml-4 header-signout-btn">
            <Button
              variant="outline-primary"
              className="sign-out-btn"
              onClick={signout}
            >
              Sign out
            </Button>
          </li>
        </ul>
      </div>
      <Modal
        show={showDeleteConfirmModal.show}
        onHide={closeDeleteConfirmModal}
        centered
        className="confirm-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Delete Account</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={closeDeleteConfirmModal}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            This will delete your Account permanently, are you sure?
          </div>
          <div className="message-footer pt-0">
            <Buttons
              variant="primary"
              title="Confirm"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                deleteAccountHandler();
                closeDeleteConfirmModal();
              }}
            ></Buttons>
            <Buttons
              variant="secondary"
              title="Cancel"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                closeDeleteConfirmModal();
              }}
            ></Buttons>
          </div>
        </div>
      </Modal>
    </Navbar>
  );
}
