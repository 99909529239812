/* eslint-disable eqeqeq */

import React, { useState, Component, useEffect, useRef } from "react";
import { Col, Row, Form, Alert } from "react-bootstrap";
import { Route, Switch, Redirect, Link, NavLink } from "react-router-dom";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import {
  passwordRegex,
  validateCharacterAndSpace,
  validateCharacterSpaceAndDigit,
  validateCity,
  validateCompany,
  validatePosition,
  Validators,
} from "../../../Utilities/Validator";
import publiclogo from "../../../assests/img/logo-public.png";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

import {
  Navbar,
  Button,
  Nav,
  ButtonDropdown,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  NavDropdown,
  Image,
} from "react-bootstrap";
import { postJob } from "../../../Redux/Jobs/Action";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../../../Redux/signUp/Action";
import Select from "react-select";
import {
  getCountry,
  getGroupCategoriesByGroup,
  getGroups,
  getIndustry,
  getStateByCountry,
} from "../../../Services/GetFields";
import axios from "axios";
import jwt from "jwt-decode";
import { API_URL, loginAPI, Roles, sitekey } from "../../../Utilities/Helper";
import { getLoginAction } from "../../../Redux/Login/Action";
import { useHistory } from "react-router";
import StartupHeader from "../../../Components/StartupHeader";
import { setData } from "../../../Utilities/StorageHelper";

const options = [
  { label: "Healthcare", value: 2 },
  { label: "Finance", value: 3 },
  { label: "Automobile", value: 4 },
  { label: "Infrastructure", value: 5 },
];

const optionsForSupportTeam = [
  { label: "Healthcare", value: 2 },
  { label: "Finance", value: 3 },
  { label: "Automobile", value: 4 },
  { label: "Infrastructure", value: 5 },
  { label: "JIN Advisor", value: 6 },
];

//if in map funct if jin advisor and stm

let employer_message =
  "Receive emails of newly posted resumes in your selected industries";
let jobseeker_message =
  "Receive emailed Job Postings for your selected industries";

function Register() {
  // const { password, confirmPassword } = this.state;
  const dispatch = useDispatch();
  const history = useHistory();
  const toastId = "custom_toast";
  const nameRef = useRef();

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [jobType, setJobType] = useState("");

  const [firstName, setFirstName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [subOrganizationName, setSubOrganizationName] = useState("");

  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [formattedPhone, setFormattedPhone] = useState("");

  const [company, setCompany] = useState("");
  const [showStateTextBox, setShowStateTextBox] = useState(false);

  const [country, setCountry] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [countryError, setCountryError] = useState(false);

  const [countryState, setCountryState] = useState("");
  const [countryStateList, setCountryStateList] = useState([]);
  const [countryStateError, setCountryStateError] = useState(false);

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState(false);

  const [group, setGroup] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [subcategoryError, setSubcategoryError] = useState(false);
  const [groupError, setGroupError] = useState(false);

  const [position, setPosition] = useState("");
  const [positionError, setPositionError] = useState(false);

  const [industry, setIndustry] = useState("");
  const [industryList, setIndustryList] = useState([]);

  const [acceptence, setAcceptence] = useState(false);
  const [resumeEmail, setResumeEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [typeOfAccount, setTypeOfAccount] = useState("");
  const [reenterPassword, setReenterPassword] = useState("");
  const [organizationNameError, setOrganizationNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState(false);
  const [acceptError, setAcceptError] = useState(false);
  const [typeOfAccountError, setTypeOfAccountError] = useState(false);
  const [acceptenceError, setAcceptenceError] = useState(false);
  const [doNotMatch, setDoNotMatch] = useState(false);
  const [regExError, setRegExError] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);
  const [dropdown, setDropdown] = useState("SignIn");
  const [firstNameValidError, setFirstNameValidError] = useState(false);
  const [lastNameValidError, setLastNameValidError] = useState(false);
  const [companyNameValidError, setCompanyNameValidError] = useState(false);
  const [organizationNameValidError, setOrganizationNameValidError] =
    useState(false);
  const [emailValidError, setEmailValidError] = useState(false);
  const [phoneValidError, setPhoneValidError] = useState(false);
  const [stateValidError, setStateValidError] = useState(false);
  const [cityValidError, setCityValidError] = useState(false);
  const [positionValidError, setPositionValidError] = useState(false);

  const [terms, setTerms] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [captchaError, setCaptchaValidError] = useState(false);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    async function getAllCountryList() {
      getCountry()
        .then((res) => {
          const result = JSON.parse(res.response);
          setCountryList(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getAllCountryList();

    async function getAllIndustryList() {
      getIndustry()
        .then((res) => {
          const result = JSON.parse(res.response);
          let industryArray = [];
          for (let i = 0; i < result.length; i++) {
            industryArray.push({
              label: result[i].industryName,
              value: result[i].industryId,
            });
          }
          setIndustryList(industryArray);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getAllIndustryList();

    async function getAllGroupList() {
      getGroups()
        .then((res) => {
          const result = JSON.parse(res.response);
          setGroupList(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getAllGroupList();
  }, []);

  const dropdownToggle = (e) => {
    e.preventDefault();
    setState({ email: "", password: "" });
    setDropdown("ForgotPassword");
    // console.log(dropdown);
  };

  const handleSubmit = () => {
    if (dropdown === "SignIn") {
      if (
        state.email == "" ||
        (state.email == undefined && state.password == "") ||
        state.password == undefined
      ) {
        setEmailError(true);
        setPasswordError(true);
      } else {
        setEmailError(false);
        setPasswordError(false);
        // const role = loginData.find((t) => t.username === state.email);
        // if (role != undefined) {

        // let userRole = Login(state.email, state.password);
        // console.log('userRole', userRole);
        let params = new URLSearchParams();
        params.append("username", state.email);
        params.append("password", state.password);
        params.append("client_id", "JIN_spa");
        params.append("grant_type", "password");
        params.append("scope", "openid");

        var options = {
          method: "POST",
          url: loginAPI,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: params,
        };

        axios
          .request(options)
          .then((response) => {
            if (response.status === 200) {
              // console.log('data', response);
              const token = response.data.access_token;
              const user = jwt(token);
              // console.log('user', user);
              // console.log('expiry time', user.exp);
              // console.log('role', user.roles);

              setData("token", token);
              //setData("user_name", user.name);
              setData("expiry_time", response.data.expires_in);
              setData("role", user.roles);

              if (user.roles === "Employer") {
                dispatch(getLoginAction("first"));
                history.push(`${process.env.PUBLIC_URL}/dashboard`);
              } else if (user.roles === "Support Team Member") {
                dispatch(getLoginAction("second"));
                history.push(
                  `${process.env.PUBLIC_URL}/support/support-dashboard`
                );
              } else if (user.roles === "Job Seeker") {
                dispatch(getLoginAction("third"));
                history.push(`${process.env.PUBLIC_URL}/seeker`);
              } else if (user.roles === "Group Leader") {
                dispatch(getLoginAction("fourth"));
                history.push(
                  `${process.env.PUBLIC_URL}/groupleader/groupleaderdashboard`
                );
              }
            }
          })
          .catch(function (error) {
            console.error("error", error);
          });
      }
    }
  };

  const handleInputChange = (event) => {
    setState((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const handleIndustryChange = (event) => {
    let array = [];
    for (let i = 0; i < event.length; i++) {
      array.push({ IndustryId: event[i].value });
    }
    setIndustry(array);
  };

  const handleChange = (event) => {
    // console.log(event.target.value, "value");

    if (event.target.name === "firstname") {
      // if (event.target.value === "") {
      //   setFirstNameError(true);
      // } else {
      //   let value = Validators.isName(event.target.value, "firstname");
      //   if (value.error === true) {
      //     setFirstNameValidError(true);
      //   } else {
      //     setFirstNameValidError(false);
      //   }
      //   setFirstNameError(false);
      // }
      setFirstName(event.target.value);
    }
    if (event.target.name === "lastname") {
      // if (event.target.value === "") {
      //   setLastNameError(true);
      // } else {
      //   let value = Validators.isName(event.target.value, "lastname");
      //   if (value.error === true) {
      //     setLastNameValidError(true);
      //   } else {
      //     setLastNameValidError(false);
      //   }
      //   setLastNameError(false);
      // }
      setLastName(event.target.value);
    }
    if (event.target.name === "company") {
      // if (event.target.value === "") {
      //   setCompanyNameError(true);
      // } else {
      //   let value = validateCharacterSpaceAndDigit(
      //     event.target.value,
      //     "company"
      //   );
      //   if (value.error === true) {
      //     setCompanyNameValidError(true);
      //   } else {
      //     setCompanyNameValidError(false);
      //   }
      //   setCompanyNameError(false);
      // }
      setCompany(event.target.value);
    }
    if (event.target.name === "phone") {
      // if (event.target.value === "") {
      //   setPhoneError(true);
      // } else {
      //   let value = Validators.isPhone(event.target.value, "phone");
      //   if (value.error === true) {
      //     setPhoneValidError(true);
      //   } else {
      //     setPhoneValidError(false);
      //   }
      //   setPhoneError(false);
      // }
      // let phoneValue = Validators.formatPhoneNumber(event.target.value);
      // setFormattedPhone(phoneValue);
      setPhone(event.target.value);
    }
    if (event.target.name === "country") {
      if (event.target.value === "") {
        setCountryError(true);
      } else {
        setCountryError(false);
      }
      setCountry(event.target.value);
    }
    if (event.target.name === "state") {
      if (event.target.value === "") {
        setCountryStateError(true);
      } else {
        let value = validateCharacterSpaceAndDigit(event.target.value, "state");
        if (value.error === true) {
          setStateValidError(true);
        } else {
          setStateValidError(false);
        }
        setCountryStateError(false);
      }
      setCountryState(event.target.value);
      // console.log(countryState);
    }
    if (event.target.name === "city") {
      // if (event.target.value === "") {
      //   setCityError(true);
      // } else {
      //   let value = validateCharacterAndSpace(event.target.value, "city");
      //   if (value.error === true) {
      //     setCityValidError(true);
      //   } else {
      //     setCityValidError(false);
      //   }
      //   setCityError(false);
      // }
      setCity(event.target.value);
    }
    if (event.target.name === "group") {
      if (event.target.value === "") {
        setGroupError(true);
      } else {
        setGroupError(false);
      }
      setGroup(event.target.value);
    }
    if (event.target.name === "subcategory") {
      if (event.target.value === "") {
        setSubcategoryError(true);
      } else {
        setSubcategoryError(false);
      }
      setSubcategory(event.target.value);
    }
    if (event.target.name === "position") {
      // if (event.target.value === "") {
      //   setPositionError(true);
      // } else {
      //   let value = validateCharacterSpaceAndDigit(
      //     event.target.value,
      //     "position"
      //   );
      //   if (value.error === true) {
      //     setPositionValidError(true);
      //   } else {
      //     setPositionValidError(false);
      //   }
      //   setPositionError(false);
      // }
      setPosition(event.target.value);
    }
    if (event.target.name === "industry") {
      if (event.target.value === "") {
        setPositionError(true);
      } else {
        setPositionError(false);
      }
      // console.log(event.target.value);
      setPosition(event.target.value);
    }
    if (event.target.name === "organizationname") {
      if (event.target.value === "") {
        setOrganizationNameError(true);
      } else {
        let value = validateCharacterSpaceAndDigit(
          event.target.value,
          "organizationname"
        );
        if (value.error === true) {
          setOrganizationNameValidError(true);
        } else {
          setOrganizationNameValidError(false);
        }
        setOrganizationNameError(false);
      }
      setOrganizationName(event.target.value);
    }
    if (event.target.name === "suborganizationname") {
      setSubOrganizationName(event.target.value);
    }
    if (event.target.name === "password") {
      if (event.target.value === "") {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
      var regularExpression =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/;
      // if (event.target.value != "") {
      //   if (!regularExpression.test(event.target.value)) {
      //     setPasswordError(false);
      //     setRegExError(true);
      //   } else {
      //     setRegExError(false);
      //     setPasswordError(false);
      //   }
      // }
      setPassword(event.target.value);
    }
    if (event.target.name === "confirmpassword") {
      if (event.target.value === "") {
        setReenterPassword(true);
      }
      if (event.target.value != "") {
        if (event.target.value != password) {
          setResetPasswordError(false);
          setDoNotMatch(true);
        } else {
          setDoNotMatch(false);
          setResetPasswordError(false);
        }
      } else {
        setReenterPassword(false);
      }
      setReenterPassword(event.target.value);
    }
    if (event.target.name === "email") {
      // if (event.target.value === "") {
      //   setEmailError(true);
      // } else {
      //   let value = Validators.isEmail(event.target.value, "email");
      //   if (value.error === true) {
      //     setEmailValidError(true);
      //   } else {
      //     setEmailValidError(false);
      //   }
      //   setEmailError(false);
      // }
      setEmail(event.target.value);
    }
    if (event.target.name === "company") {
      // if (event.target.value === "") {
      //   setCompanyNameError(true);
      // } else {
      //   let value = validateCharacterSpaceAndDigit(
      //     event.target.value,
      //     "company"
      //   );
      //   if (value.error === true) {
      //     setCompanyNameValidError(true);
      //   } else {
      //     setCompanyNameValidError(false);
      //   }
      //   setCompanyNameError(false);
      // }
      setCompany(event.target.value);
    }
  };
  const buttonChange = (event) => {
    // console.log(event.target.name, event.target.value, "ggg");

    setJobType(event.target.value);
    setTypeOfAccount(event.target.value);
    if (event.target.value === "") {
      setTypeOfAccountError(true);
    } else {
      setTypeOfAccountError(false);
    }
    checkAccountType();
    // resetForm();
  };

  const handleOnBlurChange = (event) => {
    if (event.target.name === "firstname") {
      if (event.target.value === "") {
        setFirstNameError(true);
      } else {
        let value = Validators.isName(event.target.value, "firstname");
        if (value.error === true) {
          setFirstNameValidError(true);
        } else {
          setFirstNameValidError(false);
        }
        setFirstNameError(false);
      }
      setFirstName(event.target.value);
    }
    if (event.target.name === "lastname") {
      if (event.target.value === "") {
        setLastNameError(true);
      } else {
        let value = Validators.isName(event.target.value, "lastname");
        if (value.error === true) {
          setLastNameValidError(true);
        } else {
          setLastNameValidError(false);
        }
        setLastNameError(false);
      }
      setLastName(event.target.value);
    }
    if (event.target.name === "company") {
      if (event.target.value === "") {
        setCompanyNameError(true);
      } else {
        let value = validateCompany(event.target.value, "company");
        if (value.error === true) {
          setCompanyNameValidError(true);
        } else {
          setCompanyNameValidError(false);
        }
        setCompanyNameError(false);
      }
      setCompany(event.target.value);
    }
    if (event.target.name === "position") {
      if (event.target.value === "") {
        setPositionError(true);
      } else {
        let value = validatePosition(event.target.value, "position");
        if (value.error === true) {
          setPositionValidError(true);
        } else {
          setPositionValidError(false);
        }
        setPositionError(false);
      }
      setPosition(event.target.value);
    }
    if (event.target.name === "phone") {
      if (event.target.value === "") {
        setPhoneError(true);
      } else {
        let value = Validators.isPhone(event.target.value, "phone");
        if (value.error === true) {
          setPhoneValidError(true);
        } else {
          setPhoneValidError(false);
        }
        setPhoneError(false);
      }
      let phoneValue = Validators.formatPhoneNumber(event.target.value);
      setFormattedPhone(phoneValue);
      setPhone(event.target.value);
    }
    if (event.target.name === "email") {
      if (event.target.value === "") {
        setEmailError(true);
      } else {
        let value = Validators.isEmail(event.target.value, "email");
        if (value.error === true) {
          setEmailValidError(true);
        } else {
          setEmailValidError(false);
        }
        setEmailError(false);
      }
      setEmail(event.target.value);
    }
    if (event.target.name === "state") {
      if (event.target.value === "") {
        setCountryStateError(true);
      } else {
        let value = validateCharacterAndSpace(event.target.value, "state");
        if (value.error === true) {
          setStateValidError(true);
        } else {
          setStateValidError(false);
        }
        setCountryStateError(false);
      }
      setCountryState(event.target.value);
      // console.log(countryState);
    }
    if (event.target.name === "city") {
      if (event.target.value === "") {
        setCityError(true);
      } else {
        let value = validateCity(event.target.value, "city");
        if (value.error === true) {
          setCityValidError(true);
        } else {
          setCityValidError(false);
        }
        setCityError(false);
      }
      setCity(event.target.value);
    }
    if (event.target.name === "password") {
      if (event.target.value === "") {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
      var regularExpression =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/;
      if (event.target.value != "") {
        if (!passwordRegex.test(event.target.value)) {
          setPasswordError(false);
          setRegExError(true);
        } else {
          setRegExError(false);
          setPasswordError(false);
        }
      }
      setPassword(event.target.value);
    }
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setCompany("");
    setCountry("");
    setCountryState("");
    setCountryStateList([]);
    setCity("");
    setPassword("");
    setReenterPassword("");
    setIndustry("");
    setResumeEmail(false);
    setAcceptence(false);
    setOrganizationName("");
    setPosition("");
    setGroup("");
    setSubcategory("");

    setFirstNameError(false);
    setFirstNameValidError(false);
    setLastNameError(false);
    setLastNameValidError(false);
    setEmailError(false);
    setEmailValidError(false);
    setPhoneValidError(false);
    setCompanyNameError(false);
    setCompanyNameValidError(false);
    setOrganizationNameError(false);
    setOrganizationNameValidError(false);
    setPositionError(false);
    setPositionValidError(false);
    setCountryError(false);
    setCountryStateError(false);
    setStateValidError(false);
    setCityError(false);
    setCityValidError(false);
    setSubcategoryError(false);
    setGroupError(false);
    setPasswordError(false);
    setResetPasswordError(false);
    setAcceptError(false);
    setAcceptenceError(false);
    setRegExError(false);
    setDoNotMatch(false);
  };

  const checkAccountType = () => {
    // console.log(typeOfAccount, "checkAccountType");
  };
  const acceptenceChange = (event) => {
    // console.log(event.target.value, "dd");
    setAcceptence((acceptence) => !acceptence);

    if (event.target.value === "") {
      setAcceptenceError(true);
    } else {
      setAcceptenceError(false);
    }
  };

  const emailChange = (event) => {
    setResumeEmail((resumeEmail) => !resumeEmail);
  };

  const validateForm = () => {
    let result = true;
    if (
      typeOfAccount === null ||
      typeOfAccount === "" ||
      typeOfAccount === undefined
    ) {
      result = false;
    }
    if (typeOfAccount == Roles.employer) {
      if (company == "" || company == undefined || company == null) {
        result = false;
        setCompanyNameError(true);
      }
    } else if (typeOfAccount == Roles.stm) {
      if (company == "" || company == undefined || company == null) {
        result = false;
        setCompanyNameError(true);
      }
      if (group === "" || group == undefined || group == null) {
        result = false;
        setGroupError(true);
      }
      if (
        subcategory === "" ||
        subcategory == undefined ||
        subcategory == null
      ) {
        result = false;
        setSubcategoryError(true);
      }
      if (position === "" || position == undefined || position == null) {
        result = false;
        setPositionError(true);
      }
    } else if (typeOfAccount == Roles.groupLeader) {
      if (organizationName === "" || organizationName == undefined) {
        result = false;
        setOrganizationNameError(true);
      }
    }

    if (firstName === "" || firstName == undefined || firstName == null) {
      result = false;
      setFirstNameError(true);
    }
    if (lastName === "" || lastName == undefined || lastName == null) {
      result = false;
      setLastNameError(true);
    }
    // if (phone === "" || phone == undefined) {
    //   result = false;
    //   setPhoneError(true);
    // }
    if (email === "" || email == undefined) {
      result = false;
      nameRef.current.focus();
      setEmailError(true);
    }
    if (
      country == "" ||
      country == undefined ||
      country == "0" ||
      country == null
    ) {
      // console.log("country");
      result = false;
      setCountryError(true);
    }
    if (
      countryState == "" ||
      countryState == undefined ||
      countryState == "0" ||
      countryState == null
    ) {
      // console.log("countrystt");
      result = false;
      setCountryStateError(true);
    }
    if (city === "" || city == undefined || city == null) {
      // console.log("countrcityy");

      result = false;
      setCityError(true);
    }
    if (password === "" || password == undefined) {
      result = false;
      setPasswordError(true);
    }
    if (reenterPassword === "" || reenterPassword == undefined) {
      result = false;
      setResetPasswordError(true);
    }
    if (jobType === "" || jobType == undefined) {
      setTypeOfAccountError(true);
    }
    return result;
  };

  const validatePassword = () => {
    let result = true;
    var regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (password !== "" && reenterPassword !== "") {
      if (passwordRegex.test(password)) {
        result = true;
        setRegExError(false);
      } else {
        result = false;
        setRegExError(true);
        // setPasswordError(false)
      }
      if (password === reenterPassword) {
        result = true;
        setDoNotMatch(false);
      } else {
        result = false;
        setDoNotMatch(true);
      }
    } else {
      result = false;
    }
    return result;
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (captcha === false) {
      setCaptchaValidError(true);
    }

    let val = validateForm();
    let passwordval = validatePassword();
    if (val === false || passwordval === false) {
      return;
    }
    if (acceptence == false) {
      setAcceptenceError(true);
    } else {
      if (
        firstNameError == false &&
        firstNameValidError == false &&
        lastNameError == false &&
        lastNameValidError == false &&
        typeOfAccountError == false &&
        emailError == false &&
        emailValidError == false &&
        passwordError == false &&
        doNotMatch == false &&
        resetPasswordError == false &&
        acceptenceError == false &&
        countryError == false &&
        countryStateError == false &&
        cityValidError == false &&
        stateValidError == false &&
        cityError == false &&
        regExError == false
      ) {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0");
        var yyyy = today.getFullYear();

        today = mm + "/" + dd + "/" + yyyy;

        let payload;
        let userType;
        if (typeOfAccount == Roles.employer) {
          userType = Roles.employer;
          if (companyNameError == false && companyNameValidError == false) {
            payload = {
              EmployerId: 0,
              FirstName: firstName.trim(),
              LastName: lastName.trim(),
              Roles: [userType],
              Phone: phone,
              Email: email,
              CompanyName: company.trim(),
              City: city.trim(),
              StateId: showStateTextBox ? 0 : countryState,
              StateName: showStateTextBox ? countryState.trim() : null,
              CountryId: country,
              Password: password,
              TermsOfUse: acceptence,
              IsResumeEmail: resumeEmail,
              EmployerIndustries: industry,
            };
          } else {
          }
        } else if (typeOfAccount == Roles.jobSeeker) {
          userType = Roles.jobSeeker;
          payload = {
            JobSeekerId: 0,
            FirstName: firstName.trim(),
            LastName: lastName.trim(),
            Roles: [userType],
            Phone: phone,
            Email: email,
            City: city.trim(),
            StateId: showStateTextBox ? 0 : countryState,
            State: showStateTextBox ? countryState.trim() : null,
            CountryId: country,
            Password: password,
            TermsOfUse: acceptence,
            isJobMatchIndustry: resumeEmail, //for JS emails
            JobSeekerIndustries: industry,
          };
        } else if (typeOfAccount == Roles.stm) {
          userType = Roles.stm;
          if (
            companyNameError == false &&
            companyNameValidError == false &&
            positionError == false &&
            positionValidError == false &&
            groupError == false &&
            subcategoryError == false
          ) {
            payload = {
              SupportTeamMemberId: 0,
              FirstName: firstName.trim(),
              LastName: lastName.trim(),
              Roles: [userType],
              Phone: phone,
              Email: email,
              CompanyName: company.trim(),
              City: city.trim(),
              StateId: showStateTextBox ? 0 : countryState,
              State: showStateTextBox ? countryState.trim() : null,
              CountryId: country,
              Password: password,
              TermsOfUse: acceptence,
              SupportTeamMembersIndustries: industry,
              GroupId: group,
              Position: position.trim(),
              GroupCatgoryId: subcategory,
              IsJobMatchIndustry: resumeEmail,
            };
          } else {
          }
        } else if (typeOfAccount == Roles.groupLeader) {
          userType = Roles.groupLeader;
          if (
            organizationNameError == false &&
            organizationNameValidError == false
          ) {
            payload = {
              FirstName: firstName.trim(),
              LastName: lastName.trim(),
              Phone: phone,
              Roles: [userType],
              Email: email,
              CompanyName: company.trim(),
              OrganizationName: organizationName.trim(),
              City: city.trim(),
              StateId: showStateTextBox ? 0 : countryState,
              State: showStateTextBox ? countryState.trim() : null,
              CountryId: country,
              Password: password,
              TermsOfUse: acceptence,
              GroupLeadersIndustries: industry,
            };
          } else {
          }
        }

        if (payload != undefined && val === true && passwordval === true) {
          setSaving(true);
          dispatch(signUp(payload, userType));
          setTimeout(() => {
            setSaving(false);
          }, 5000);
        }
      } else {
        console.log("Invalid Data, please check input fields");
      }
    }
  };

  const onCaptchaChange = (value) => {
    var options = {
      method: "POST",
      url: API_URL + "common/reCaptcha",
      headers: { "content-type": "application/json" },
      data: { response: value, secret: null },
    };

    axios
      .request(options)
      .then((response) => {
        //console.log(response, response.data.success);
        setCaptcha(response.data.success);
        if (response.data.success === true) {
          setCaptchaValidError(false);
        }
      })
      .catch(function (error) {
        console.error("error", error);
      });
  };

  const handleCountryChange = (e) => {
    setCountryStateList([]);
    const countryValue = e.target.value;
    var index = e.nativeEvent.target.selectedIndex;
    const countryText = e.nativeEvent.target[index].text;
    setCountry(countryValue);
    if (countryText === "Other") {
      setShowStateTextBox(true);
      setCountryState("");
      setStateValidError(false);
    } else setShowStateTextBox(false);
    if (countryValue === "0") {
      setCountryError(true);
    } else {
      setCountryError(false);
      getStateByCountry(countryValue)
        .then((res) => {
          const result = JSON.parse(res.response);
          setCountryStateList(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleGroupChange = (e) => {
    const groupValue = e.target.value;
    setGroup(groupValue);
    setSubcategory("");
    if (groupValue === "0") {
      setGroupError(true);
    } else {
      setGroupError(false);
      getGroupCategoriesByGroup(groupValue)
        .then((res) => {
          const result = JSON.parse(res.response);
          setSubcategoryList(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    document.body.classList.add("home-page");
    return function cleanup() {
      document.body.classList.remove("home-page");
    };
  }, []);

  let checkval = [];
  for (let i = 0; i < industry.length; i++) {
    checkval.push(industry[i].IndustryId);
    // console.log(checkval, 'loop');
  }
  // console.log(industryList.filter(obj => { console.log(obj.value, checkval.includes(obj.value)); val = industry.includes(obj.value); }), '840');

  return (
    <React.Fragment>
      <StartupHeader />

      {!terms && (
        <div className="main-content">
          <div className="main-banner">
            <div className="dashboard-wrapper resumes-page">
              <div className="container">
                <div className="dashboard-box dashboard-col-bg public-wrapper outline-form">
                  {/* <div className="text-right">
                  <span className="icon icon-close ml-auto black cursor-pointer"></span>
                </div> */}
                  <Form noValidate>
                    <Row>
                      <Col md={11} className="mx-auto">
                        <h1>Register</h1>
                        <h5 className="sub-form-title mb-4 mt-4 d-flex border-none">
                          Type of account
                        </h5>
                        <div className="mb-3 d-flex radio-buttons-new flex-wrap justify-content-between">
                          <div className="radio-new">
                            <input
                              id="radio-1"
                              type="radio"
                              checked={jobType === Roles.employer}
                              value={Roles.employer}
                              onChange={buttonChange}
                            />
                            <label htmlFor="radio-1" className="radio-label">
                              {Roles.employer}
                            </label>
                          </div>
                          <div className="radio-new">
                            <input
                              id="radio-2"
                              type="radio"
                              checked={jobType === Roles.jobSeeker}
                              value={Roles.jobSeeker}
                              onChange={buttonChange}
                            />
                            <label htmlFor="radio-2" className="radio-label">
                              {Roles.jobSeeker}
                            </label>
                          </div>
                          <div className="radio-new">
                            <input
                              id="radio-3"
                              type="radio"
                              checked={jobType === Roles.stm}
                              value={Roles.stm}
                              onChange={buttonChange}
                            />
                            <label htmlFor="radio-3" className="radio-label">
                              {Roles.stm}
                            </label>
                          </div>
                          <div className="radio-new">
                            <input
                              id="radio-4"
                              type="radio"
                              checked={jobType === Roles.groupLeader}
                              value={Roles.groupLeader}
                              onChange={buttonChange}
                            />
                            <label htmlFor="radio-4" className="radio-label">
                              {Roles.groupLeader}
                            </label>
                          </div>
                        </div>
                        {typeOfAccountError && (
                          <p style={{ color: "red" }}>
                            Type of Account is required
                          </p>
                        )}

                        <h5 className="sub-form-title mb-4  mt-4 d-flex">
                          Contact details
                        </h5>

                        <Row>
                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="text"
                                name="firstname"
                                maxLength={30}
                                placeholder="First Name *"
                                value={firstName}
                                onBlur={handleOnBlurChange}
                                onChange={handleChange}
                                className="inputclass form-control"
                              />
                              {firstNameError && (
                                <p style={{ color: "red" }}>
                                  First name is required
                                </p>
                              )}
                              {firstNameValidError && !firstNameError && (
                                <p style={{ color: "red" }}>
                                  Enter valid first name
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="text"
                                name="lastname"
                                placeholder="Last Name *"
                                maxLength={30}
                                value={lastName}
                                onBlur={handleOnBlurChange}
                                onChange={handleChange}
                                className="inputclass form-control"
                              />
                              {lastNameError && (
                                <p style={{ color: "red" }}>
                                  Last name is required
                                </p>
                              )}
                              {lastNameValidError && !lastNameError && (
                                <p style={{ color: "red" }}>
                                  Enter valid last name
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="text"
                                name="phone"
                                placeholder="Phone"
                                minLength="10"
                                maxLength="11"
                                value={phone}
                                onChange={handleChange}
                                onBlur={handleOnBlurChange}
                                className="inputclass form-control"
                              />
                              {phoneValidError && (
                                <p style={{ color: "red" }}>
                                  Enter valid phone number
                                </p>
                              )}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="form-group">
                              <input
                                ref={nameRef}
                                type="text"
                                name="email"
                                maxLength={100}
                                placeholder="Email *"
                                value={email.trim()}
                                onChange={handleChange}
                                onBlur={handleOnBlurChange}
                                className="inputclass form-control"
                              />
                              {emailError && (
                                <p style={{ color: "red" }}>
                                  Email is required
                                </p>
                              )}
                              {emailValidError && !emailError && (
                                <p style={{ color: "red" }}>
                                  Enter valid email
                                </p>
                              )}
                            </div>
                          </Col>

                          {typeOfAccount == Roles.groupLeader && (
                            <>
                              <Col md={6}>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="organizationname"
                                    placeholder="Group Name *"
                                    maxLength={100}
                                    value={organizationName}
                                    onBlur={handleOnBlurChange}
                                    onChange={handleChange}
                                    className="inputclass form-control"
                                  />
                                  {organizationNameError && (
                                    <p style={{ color: "red" }}>
                                      Group name is required
                                    </p>
                                  )}
                                  {organizationNameValidError &&
                                    !organizationNameError && (
                                      <p style={{ color: "red" }}>
                                        Enter valid group name
                                      </p>
                                    )}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="suborganizationname"
                                    placeholder="Sub Group Name (Optional)"
                                    maxLength={100}
                                    value={subOrganizationName}
                                    onBlur={handleOnBlurChange}
                                    onChange={handleChange}
                                    className="inputclass form-control"
                                  />
                                </div>
                              </Col>
                            </>
                          )}

                          {(typeOfAccount == Roles.employer ||
                            typeOfAccount == Roles.stm) && (
                            <Col md={6}>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="company"
                                  placeholder="Company Name *"
                                  maxLength={30}
                                  value={company}
                                  onBlur={handleOnBlurChange}
                                  onChange={handleChange}
                                  className="inputclass form-control"
                                />
                                {companyNameError && (
                                  <p style={{ color: "red" }}>
                                    Company name is required
                                  </p>
                                )}
                                {companyNameValidError && !companyNameError && (
                                  <p style={{ color: "red" }}>
                                    Enter valid company name
                                  </p>
                                )}
                              </div>
                            </Col>
                          )}

                          {typeOfAccount == Roles.stm && (
                            <Col md={6}>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="position"
                                  placeholder="Position *"
                                  maxLength={50}
                                  value={position}
                                  onBlur={handleOnBlurChange}
                                  onChange={handleChange}
                                  className="inputclass form-control"
                                />
                                {positionError && (
                                  <p style={{ color: "red" }}>
                                    Position is required
                                  </p>
                                )}
                                {positionValidError && !positionError && (
                                  <p style={{ color: "red" }}>
                                    Enter valid position
                                  </p>
                                )}
                              </div>
                            </Col>
                          )}
                        </Row>

                        <Row>
                          <Col className="dd">
                            <Form.Group controlId="dd">
                              <Form.Control
                                as="select"
                                name="country"
                                value={country}
                                onChange={(handleChange, handleCountryChange)}
                                className="inputclass form-control"
                              >
                                <option value="0">Select Country *</option>
                                {countryList.map((item) => (
                                  <option value={item.countryId}>
                                    {item.countryName}
                                  </option>
                                ))}
                              </Form.Control>
                              {countryError && (
                                <p style={{ color: "red" }}>
                                  Country is required
                                </p>
                              )}
                            </Form.Group>
                          </Col>
                          {!showStateTextBox && (
                            <Col className="dd">
                              <Form.Group controlId="dd">
                                <Form.Control
                                  as="select"
                                  name="state"
                                  value={countryState}
                                  onChange={handleChange}
                                  className="inputclass form-control"
                                >
                                  <option value="0">
                                    {country == 2
                                      ? "Select Province *"
                                      : "Select State *"}
                                  </option>
                                  {countryStateList.map((item) => (
                                    <option value={item.stateId}>
                                      {item.stateName}
                                    </option>
                                  ))}
                                </Form.Control>
                                {countryStateError && (
                                  <p style={{ color: "red" }}>
                                    {country == 2
                                      ? "Province is required"
                                      : "State is required"}
                                  </p>
                                )}
                              </Form.Group>
                            </Col>
                          )}

                          {showStateTextBox && (
                            <Col md={6}>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="state"
                                  placeholder="Country *"
                                  maxLength={30}
                                  value={countryState}
                                  onBlur={handleOnBlurChange}
                                  onChange={handleChange}
                                  className="inputclass form-control"
                                />
                                {countryStateError && (
                                  <p style={{ color: "red" }}>
                                    Country is required
                                  </p>
                                )}
                                {/*{stateValidError && !countryStateError && (*/}
                                {/*  <p style={{ color: "red" }}>*/}
                                {/*    Enter valid State*/}
                                {/*  </p>*/}
                                {/*)}*/}
                              </div>
                            </Col>
                          )}

                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="text"
                                name="city"
                                placeholder="City *"
                                maxLength={30}
                                value={city}
                                onBlur={handleOnBlurChange}
                                onChange={handleChange}
                                className="inputclass form-control"
                              />
                              {cityError && (
                                <p style={{ color: "red" }}>City is required</p>
                              )}
                              {cityValidError && !cityError && (
                                <p style={{ color: "red" }}>Enter valid City</p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {typeOfAccount == Roles.stm && (
                            <Col className="dd">
                              <Form.Group controlId="dd">
                                <Form.Control
                                  as="select"
                                  name="group"
                                  value={group}
                                  onChange={(handleChange, handleGroupChange)}
                                  className="inputclass form-control"
                                >
                                  <option value="0">Select Group *</option>
                                  {groupList.map((item) => (
                                    <option value={item.groupId}>
                                      {item.groupName}
                                    </option>
                                  ))}
                                </Form.Control>
                                {groupError && (
                                  <p style={{ color: "red" }}>
                                    Group is required
                                  </p>
                                )}
                              </Form.Group>
                            </Col>
                          )}

                          {typeOfAccount == Roles.stm && (
                            <Col className="dd">
                              <Form.Group controlId="dd">
                                <Form.Control
                                  as="select"
                                  name="subcategory"
                                  value={subcategory}
                                  onChange={handleChange}
                                  className="inputclass form-control"
                                >
                                  <option value="0">
                                    Select Subcategory *
                                  </option>
                                  {subcategoryList.map((item) => (
                                    <option value={item.groupCategoryId}>
                                      {item.groupCategoryName}
                                    </option>
                                  ))}
                                </Form.Control>
                                {subcategoryError && (
                                  <p style={{ color: "red" }}>
                                    Subcategory is required
                                  </p>
                                )}
                              </Form.Group>
                            </Col>
                          )}
                        </Row>

                        <h5 className="sub-form-title mb-4  mt-4 d-flex">
                          Password
                        </h5>

                        <Row>
                          <Col md={6}>
                            <div className="form-group">
                              <input
                                type="password"
                                name="password"
                                placeholder="Password *"
                                value={password}
                                onBlur={handleOnBlurChange}
                                onChange={handleChange}
                                className="inputclass form-control"
                                maxLength={50}
                              />
                              {passwordError && (
                                <p style={{ color: "red" }}>
                                  Password is required
                                </p>
                              )}
                              {regExError && !passwordError && (
                                <p style={{ color: "red" }}>
                                  Enter a valid Password
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <input
                                type="password"
                                name="confirmpassword"
                                placeholder="Re-enter password *"
                                value={reenterPassword}
                                onBlur={handleOnBlurChange}
                                onChange={handleChange}
                                className="inputclass form-control"
                                maxLength={50}
                              />
                              {resetPasswordError && (
                                <p style={{ color: "red" }}>
                                  Re-enter password is required
                                </p>
                              )}
                              {doNotMatch && (
                                <p style={{ color: "red" }}>
                                  Passwords do not match
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col md={6}>
                            {regExError && (
                              <p className="password-note">
                                Be a minimum of 8 characters
                                <br />
                                Include at least one uppercase letter (A-Z)
                                <br />
                                Include at least one lowercase letter (a-z)
                                <br />
                                One number (0-9) and symbol (@, #, $, %, etc.)
                              </p>
                            )}
                          </Col>
                        </Row>
                        <h5 className="sub-form-title mb-4  mt-4 d-flex">
                          Industry
                        </h5>
                        <Row>
                          <Col md={6}>
                            <div className="form-group">
                              <Select
                                placeholder="Select Industry"
                                options={industryList}
                                value={industryList.filter((obj) =>
                                  checkval.includes(obj.value)
                                )}
                                onChange={handleIndustryChange}
                                isMulti
                                className="selectd"
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="custom-control custom-checkbox checkb">
                              {(typeOfAccount == Roles.employer ||
                                Roles.jobSeeker ||
                                Roles.stm) && (
                                <input
                                  className="custom-control-input"
                                  type="checkbox"
                                  checked={resumeEmail}
                                  name="checkbox2"
                                  onChange={emailChange}
                                />
                              )}

                              {typeOfAccount == Roles.employer && (
                                <label className="custom-control-label my-4">
                                  {employer_message}
                                </label>
                              )}

                              {typeOfAccount == Roles.jobSeeker && (
                                <label className="custom-control-label my-4">
                                  {jobseeker_message}
                                </label>
                              )}
                              {typeOfAccount == Roles.stm && (
                                <label className="custom-control-label my-4">
                                  {jobseeker_message}
                                </label>
                              )}
                            </div>

                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                checked={acceptence}
                                name="checkbox1"
                                onChange={acceptenceChange}
                              />
                              <label className="custom-control-label">
                                I accept the JobInfoNetwork's &nbsp;
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setTerms(!terms)}
                                >
                                  Terms of use
                                </a>
                              </label>
                            </div>
                            {acceptenceError && (
                              <p style={{ color: "red" }}>
                                Please accept the conditions
                              </p>
                            )}
                          </Col>
                        </Row>

                        <Row className="sub-form-title mb-4  mt-4 d-flex">
                          <Col className="password-note">
                            <ReCAPTCHA
                              sitekey={sitekey}
                              onChange={onCaptchaChange}
                            />
                            {captchaError && (
                              <p style={{ color: "red", fontWeight: 400 }}>
                                Please check the Captcha
                              </p>
                            )}
                          </Col>
                        </Row>

                        <Row className="register-user-btn">
                          <Col md={8} className="square-radius mt-5">
                            <Buttons
                              variant="warning"
                              title="Register"
                              type="button"
                              size="xs"
                              color="white"
                              disabled={saving}
                              onClick={onSubmit}
                            ></Buttons>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {terms && (
        <div className="main-content">
          <div className="main-banner">
            <div className="dashboard-wrapper resumes-page">
              <div className="container">
                <div className="dashboard-box dashboard-col-bg public-wrapper outline-form terms-page">
                  {/* <div className="text-right">
                    <span className="icon icon-close ml-auto black cursor-pointer"></span>
                  </div> */}
                  <Form noValidate>
                    <Row>
                      <Col md={10} className="mx-auto">
                        <h1 className="mb-5">Terms of Use</h1>
                        <iframe
                          src="Terms.html"
                          frameborder="0"
                          className="h-100v"
                          height="100%"
                          width="100%"
                        ></iframe>

                        <Row>
                          <Col md={8} className="square-radius mt-5">
                            <Buttons
                              variant="outline-primary"
                              title="Back"
                              type="submit"
                              size="xs"
                              icon="icon icon-arrow-left mr-4"
                              onClick={() => {
                                setTerms(!terms);
                              }}
                            ></Buttons>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
export default connect(null, { signUp })(Register);
