/* eslint-disable eqeqeq */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import SpinnerLoader from "../Components/SpinnerLoader";
import moment from "moment";
import {
  getResponsesMessage,
  sendResponse,
} from "../Services/SupportConnectionsService";
import { toast } from "react-toastify";
import { DownloadByteArray } from "../Services/ResumeService";
import { base64ToArrayBuffer, saveByteArray } from "../Utilities/DownloadFile";
import {
  helpful,
  helpfulText,
  publicResumeRootUrl,
  fileResumeHasProfessionalTitle,
} from "../Utilities/Helper";

export default function SupportConnectionList({
  connections = [],
  showSpinner,
  responses = [],
  refreshTable,
}) {
  const [text, setText] = React.useState("");

  const [responseMessage, setResponseMessage] = useState({
    message: "",
    shortMessage: "",
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [responseError, setResponseError] = useState(null);
  const [response, setResponse] = useState(false);
  const handleResponseClose = () => setResponse(false);
  const [bindJobSeekerData, setBindJobSeekerData] = useState({
    jobSeekerConnectionId: 0,
    jobSeekerId: 0,
  });
  const [showResume, setShowResume] = useState(undefined);
  const [status, setStatus] = React.useState(0); // 0: no show, 1: show yes, 2: show no.

  const radioHandler = (status) => {
    setText("");
    setStatus(status);
    setResponseError(false);
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const openResponseModal = (jobSeekerConnectionId, jobSeekerId) => {
    setResponseError(null);
    setShow(true);
    setText("");
    setStatus(0);
    setBindJobSeekerData({
      jobSeekerConnectionId: jobSeekerConnectionId,
      jobSeekerId: jobSeekerId,
    });
  };

  const sendClickHandler = () => {
    if (status === 0 || responseError === null) {
      setResponseError(true);
      return;
    }
    var data = {
      JobSeekerConnectionResponseId: status,
      JobSeekerConnectionId: bindJobSeekerData.jobSeekerConnectionId,
      Message: text,
      UserId: bindJobSeekerData.jobSeekerId,
    };
    sendResponse(data)
      .then((res) => {
        toast.success(res.response);
        setShow(false);
        setResponseError(null);
        refreshTable();
      })
      .catch((err) => {
        toast.error(JSON.stringify(err));
      });
  };

  const onResposeClickHandler = (jobSeekerConnectionId) => {
    getResponsesMessage(jobSeekerConnectionId)
      .then((res) => {
        const result = JSON.parse(res.response);
        setResponseMessage({
          message: result.message,
          shortMessage: result.shortMessage,
        });
        setResponse(true);
      })
      .catch((err) => {
        toast.error(JSON.stringify(err));
      });
  };

  const downloadFile = (filename, resumeName) => {
    DownloadByteArray(filename)
      .then((res) => {
        const result = JSON.parse(res.response);
        var sampleArr = base64ToArrayBuffer(result.fileContentInBytes);
        saveByteArray(resumeName, result.fileName, sampleArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShowResume = (resume) => {
    setShowResume(resume);
  };
  const handleHideResume = () => {
    setShowResume(undefined);
  };

  const renderResumeModal = () => {
    const filepath = showResume.resumePath;

    const resumeUrl = publicResumeRootUrl + encodeURIComponent(filepath);

    // Note: office viewer only seems to support ms office formats
    // Most browsers support pdf natively, so we can just use the default pdf viewer
    const isResumePdfFormated = (fileName) =>
      fileName.toLowerCase().endsWith(".pdf");
    const fileViewerIFrameUrl = isResumePdfFormated(filepath)
      ? resumeUrl
      : `https://view.officeapps.live.com/op/embed.aspx?src=${resumeUrl}`;

    return (
      <Modal
        show={showResume !== undefined}
        size="lg"
        centered
        dialogClassName="modal-resume"
        onHide={handleHideResume}
      >
        <Modal.Header closeButton>
          <Modal.Title>Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "1px" }}>
          <iframe
            title="resume"
            src={fileViewerIFrameUrl}
            width={"100%"}
            height={"600px"}
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleHideResume()}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => downloadFile(filepath, filepath)}
          >
            Download Resume
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <ul className="sponsership-list network-list mobile-list">
        <li className="headings">
          <div className="name-title">Name</div>
          <div className="resume-clm">Resume</div>
          <div className="receipt-date pl-0">Received</div>
          <div className="action ml-auto text-center">Action</div>
          <div className="response-date text-right">Respond date</div>
          <div className="email-id text-right">Response</div>
        </li>
        {showSpinner && <SpinnerLoader />}
        {connections.length === 0 && !showSpinner && (
          <h5 style={{ textAlign: "center" }}>No Data Found</h5>
        )}
        {connections.map((item, index) => (
          <li key={index} className="align-items-center">
            <div className="name-title mobile-coulmn">
              <span className="mobile-column-name">Name</span>
              <span className="mobile-column-content">{item.fullName}</span>
            </div>

            <div className="resume-clm mobile-coulmn">
              <span className="mobile-column-name">Resume</span>
              <span className="mobile-column-content">
                {item.jinTemplateId !== null ? (
                  /*
                   * JIN Template Resume
                   */
                  <Link
                    to={`${process.env.PUBLIC_URL}/support/resumedetails/${item.jinTemplateId}`}
                    className="btn btn-primary btn-request text-white"
                  >
                    <span className="icon-manage_search mr-2" />
                    Resume{item.jobTitle ? `: ${item.jobTitle}` : ""}
                  </Link>
                ) : (
                  item.resumePath != null && (
                    /*
                     * DOCX or PDF Resume
                     */
                    <Button
                      variant="primary"
                      type="button"
                      className="btn-request text-white"
                      onClick={() => handleShowResume(item)}
                    >
                      <span className="icon-manage_search mr-2" />
                      Resume{" "}
                      {fileResumeHasProfessionalTitle(item.resumeName)
                        ? `: ${item.resumeName}`
                        : ""}
                    </Button>
                  )
                )}
              </span>
            </div>
            <div className="receipt-date pl-0 mobile-coulmn">
              <span className="mobile-column-name">Received</span>
              <span className="mobile-column-content">
                {moment(
                  new Date(item.createdDate.toString() + "Z").toString()
                ).format("MMM DD, Y")}
              </span>
            </div>
            <div className="action text-center">
              {item.jobSeekerConnectionResponseId !== null && (
                <Button
                  variant="primary"
                  size="sm"
                  className="btn-request disabled text-white"
                  style={{ minWidth: "105px" }}
                >
                  Responded
                </Button>
              )}
              {item.jobSeekerConnectionResponseId === null && (
                <Button
                  variant="warning"
                  size="sm"
                  className="btn-request text-white"
                  onClick={() =>
                    openResponseModal(
                      item.jobSeekerConnectionId,
                      item.jobSeekerId
                    )
                  }
                >
                  Respond
                </Button>
              )}
            </div>
            <div className="response-date text-right mobile-coulmn">
              <span className="mobile-column-name">Respond Date</span>
              <span className="mobile-column-content">
                {item.respondDate !== null &&
                  moment(
                    new Date(item.respondDate.toString() + "Z").toString()
                  ).format("MMM DD, Y")}
                {item.respondDate === null && "-"}
              </span>
            </div>
            <div className="email-id text-right mobile-coulmn">
              <span className="mobile-column-name">Response</span>
              <span className="mobile-column-content">
                {item.shortResponse !== null && (
                  <Link
                    onClick={() =>
                      onResposeClickHandler(item.jobSeekerConnectionId)
                    }
                    to={"#"}
                    className="link"
                  >
                    {item.shortResponse}
                  </Link>
                )}
                {item.shortResponse === null && (
                  <Link to={"#"} className="link">
                    -
                  </Link>
                )}
              </span>
            </div>
          </li>
        ))}
      </ul>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        className="respond-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Respond</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 radio-list">
            {responses.map((item, index) => (
              <div key={index} className="radio mr-4">
                <input
                  id={`radio-${index}`}
                  name={`radio${index}`}
                  type="radio"
                  checked={status == item.jobSeekerConnectionResponseId}
                  onClick={() =>
                    radioHandler(item.jobSeekerConnectionResponseId)
                  }
                />
                <label htmlFor={`radio-${index}`} className="radio-label">
                  {item.response}
                </label>

                {status == item.jobSeekerConnectionResponseId && (
                  <textarea
                    value={text}
                    onChange={handleChange}
                    maxLength="500"
                    className="inputclass form-control"
                    style={{ overflowY: "scroll" }}
                    rows={4}
                    autoFocus={true}
                  />
                )}
              </div>
            ))}
            {responseError && (
              <span style={{ color: "red" }}>Please select response</span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={sendClickHandler}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Response */}

      <Modal
        show={response}
        size="lg"
        onHide={handleResponseClose}
        className="respond-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <h6 className="respons-heading">
              {responseMessage.shortMessage === helpful
                ? helpfulText
                : responseMessage.shortMessage}{" "}
            </h6>
            <p className="respons-text">{responseMessage.message}</p>
          </div>
        </Modal.Body>
      </Modal>

      {showResume !== undefined && renderResumeModal()}
    </React.Fragment>
  );
}
