/* eslint-disable eqeqeq */

import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { ListGroup } from "react-bootstrap";

export default function InboxMessagesList({
  messages = [],
  getSelectedMessage,
  pagination,
  type,
  messageIndexChange,
  refreshTable,
  messageIndex,
}) {
  const [id, setId] = useState(messageIndex);

  useEffect(() => {
    setId(0);
  }, [messageIndexChange]);
  const [msg, setMsg] = useState(messages);

  const [postList, setPostList] = useState({ list: [1, 2, 3, 4] });
  // tracking on which page we currently are
  const [page, setPage] = useState(1);
  // add loader refrence
  const loader = useRef(null);

  const pageChangeHandler = (page) => {
    pagination(page);
  };

  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "10px",
      threshold: 1.0,
    };
    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);

  const markAsRead = (id, type, isSeen, index) => {
    refreshTable(id, type, isSeen, index);
  };

  useEffect(() => {
    // here we simulate adding new posts to List
    const newList = postList.list.concat([1, 1, 1, 1]);
    setPostList({
      list: newList,
    });
  }, [page]);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setPage((page) => page + 1);
    }
  };

  return (
    <>
      {messages &&
        messages.length !== 0 &&
        messages.map((message, index) => (
          <ListGroup key={type + "-" + index}>
            <ListGroup.Item
              key={type + index}
              action
              // href="#link1"
              onClick={() => {
                setId(index);
                getSelectedMessage(index);
                markAsRead(
                  message.messageId,
                  message.messageType,
                  message.isSeen,
                  index
                );
              }}
              className={index == messageIndex ? "active" : ""}
              style={
                message.isSeen == false && index !== messageIndex
                  ? {
                      backgroundColor: "transparent",
                      border: "1px solid #c1bcbc",
                    }
                  : {}
              }
            >
              <h4>
                <span className="msg-time float-right">
                  {moment(
                    new Date(message.createdDate.toString() + "Z").toString()
                  ).format("MMM D LT")}
                </span>
              </h4>
              <label>{message.fullName}</label>
              <p>
                {message.message.length < 35
                  ? message.message
                  : message.message.substr(0, 30) + "..."}
              </p>
            </ListGroup.Item>
          </ListGroup>
        ))}
    </>
  );
}
