/* eslint-disable eqeqeq */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Form, Toast, Alert } from "react-bootstrap";
import { FaPaperPlane, FaTimes } from "react-icons/fa";
import Buttons from "../Components/Shared/Button";
import { getJobSeekerResume } from "../Services/AllJobsService";
import { sendNetworkRequest } from "../Services/JobSeekersService";
import { toast } from "react-toastify";
import moment from "moment";
import { fileResumeTitle } from "../Utilities/Helper";

export default function SeekersNetworkList({ networks = [], setRefresh }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showt, setShowt] = useState(false);
  const [resumeId, setResumeId] = useState(0);
  const [StmId, setStmId] = useState("");
  const [jobResumes, setJobResumes] = useState([]);
  const [resumeEmpty, setResumeEmpty] = useState("");
  const [stmPosition, setStmPosition] = useState("");
  const [stmCompany, setStmCompany] = useState("");
  const [hideRequestLI, setHideRequestLI] = useState("");
  const [selectRequestLI, setSelectHideRequestLI] = useState("");
  const [jobSeekerPublicResumeId, setJobSeekerPublicResumeId] = useState(0);
  // const [refresh, setRefresh] = useState(false);

  //const handleClose1 = () => setShowt(false);

  setTimeout(() => {
    setShowt(false);
  }, 7000);

  const onResumeChange = (e) => {
    // console.log(e.target.value);
    if (e.target.value == 0) {
      setResumeEmpty(true);
    } else {
      setResumeEmpty(false);
    }
    // setResumeId(e.target.value);

    var resume = e.target.value;
    var res = resume.split("-");
    var obj = {};
    obj[
      res[0] === "JIN" ? "JobSeekerPublicResumeId" : "JobSeekerPrivateResumeId"
    ] = res[1];

    if (res[0] === "JIN") {
      setResumeId("");
      setJobSeekerPublicResumeId(res[1]);
    } else {
      setResumeId(res[1]);
      setJobSeekerPublicResumeId("");
    }
  };

  const attachResume = () => {
    // let resumes = [];
    getJobSeekerResume()
      .then((res) => {
        // console.log(res);
        var result = JSON.parse(res.response);
        if (result) {
          result.sort((a, b) => {
            if (a.templateId === null) {
              return 1;
            }
            if (b.templateId === null) {
              return -1;
            }
            return 0;
          });

          setJobResumes(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setShow(true);
  };

  const selectSTM = (e, selectedLI) => {
    setStmId(e.supportTeamMemberId);
    setStmPosition(e.supportTeamMemberTitle);
    setStmCompany(e.supportTeamMemberCompanyName);
    setSelectHideRequestLI(selectedLI);
  };

  const reload = () => {
    window.location.reload();
  };

  const sendResume = () => {
    if (resumeId == 0 && jobSeekerPublicResumeId == 0) {
      setResumeEmpty(true);
    } else {
      setResumeEmpty(false);
      sendNetworkRequest(
        StmId,
        resumeId,
        jobSeekerPublicResumeId,
        moment(new Date()).format("YYYY/MM/DD")
      )
        .then((res) => {
          let response = res.response;
          toast.success(response.message);
          setRefresh(true);
          if (true === response.isSuccess) {
            setHideRequestLI(selectRequestLI);
          }
        })
        .catch((err) => {
          console.log(err);
          setHideRequestLI("");
        });
      setShow(false);
    }
    // if (!resumeEmpty && resumeId != 0) {
    //   sendNetworkRequest(StmId, resumeId, jobSeekerPublicResumeId)
    //     .then((res) => {
    //       console.log(res);
    //       let response = JSON.parse(res.response);
    //       // console.log(response);
    //       toast.success(response.message);
    //       setTimeout(reload, 6000);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    //   setShow(false);
    // }
    // else {
    //   setShow(true);
    //   return;
    // }
  };

  return (
    <React.Fragment>
      <Alert
        variant="success"
        show={showt}
        onClose={() => setShowt(false)}
        dismissible
      >
        <p className="text-center mb-0 py-3">
          2/10 network request sent this week
        </p>
      </Alert>

      {/* <Toast onClose={() => setShowt(false)} show={showt}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">Bootstrap</strong>
            <small>11 mins ago</small>
          </Toast.Header>
          <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
        </Toast> */}

      <ul className="sponsership-list network-list mobile-list">
        <li className="headings">
          <div className="name-title group-td pl-0">Group</div>
          <div className="name-title industry-category pl-0">Industry</div>
          <div className="company-name">Company</div>
          <div className="email-id title pl-0">Position</div>
          <div className="email-id title pl-0">State</div>
          <div className="resume-clm city pl-0">City</div>
          <div
            className="title res-td text-center"
            style={{ wordBreak: "inherit" }}
          >
            Request / Response
          </div>
          <div className="action ml-auto text-right">Action</div>
        </li>
        {networks &&
          networks.map((network, index) => (
            <li
              key={index}
              className="align-items-center"
              style={{
                display: `${
                  hideRequestLI == "LI-" + network.supportTeamMemberId
                    ? "none"
                    : "flex"
                }`,
              }}
            >
              <div className="email-id group-td pl-0 mobile-coulmn">
                <span className="mobile-column-name">Group</span>
                <span className="mobile-column-content">
                  {" "}
                  {network.group ? network.group : "--"}
                </span>
              </div>
              <div className="name-title industry-category pl-0 mobile-coulmn">
                <span className="mobile-column-name">Industry</span>
                <span
                  className="mobile-column-content"
                  title={
                    network.supportTeamMemberIndustryList &&
                    network.supportTeamMemberIndustryList.map(
                      (item, index) => item.industryName
                    )
                  }
                >
                  {/* {network.supportTeamMemberIndustryList.length
                  ? network.supportTeamMemberIndustryList.map((item, index) => (
                      <span>
                        {item.industryName}
                        {network.supportTeamMemberIndustryList &&
                        index < network.supportTeamMemberIndustryList.length - 1
                          ? ", "
                          : " "}
                      </span>
                    ))
                  : "--"} */}
                  {network.supportTeamMemberIndustryList.map((item, index) => (
                    <span key={index}>
                      {index > 2 && index < 4 && ",..."}
                      {index == 0 && item.industryName}
                      {index <= 2 && index > 0 && ", " + item.industryName}
                    </span>
                  ))}
                  {network.supportTeamMemberIndustryList &&
                    network.supportTeamMemberIndustryList.length == 0 &&
                    "--"}
                </span>
              </div>
              <div className="company-name mobile-coulmn">
                <span className="mobile-column-name">Company</span>
                <span className="mobile-column-content">
                  {" "}
                  {network.supportTeamMemberCompanyName}
                </span>
              </div>
              <div className="email-id title pl-0 mobile-coulmn">
                <span className="mobile-column-name">Position</span>
                <span className="mobile-column-content">
                  {" "}
                  {network.supportTeamMemberTitle}
                </span>
              </div>
              <div className="resume-clm state pl-0 mobile-coulmn">
                <span className="mobile-column-name">State</span>
                <span className="mobile-column-content">
                  {network.supportTeamMemberState
                    ? network.supportTeamMemberState
                    : "--"}
                </span>
              </div>
              <div className="resume-clm city pl-0 mobile-coulmn">
                <span className="mobile-column-name">City</span>
                <span className="mobile-column-content">
                  {network.supportTeamMemberCity
                    ? network.supportTeamMemberCity
                    : "--"}
                </span>
              </div>
              <div className="title res-td text-center mobile-coulmn">
                <span className="mobile-column-name">Request/Response</span>
                <span className="mobile-column-content">
                  {network.supportTeamMemberRequestNumber}/
                  {network.supportTeamMemberResponseNumber}
                </span>
              </div>
              <div className="action ml-auto text-right">
                <Button
                  variant="warning"
                  size="sm"
                  className="btn-request pl-1 pr-1"
                  onClick={() => {
                    setResumeId(0);
                    setResumeEmpty(false);
                    handleShow();
                    // selectSTM(network.supportTeamMemberId);
                    selectSTM(network, "LI-" + network.supportTeamMemberId);
                    attachResume();
                  }}
                >
                  <FaPaperPlane className="mr-2" /> Request
                </Button>
              </div>
            </li>
          ))}
      </ul>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="custome-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Attach Resume</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={handleClose}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            <form>
              <Form.Group
                controlId="exampleForm.ControlSelect1"
                className="w-100"
              >
                <Form.Control
                  as="select"
                  className="inputclass"
                  onChange={onResumeChange}
                >
                  <option value="0">Select Private Resume</option>
                  {jobResumes.map((item, index) => (
                    <option
                      key={index}
                      value={
                        item.templateId !== null
                          ? `JIN-${item.templateId}`
                          : `Private-${item.jobSeekerPrivateResumeId}`
                      }
                    >
                      {item.templateId !== null
                        ? `${item.templateName}`
                        : fileResumeTitle(item.resumePath, item.resumeName)}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {resumeEmpty && (
                <p style={{ color: "red" }}>Resume is required</p>
              )}
            </form>
          </div>
          <div className="message-footer">
            <Buttons
              variant="warning"
              title="Send Resume"
              type="submit"
              size="xs"
              color="white"
              onChange={onResumeChange}
              onClick={() => {
                sendResume();
                // setShowt(true);
                // setShow(false);
              }}
            ></Buttons>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

// export default SeekersNetworkList;
