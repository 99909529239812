/* eslint-disable eqeqeq */
import React, { useState, Component } from "react";
import {
  Col,
  Row,
  Form,
  Alert,
  Tooltip,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
  validateCharacterAndDigit,
  validateCharacterAndSpace,
  validateCharacterSpaceAndDigit,
  validateCity,
  validateCompany,
  validatePayrate,
  validatePosition,
  validateURL,
  Validators,
} from "../../../Utilities/Validator";
import Dropdown from "../../../Components/Shared/Dropdown";
import { postJob } from "../../../Redux/Jobs/Action";
import { connect } from "react-redux";
import {
  getCountry,
  getDraftJobsCount,
  getGroups,
  getIndustry,
  getJobTypes,
  getStateByCountry,
  getUserDetails,
} from "../../../Services/GetFields";
import Select from "react-select";
import { toast } from "react-toastify";
import axios from "axios";
import help_icon from "../../../assests/img/help_icon.png";
import { post } from "../../../Services/AxiosService";
import { getProfanitiesWords } from "../../../Services/ResumeService";
import {
  API_URL,
  ProfanitiesWordKeysToReplace,
} from "../../../Utilities/Helper";
import { getData } from "../../../Utilities/StorageHelper";

class PostJob extends Component {
  state = {
    jobTitle: "",
    location: "",
    company: "",
    group: "",
    industry: "",
    joblength: "",
    travelRequired: "",
    salary: "",
    description: "",
    jobStartDate: "",
    jobEndDate: "",
    country: "",
    countryState: "",
    countryList: [],
    countryStateList: [],
    industryList: [],
    groupList: [],
    city: "",
    state: "",
    address1: "",
    address2: "",
    zip: "",
    contactName: "",
    contactEmail: "",
    telephone: "",
    category: "",
    jobength: "",
    jobLength: "",
    responsibilities: "",
    companyError: false,
    groupError: false,
    cityError: false,
    stateError: false,
    contactNameError: false,
    emailError: false,
    industryError: false,
    positionError: false,
    joblengthError: false,
    travelError: false,
    descriptionError: false,
    jobTypeId: null,
    postLink: "",
    telecommute: "",
    jobType: "",
    showStateTextBox: false,
    stateName: "",

    jobTypeIdError: false,
    startDateError: false,
    endDateError: false,
    addressError: false,
    countryStateError: false,
    postType: "",
    phoneValidError: false,
    companyNameValidError: false,
    stateValidError: false,
    cityValidError: false,
    zipValidError: false,
    nameValidError: false,
    emailValidError: false,
    positionValidError: false,
    resultState: false,
    linkError: false,
    linkValidError: false,
    disablePost: false,
    disableDraft: false,
    draftsCount: "",
    industryNameForActivity: "",
    ProfanitiesWords: null,

    validCompanyContentError: false,
    validAddress1ContentError: false,
    validAddress2ContentError: false,
    validCityContentError: false,
    validStateContentError: false,
    validZipContentError: false,
    validContactContentError: false,
    validPositionContentError: false,
    validCompensationContentError: false,
    validDescriptionContentError: false,
    validPositionResContentError: false,
    validMinimumQualContentError: false,
    validPreferredQualContentError: false,
    validEmpPolicyContentError: false,
    validPostLinkContentError: false,
  };

  getProfanitiesWordsForValidate() {
    getProfanitiesWords()
      .then((res) => {
        this.setState({ ProfanitiesWords: res.data.response });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // DatePicker settings

  componentDidMount = () => {
    this.getProfanitiesWordsForValidate();
    getUserDetails()
      .then((res) => {
        // console.log(res.response);
        let response = res.response;
        let industryArray = res.response.industries;

        let industry = [];
        for (let i = 0; i < industryArray.length; i++) {
          industry.push({ IndustryId: industryArray[i].industryId });
        }

        const countryText = response.countryId;
        getStateByCountry(countryText)
          .then((res) => {
            const result = JSON.parse(res.response);
            this.setState({ countryStateList: result });
          })
          .catch((err) => {
            console.log(err);
          });

        this.setState({
          contactName: response.firstName + " " + response.lastName,
          telephone: response.phoneNumber,
          contactEmail: response.email,
          country: response.countryId,
          countryState:
            response.stateId !== 0 ? response.stateId : response.stateName,
          stateName: response.stateName,
          city: response.city,
          // group: response.groupId,
          // groupName: response.groupName,
          // subcategory: response.groupCategoryId,
          // groupCategoryName: response.groupCategoryName,
          // organizationName: response.groupName,
          // position: response.position ? response.position : "",
          company: response.companyName,
          // industry: industry,
          // groupId: response.groupId
          resultState: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    getCountry()
      .then((res) => {
        const result = JSON.parse(res.response);
        this.setState({ countryList: result });
      })
      .catch((err) => {
        console.log(err);
      });

    getIndustry()
      .then((res) => {
        const result = JSON.parse(res.response);
        let industryArray = [];
        for (let i = 0; i < result.length; i++) {
          industryArray.push({
            label: result[i].industryName,
            value: result[i].industryId,
          });
        }
        this.setState({ industryList: industryArray });
      })
      .catch((err) => {
        console.log(err);
      });

    getGroups()
      .then((res) => {
        const result = JSON.parse(res.response);
        result.push({ groupId: "all", groupName: "All Groups" });
        this.setState({ groupList: result });
      })
      .catch((err) => {
        console.log(err);
      });

    getDraftJobsCount()
      .then((res) => {
        const result = JSON.parse(res.response);
        this.setState({ draftsCount: result });
        // console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });

    getJobTypes()
      .then((res) => {
        const result = JSON.parse(res.response);
        // console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
    this.minDate = new moment(props.minDate);
    this.maxDate = new moment(props.maxDate);
    this.state = {
      startDate: props.minDate,
      endDate: props.maxDate,
      selectedValue: "Nothing selected",
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  focusTextInput() {
    this.textInput.current.setFocus();
  }

  buttonChange = (event) => {
    // console.log(event.target.name, event.target.value, "ggg");
    this.setState({ jobTypeId: event.target.value, jobTypeIdError: false });
  };

  handleCountryChange = (e) => {
    this.setState({ countryStateList: [] });
    let countryValue = 0;
    countryValue = e.target.value;
    var index = e.nativeEvent.target.selectedIndex;
    const countryText = e.nativeEvent.target[index].text;
    this.setState({ country: countryValue, countryState: "" });
    if (countryText === "Other")
      this.setState({ showStateTextBox: true, stateName: "" });
    else this.setState({ showStateTextBox: false });
    if (countryValue === "0") {
      this.setState({ countryError: true, country: e.target.value });
    } else {
      this.setState({ countryError: false, country: e.target.value });
      getStateByCountry(countryValue)
        .then((res) => {
          const result = JSON.parse(res.response);
          this.setState({ countryStateList: result });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleGroupChange = (e) => {
    // console.log(e.target.value);
    const groupValue = [];
    let ids = [];
    if (e.target.value === "0") {
      this.setState({ groupError: true, group: undefined });
    }
    if (e.target.value === "all") {
      for (let i = 0; i < this.state.groupList.length - 1; i++) {
        ids.push(this.state.groupList[i].groupId);
      }
      this.setState({ group: ids, groupError: false });
      // console.log(ids);
    } else {
      groupValue.push(parseInt(e.target.value));
      this.setState({ group: groupValue, groupError: false });
    }
    // this.setState({ group: groupValue });
  };

  handleIndustryChange = (event) => {
    // console.log(event.target.value);
    let name = "";
    this.state.industryList.map((item) => {
      if (event.target.value == item.value) {
        name = item.label;
      }
    });
    if (event.target.value === 0) {
      this.setState({ industry: undefined, industryError: true });
    } else {
      this.setState({
        industry: event.target.value,
        industryError: false,
        industryNameForActivity: name,
      });
    }
    this.setState({
      industry: event.target.value,
      industryError: false,
      industryNameForActivity: name,
    });
  };

  handleDateChange(date) {
    // const event = new Date(date);
    // let startDate = event.toISOString();
    var selectedDate = new Date(date);
    var todayDate = new Date();
    if (selectedDate > todayDate) {
      this.setState(
        { startDate: date, startDateError: false, endDate: "" },
        () => {
          this.setState({ disablePost: true });
        }
      );
    } else {
      this.setState({ startDate: date, startDateError: false }, () => {
        this.setState({ disablePost: false });
      });
    }
    // this.focusTextInput();
  }

  handleEndDateChange(date) {
    // const event = new Date(date);
    // let endDate = event.toISOString();

    this.setState({ endDate: date, endDateError: false });
  }

  // custom select settings
  handleSelectChange = (selectedValue) => {
    // console.log(selectedValue, "selectedValue");
    this.setState({
      selectedValue: selectedValue,
    });
  };

  handleChange = (key) => (value) => {
    // console.log(key, value, "handleChange");
    this.setState({ [key]: value });
    if (this.state.jobTypeId !== "3") {
      if (key == "company") {
        if (value === "") {
          this.setState({
            companyError: true,
          });
        } else {
          // let val = validateCompany(value, "company");
          // if (val.error === true) {
          //   this.setState({ companyNameValidError: true });
          // } else {
          //   this.setState({ companyNameValidError: false });
          // }
          this.setState({
            companyError: false,
          });
        }
      }
    } else {
      if (key == "company") {
        if (value === "") {
          this.setState({
            companyError: false,
          });
        }
      }
    }
    if (key == "city") {
      if (value === "") {
        this.setState({
          cityError: true,
        });
      } else {
        // let val = validateCharacterAndSpace(value, "city");
        // if (val.error === true) {
        //   this.setState({ cityValidError: true });
        // } else {
        //   this.setState({ cityValidError: false });
        // }
        this.setState({
          cityError: false,
        });
      }
    }
    if (key == "stateName") {
      if (value === "") {
        this.setState({
          countryStateError: true,
        });
      } else {
        // let val = validateCharacterAndSpace(value, "state");
        // if (val.error === true) {
        //   this.setState({ stateValidError: true, countryStateError: false });
        // } else {
        //   this.setState({ stateValidError: false });
        // }
        this.setState({
          countryStateError: false,
        });
      }
    }
    if (this.state.jobTypeId !== "3") {
      if (key == "contactName") {
        if (value === "") {
          this.setState({
            contactNameError: true,
          });
        } else {
          // let val = Validators.isName(value, "firstname");
          // if (val.error === true) {
          //   this.setState({ nameValidError: true });
          // } else {
          //   this.setState({ nameValidError: false });
          // }
          this.setState({
            contactNameError: false,
          });
        }
      }
    } else {
      if (key == "contactName") {
        if (value === "") {
          this.setState({
            contactNameError: false,
          });
        }
      }
    }
    if (key == "position") {
      if (value === "") {
        this.setState({
          positionError: true,
        });
      } else {
        // let val = validatePosition(value, "position");
        // if (val.error === true) {
        //   this.setState({ positionValidError: true, position: value });
        // } else {
        //   this.setState({ positionValidError: false, position: value });
        // }
        this.setState({
          positionError: false,
        });
      }
    }
    if (this.state.jobTypeId !== "3") {
      if (key == "contactEmail") {
        if (value === "") {
          this.setState({
            emailError: true,
          });
        } else {
          // let val = Validators.email(value, "email");
          // if (val.error === true) {
          //   this.setState({ emailValidError: true });
          // } else {
          //   this.setState({ emailValidError: false });
          // }
          this.setState({
            emailError: false,
          });
        }
      }
    }
    if (this.state.jobTypeId !== "3") {
      if (key == "description") {
        if (value === "") {
          this.setState({
            descriptionError: true,
            description: value,
          });
        } else {
          this.setState({
            descriptionError: false,
            description: value,
          });
        }
      }
    }
    if (key == "address1") {
      if (value === "") {
        this.setState({
          addressError: true,
        });
      } else {
        this.setState({
          addressError: false,
        });
      }
    }
    if (key == "telephone") {
      // let val = Validators.isPhone(value, "phone");
      // if (val.error === true) {
      //   this.setState({ phoneValidError: true });
      // } else {
      //   this.setState({ phoneValidError: false });
      // }
      if (value === "") {
        this.setState({ phoneValidError: false });
      }
    }
    if (key == "zip") {
      // let val = validateCharacterAndDigit(value, "zip");
      // if (val.error === true) {
      //   this.setState({ zipValidError: true });
      // } else {
      //   this.setState({ zipValidError: false });
      // }
      if (value === "") {
        this.setState({ zipValidError: false });
      }
    }
    if (this.state.jobTypeId === "3") {
      if (key == "postLink") {
        if (value === "") {
          this.setState({
            linkError: true,
            linkValidError: false,
          });
        } else {
          this.setState({
            linkError: false,
            linkValidError: false,
          });
        }
      }
    }
  };

  validateContent(value) {
    const ProfanitiesWords = this.state.ProfanitiesWords;
    let data;
    if (value != null) {
      data = value.toLowerCase();
      ProfanitiesWordKeysToReplace.forEach((item) => {
        data = data.replaceAll(item, "");
      });
      data = data.replace(/\s/g, "~");
      let list = data.split("~");
      if (list.some((w) => ProfanitiesWords.includes(w))) {
        return true;
      }
    }
    return false;
  }

  handleOnBlur = (key) => (value) => {
    // console.log(key, value, "handleChange");
    this.setState({ [key]: value });
    if (key == "payRate") {
      let payval = validatePayrate(value, "payRate");
      if (payval.error === true) {
        this.setState({ payRateError: true });
      } else {
        if (this.validateContent(value)) {
          this.setState({ validCompensationContentError: true });
        } else {
          this.setState({ validCompensationContentError: false });
        }
        this.setState({ payRateError: false });
      }
      if (value === "") {
        this.setState({ payRateError: false });
      }
    }
    if (this.state.jobTypeId !== "3") {
      if (key == "company") {
        if (value === "") {
          this.setState({
            companyError: true,
          });
        } else {
          let val = validateCompany(value, "company");
          if (val.error === true) {
            this.setState({ companyNameValidError: true });
          } else {
            if (this.validateContent(value)) {
              this.setState({ validCompanyContentError: true });
            } else {
              this.setState({ validCompanyContentError: false });
            }
            this.setState({ companyNameValidError: false });
          }
          this.setState({
            companyError: false,
          });
        }
      }
    } else {
      if (key == "company") {
        if (value === "") {
          this.setState({
            companyError: false,
          });
        } else {
          let val = validateCompany(value, "company");
          if (val.error === true) {
            this.setState({ companyNameValidError: true });
          } else {
            if (this.validateContent(value)) {
              this.setState({ validCompanyContentError: true });
            } else {
              this.setState({ validCompanyContentError: false });
            }
            this.setState({ companyNameValidError: false });
          }
          this.setState({
            companyError: false,
          });
        }
      }
    }
    if (key == "city") {
      if (value === "") {
        this.setState({
          cityError: true,
        });
      } else {
        let val = validateCity(value, "city");
        if (val.error === true) {
          this.setState({ cityValidError: true });
        } else {
          if (this.validateContent(value)) {
            this.setState({ validCityContentError: true });
          } else {
            this.setState({ validCityContentError: false });
          }
          this.setState({ cityValidError: false });
        }
        this.setState({
          cityError: false,
        });
      }
    }
    if (key == "stateName") {
      if (value === "") {
        this.setState({
          countryStateError: true,
        });
      } else {
        let val = validateCharacterAndSpace(value, "state");
        if (val.error === true) {
          this.setState({ stateValidError: true, countryStateError: false });
        } else {
          if (this.validateContent(value)) {
            this.setState({ validStateContentError: true });
          } else {
            this.setState({ validStateContentError: false });
          }
          this.setState({ stateValidError: false });
        }
        this.setState({
          countryStateError: false,
        });
      }
    }
    if (this.state.jobTypeId !== "3") {
      if (key == "contactName") {
        if (value === "") {
          this.setState({
            contactNameError: true,
          });
        } else {
          let val = Validators.isName(value, "firstname");
          if (val.error === true) {
            this.setState({ nameValidError: true });
          } else {
            if (this.validateContent(value)) {
              this.setState({ validContactContentError: true });
            } else {
              this.setState({ validContactContentError: false });
            }
            this.setState({ nameValidError: false });
          }
          this.setState({
            contactNameError: false,
          });
        }
      }
    } else {
      if (key == "contactName") {
        if (value === "") {
          this.setState({
            contactNameError: false,
          });
        } else {
          let val = Validators.isName(value, "firstname");
          if (val.error === true) {
            this.setState({ nameValidError: true });
          } else {
            if (this.validateContent(value)) {
              this.setState({ validContactContentError: true });
            } else {
              this.setState({ validContactContentError: false });
            }
            this.setState({ nameValidError: false });
          }
          this.setState({
            contactNameError: false,
          });
        }
      }
    }
    if (key == "position") {
      if (value === "") {
        this.setState({
          positionError: true,
        });
      } else {
        let val = validatePosition(value, "position");
        if (val.error === true) {
          this.setState({ positionValidError: true, position: value });
        } else {
          if (this.validateContent(value)) {
            this.setState({ validPositionContentError: true });
          } else {
            this.setState({ validPositionContentError: false });
          }
          this.setState({ positionValidError: false, position: value });
        }
        this.setState({
          positionError: false,
        });
      }
    }
    if (this.state.jobTypeId !== "3") {
      if (key == "contactEmail") {
        if (value === "") {
          this.setState({
            emailError: true,
          });
        } else {
          let val = Validators.email(value, "email");
          if (val.error === true) {
            this.setState({ emailValidError: true });
          } else {
            this.setState({ emailValidError: false });
          }
          this.setState({
            emailError: false,
          });
        }
      }
    }
    if (this.state.jobTypeId !== "3") {
      if (key == "description") {
        if (value === "") {
          this.setState({
            descriptionError: true,
            description: value,
          });
        } else {
          if (this.validateContent(value)) {
            this.setState({ validDescriptionContentError: true });
          } else {
            this.setState({ validDescriptionContentError: false });
          }
          this.setState({
            descriptionError: false,
            description: value,
          });
        }
      }
    }
    if (key == "address1") {
      if (value === "") {
        this.setState({
          addressError: true,
        });
      } else {
        if (this.validateContent(value)) {
          this.setState({ validAddress1ContentError: true });
        } else {
          this.setState({ validAddress1ContentError: false });
        }
        this.setState({
          addressError: false,
        });
      }
    }
    if (key == "address2") {
      if (this.validateContent(value)) {
        this.setState({ validAddress2ContentError: true });
      } else {
        this.setState({ validAddress2ContentError: false });
      }
    }
    if (key == "mQualification") {
      if (this.validateContent(value)) {
        this.setState({ validMinimumQualContentError: true });
      } else {
        this.setState({ validMinimumQualContentError: false });
      }
    }
    if (key == "responsibilities") {
      if (this.validateContent(value)) {
        this.setState({ validPositionResContentError: true });
      } else {
        this.setState({ validPositionResContentError: false });
      }
    }
    if (key == "pQualification") {
      if (this.validateContent(value)) {
        this.setState({ validPreferredQualContentError: true });
      } else {
        this.setState({ validPreferredQualContentError: false });
      }
    }
    if (key == "policy") {
      if (this.validateContent(value)) {
        this.setState({ validEmpPolicyContentError: true });
      } else {
        this.setState({ validEmpPolicyContentError: false });
      }
    }
    if (key == "telephone") {
      let val = Validators.isPhone(value, "phone");
      if (val.error === true) {
        this.setState({ phoneValidError: true });
      } else {
        this.setState({ phoneValidError: false });
      }
      if (value === "") {
        this.setState({ phoneValidError: false });
      }
    }
    if (key == "zip") {
      let val = validateCharacterAndDigit(value, "zip");
      if (val.error === true) {
        this.setState({ zipValidError: true });
      } else {
        if (this.validateContent(value)) {
          this.setState({ validZipContentError: true });
        } else {
          this.setState({ validZipContentError: false });
        }
        this.setState({ zipValidError: false });
      }
      if (value === "") {
        this.setState({ zipValidError: false });
      }
    }
    if (this.state.jobTypeId === "3") {
      if (key == "postLink") {
        if (value === "") {
          this.setState({
            linkError: true,
            linkValidError: false,
          });
        } else {
          if (this.validateContent(value)) {
            this.setState({ validPostLinkContentError: true });
          } else {
            this.setState({ validPostLinkContentError: false });
          }
          this.setState({
            linkError: false,
            linkValidError: false,
          });
        }
      }
    }
  };
  // validation check

  validate = (
    typeId,
    start,
    end,
    companyName,
    groupName,
    address,
    countryName,
    countryStateName,
    cityName,
    contact,
    email,
    positionName,
    desc,
    travel,
    phone,
    zipcode,
    job,
    industries,
    link,
    pay
  ) => {
    let result = true;

    // // console.log(this.state.resultState);
    let jobTypeId = typeId;
    let startDate = start;
    let endDate = end;
    let company = companyName;
    let group = groupName;
    let address1 = address;
    let country = countryName;
    let countryState = countryStateName;
    let city = cityName;
    let contactName = contact;
    let contactEmail = email;
    let position = positionName;
    let description = desc;
    let travelRequired = travel;
    let telephone = phone;
    let zip = zipcode;
    let jobLength = job;
    let industry = industries;
    let postLink = link;
    let payRate = pay;

    // console.log(jobTypeId, 'id', startDate, 'sd', endDate, company, group, address1, country,
    //   countryState, city, contactName, contactEmail, 'em', position, 'pos', description, 'desc',
    //   travelRequired, 'req', telephone, 'tele', zip, jobLength, 'length', industry, postLink, 'link');
    if (Validators.isUndefined(jobTypeId, "jobTypeId")) {
      result = false;
      this.setState({ jobTypeIdError: true, resultState: false }, () => {
        this.setState({ resultState: true });
      });
    }

    if (Validators.isUndefined(startDate, "startDate")) {
      result = false;
      this.setState({ startDateError: true });
    } else {
      result = true;
      this.setState({ startDateError: false }, () => {
        this.setState({ resultState: true });
      });
    }

    if (Validators.isUndefined(endDate, "endDate")) {
      result = false;
      this.setState({ endDateError: true });
    } else {
      result = true;
      this.setState({ endDateError: false }, () => {
        this.setState({ resultState: true });
      });
    }

    if (jobTypeId !== "3") {
      if (Validators.isNull(company)) {
        result = false;
        this.setState({ companyError: true });
      } else {
        let val = validateCompany(company, "company");
        if (val.error === true) {
          result = false;
          this.setState({ companyNameValidError: true });
        } else {
          result = true;
          this.setState({ companyNameValidError: false }, () => {
            this.setState({ resultState: true });
          });
        }
        // result = true;
        this.setState({ companyError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    } else {
      if (company === "" || Validators.isUndefined(company)) {
        result = true;
        this.setState({ companyNameValidError: false }, () => {
          this.setState({ resultState: true });
        });
      } else {
        let val = validateCompany(company, "company");
        if (val.error === true) {
          result = false;
          this.setState({ companyNameValidError: true, companyError: false });
        } else {
          result = true;
          this.setState({ companyNameValidError: false }, () => {
            this.setState({ resultState: true });
          });
        }
        //result = true;
        this.setState({ companyError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    }

    if (
      Validators.isUndefined(group) ||
      group === [0] ||
      group === 0 ||
      group === "" ||
      group[0] === 0
    ) {
      result = false;
      this.setState({ groupError: true });
    } else {
      result = true;
      this.setState({ groupError: false }, () => {
        this.setState({ resultState: true });
      });
    }

    if (Validators.isUndefined(address1) || address1 == "") {
      result = false;
      this.setState({ addressError: true });
    } else {
      result = true;
      this.setState({ addressError: false }, () => {
        this.setState({ resultState: true });
      });
    }

    if (this.state.jobTypeId !== "3") {
      if (this.state.jobTypeId !== "3") {
        if (
          Validators.isUndefined(country) ||
          country === 0 ||
          country === "0"
        ) {
          result = false;
          this.setState({ countryError: true });
        } else {
          result = true;
          this.setState({ countryError: false }, () => {
            this.setState({ resultState: true });
          });
        }
      }
    } else {
      result = true;
      this.setState({ countryError: false }, () => {
        this.setState({ resultState: true });
      });
    }

    if (!this.state.showStateTextBox) {
      if (this.state.jobTypeId !== "3") {
        if (
          Validators.isUndefined(countryState) ||
          countryState === "" ||
          countryState === 0
        ) {
          result = false;
          this.setState({ countryStateError: true });
        } else {
          let val = validateCharacterSpaceAndDigit(countryState, "state");
          if (val.error === true) {
            result = false;
            this.setState({ stateValidError: true, countryStateError: false });
          } else {
            result = true;
            this.setState({ stateValidError: false }, () => {
              this.setState({ resultState: true });
            });
          }
          //result = true;
          this.setState({ countryStateError: false }, () => {
            this.setState({ resultState: true });
          });
        }
      } else {
        if (countryState === "" || Validators.isUndefined(countryState)) {
          result = true;
          this.setState({ stateValidError: false }, () => {
            this.setState({ resultState: true });
          });
        } else {
          let val = validateCharacterSpaceAndDigit(countryState, "state");
          if (val.error === true) {
            result = false;
            this.setState({ stateValidError: true, countryStateError: false });
          } else {
            result = true;
            this.setState({ stateValidError: false }, () => {
              this.setState({ resultState: true });
            });
          }
          //result = true;
          this.setState({ countryStateError: false }, () => {
            this.setState({ resultState: true });
          });
        }
      }
    } else {
      if (this.state.jobTypeId !== "3") {
        if (
          Validators.isUndefined(countryState) ||
          countryState === "" ||
          countryState === 0
        ) {
          result = false;
          this.setState({ countryStateError: true });
        } else {
          let val = validateCharacterAndSpace(countryState, "state");
          if (val.error === true) {
            result = false;
            this.setState({ stateValidError: true, countryStateError: false });
          } else {
            result = true;
            this.setState({ stateValidError: false }, () => {
              this.setState({ resultState: true });
            });
          }
          //result = true;
          this.setState({ countryStateError: false }, () => {
            this.setState({ resultState: true });
          });
        }
      } else {
        if (countryState === "" || Validators.isUndefined(countryState)) {
          result = true;
          this.setState({ stateValidError: false }, () => {
            this.setState({ resultState: true });
          });
        } else {
          let val = validateCharacterAndSpace(countryState, "state");
          if (val.error === true) {
            result = false;
            this.setState({ stateValidError: true, countryStateError: false });
          } else {
            result = true;
            this.setState({ stateValidError: false }, () => {
              this.setState({ resultState: true });
            });
          }
          //result = true;
          this.setState({ countryStateError: false }, () => {
            this.setState({ resultState: true });
          });
        }
      }
    }

    if (this.state.jobTypeId !== "3") {
      if (Validators.isUndefined(city)) {
        result = false;
        this.setState({ cityError: true });
      } else {
        let val = validateCity(city, "city");
        if (val.error === true) {
          result = false;
          this.setState({ cityValidError: true });
        } else {
          result = true;
          this.setState({ cityValidError: false }, () => {
            this.setState({ resultState: true });
          });
        }
        result = true;
        this.setState({ cityError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    } else {
      if (city === "" || Validators.isUndefined(city)) {
        result = true;
        this.setState({ cityValidError: false }, () => {
          this.setState({ resultState: true });
        });
      } else {
        let val = validateCity(city, "city");
        if (val.error === true) {
          result = false;
          this.setState({ cityValidError: true, cityError: false });
        } else {
          result = true;
          this.setState({ cityValidError: false }, () => {
            this.setState({ resultState: true });
          });
        }
        //result = true;
        this.setState({ cityError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    }

    if (jobTypeId !== "3") {
      if (Validators.isUndefined(contactName)) {
        result = false;
        this.setState({ contactNameError: true });
      } else {
        let val = Validators.isName(contactName, "firstname");
        if (val.error === true) {
          result = false;
          this.setState({ nameValidError: true, contactNameError: false });
        } else {
          result = true;
          this.setState({ nameValidError: false }, () => {
            this.setState({ resultState: true });
          });
        }
        // result = true;
        this.setState({ contactNameError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    } else {
      if (contactName === "" || Validators.isUndefined(contactName)) {
        result = true;
        this.setState({ contactNameError: false }, () => {
          this.setState({ resultState: true });
        });
      } else {
        let val = Validators.isName(contactName, "contactName");
        if (val.error === true) {
          result = false;
          this.setState({ nameValidError: true, contactNameError: false });
        } else {
          result = true;
          this.setState({ nameValidError: false }, () => {
            this.setState({ resultState: true });
          });
        }
        //result = true;
        this.setState({ contactNameError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    }

    if (jobTypeId !== "3") {
      if (Validators.isUndefined(contactEmail)) {
        result = false;
        this.setState({ emailError: true });
      } else {
        let val = Validators.email(contactEmail, "email");
        if (val.error === true) {
          result = false;
          this.setState({ emailValidError: true });
        } else {
          result = true;
          this.setState({ emailValidError: false }, () => {
            this.setState({ resultState: true });
          });
        }
        // result = true;
        this.setState({ emailError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    } else {
      if (contactEmail === "" || Validators.isUndefined(contactEmail)) {
        result = true;
        this.setState({ emailValidError: false }, () => {
          this.setState({ resultState: true });
        });
      } else {
        let val = Validators.email(contactEmail, "contactEmail");
        if (val.error === true) {
          result = false;
          this.setState({ emailValidError: true, emailError: false });
        } else {
          result = true;
          this.setState({ emailValidError: false }, () => {
            this.setState({ resultState: true });
          });
        }
        //result = true;
        this.setState({ emailError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    }

    if (Validators.isUndefined(position)) {
      result = false;
      this.setState({ positionError: true });
    } else {
      let val = validatePosition(position, "position");
      if (val.error === true) {
        result = false;
        this.setState({ positionValidError: true });
      } else {
        result = true;
        this.setState({ positionValidError: false }, () => {
          this.setState({ resultState: true });
        });
      }
      // result = true;
      this.setState({ positionError: false }, () => {
        this.setState({ resultState: true });
      });
    }

    if (jobTypeId !== "3") {
      if (Validators.isUndefined(description)) {
        result = false;
        this.setState({ descriptionError: true });
      } else {
        result = true;
        this.setState({ descriptionError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    }
    if (jobTypeId !== "3") {
      if (Validators.isUndefined(travelRequired) || travelRequired === "") {
        result = false;
        this.setState({ travelError: true }, () => {
          this.setState({ resultState: false });
        });
      } else {
        result = true;
        this.setState({ travelError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    } else {
      if (Validators.isUndefined(travelRequired) || travelRequired === "") {
        result = true;
        this.setState({ travelError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    }

    if (telephone === undefined || telephone === "") {
      result = true;
      this.setState({ phoneValidError: false }, () => {
        this.setState({ resultState: true });
      });
    } else {
      let val = Validators.isPhone(telephone, "telephone");
      if (val.error === true) {
        result = false;
        this.setState({ phoneValidError: true });
      } else {
        result = true;
        this.setState({ phoneValidError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    }

    let zipval = validateCharacterAndDigit(zip, "zip");
    if (zipval.error === true) {
      result = false;
      this.setState({ zipValidError: true });
    } else {
      result = true;
      this.setState({ zipValidError: false }, () => {
        this.setState({ resultState: true });
      });
    }
    if (zip === "") {
      result = true;
      this.setState({ zipValidError: false }, () => {
        this.setState({ resultState: true });
      });
    }

    if (jobTypeId !== "3") {
      if (Validators.isUndefined(jobLength) || jobLength === "") {
        result = false;
        this.setState({ joblengthError: true });
      } else {
        result = true;
        this.setState({ joblengthError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    } else {
      if (Validators.isUndefined(jobLength) || jobLength === "") {
        result = true;
        this.setState({ joblengthError: false });
      }
    }

    if (
      Validators.isUndefined(industry) ||
      industry === "" ||
      industry === "0"
    ) {
      result = false;
      this.setState({ industryError: true });
    } else {
      result = true;
      this.setState({ industryError: false }, () => {
        this.setState({ resultState: true });
      });
    }

    if (jobTypeId === "3") {
      if (Validators.isUndefined(postLink) || postLink === "") {
        result = false;
        this.setState({ linkError: true });
      }
      // else {
      //   let val = validateURL(postLink, "postlink");
      //   if (val.error === true) {
      //     result = false;
      //     this.setState({ linkValidError: true });
      //   }
      else {
        result = true;
        this.setState({ linkError: false }, () => {
          this.setState({ resultState: true });
        });
      }
    }
    // }

    let payval = validatePayrate(payRate, "payRate");
    if (payval.error === true) {
      result = false;
      this.setState({ payRateError: true });
    } else {
      result = true;
      this.setState({ payRateError: false }, () => {
        this.setState({ resultState: true });
      });
    }
    if (payRate === "") {
      result = true;
      this.setState({ payRateError: false }, () => {
        this.setState({ resultState: true });
      });
    }

    // console.log(result, this.state.resultState, jobTypeId, 'final');

    if (
      this.state.validCompanyContentError ||
      this.state.validAddress1ContentError ||
      this.state.validAddress2ContentError ||
      this.state.validCityContentError ||
      this.state.validStateContentError ||
      this.state.validZipContentError ||
      this.state.validContactContentError ||
      this.state.validPositionContentError ||
      this.state.validCompensationContentError ||
      this.state.validDescriptionContentError ||
      this.state.validPositionResContentError ||
      this.state.validMinimumQualContentError ||
      this.state.validPreferredQualContentError ||
      this.state.validEmpPolicyContentError ||
      this.state.validPostLinkContentError
    ) {
      return false;
    }

    if (jobTypeId !== "3") {
      if (
        jobTypeId === undefined ||
        this.state.nameValidError === true ||
        startDate === undefined ||
        endDate === undefined ||
        company === undefined ||
        group === undefined ||
        group === [0] ||
        group === "" ||
        group === 0 ||
        group[0] === 0 ||
        address1 === undefined ||
        country === undefined ||
        country === 0 ||
        country === "0" ||
        countryState === undefined ||
        countryState === 0 ||
        countryState === "" ||
        this.state.stateValidError === true ||
        city === undefined ||
        contactName === undefined ||
        this.state.cityValidError === true ||
        this.state.zipValidError === true ||
        this.state.payRateError === true ||
        this.state.phoneValidError === true ||
        contactEmail === undefined ||
        position === undefined ||
        this.state.positionValidError === true ||
        description === undefined ||
        travelRequired === undefined ||
        travelRequired === "" ||
        jobLength === undefined ||
        jobLength === "" ||
        industry === undefined ||
        industry === "0" ||
        this.state.companyNameValidError === true
      ) {
        // this.setState({ resultState: false });
        result = false;
        return result;
      } else return result;
    } else {
      // console.log(this.state.linkValidError);
      if (
        jobTypeId === undefined ||
        this.state.nameValidError === true ||
        startDate === undefined ||
        endDate === undefined ||
        this.state.payRateError === true ||
        group === undefined ||
        group === [0] ||
        group === 0 ||
        group === "" ||
        this.state.phoneValidError === true ||
        this.state.cityValidError === true ||
        this.state.stateValidError === true ||
        this.state.zipValidError === true ||
        group[0] === 0 ||
        address1 === undefined ||
        position === undefined ||
        this.state.positionValidError === true ||
        industry === undefined ||
        industry === "0" ||
        this.state.companyNameValidError === true ||
        postLink === undefined ||
        postLink === ""
      ) {
        result = false;
        return result;
      } else return result;
    }
  };

  setOptions(e) {
    // console.log(e.target.value, "options");
    if (e.target.name == "telecommute") {
      this.setState({ telecommute: e.target.value });
    }

    if (e.target.name == "travel") {
      if (e.target.value == "") {
        this.setState({ travelRequired: undefined, travelError: true });
      } else {
        this.setState({ travelRequired: e.target.value, travelError: false });
      }
      this.setState({ travelRequired: e.target.value });
    }
    if (e.target.name == "joblength") {
      if (e.target.value == "") {
        this.setState({ jobLength: undefined, joblengthError: true });
      } else {
        this.setState({ jobLength: e.target.value, joblengthError: false });
      }
      this.setState({ jobLength: e.target.value });
    }
    if (e.target.name == "countryState") {
      if (e.target.value == "" || e.target.value === "0") {
        this.setState({
          countryState: 0,
          countryStateError: true,
        });
      } else {
        this.setState({
          countryState: e.target.value,
          countryStateError: false,
        });
      }
    }
  }

  onChangeGroup(e) {
    // console.log(e.target.value, e.target.name, "changegroup");
    if (e.target.name == "group") {
      // console.log(e.target.value);
      if (e.target.value == "" || e.target.value == "0") {
        this.setState({
          groupError: true,
        });
      } else {
        this.setState({
          groupError: false,
        });
      }
    }
    if (e.target.name == "industry") {
      if (e.target.value == "") {
        this.setState({
          industryError: true,
        });
      } else {
        this.setState({
          industryError: false,
        });
      }
    }

    if (this.state.jobTypeId !== "3") {
      if (e.target.name == "joblength") {
        if (e.target.value == "") {
          this.setState({
            joblengthError: true,
          });
        } else {
          this.setState({
            joblengthError: false,
          });
        }
      }
    }
  }

  setRefresh = () => {
    this.setState({ disablePost: false, disableDraft: false });
    // window.location.href = `${process.env.PUBLIC_URL}/#/jobslist`;
  };

  validateLink = (link) => {
    let postLink = link;
    let result = true;
    let val = validateURL(postLink, "postlink");
    if (val.error === true) {
      console.log(val.error);
      result = false;
      this.setState({ linkValidError: true });
    } else {
      result = true;
      this.setState({ linkError: false }, () => {
        this.setState({ resultState: true });
      });
    }
    return result;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(event.target.innerText);
    let jobPostType = "";
    if (event.target.innerText == "Post job") {
      jobPostType = "Published Jobs";
    } else {
      jobPostType = "Draft Jobs";
    }

    const {
      jobTypeId,
      description,
      position,
      payRate,
      contactName,
      contactEmail,
      company,
      telephone,
      telecommute,
      pQualification,
      jobLength,
      travelRequired,
      responsibilities,
      mQualification,
      policy,
      postLink,
      startDate,
      endDate,
      address1,
      address2,
      country,
      countryState,
      city,
      zip,
      group,
      stateName,
      showStateTextBox,
      postType,
      resultState,
      industry,
      industryNameForActivity,
    } = this.state;

    let result = "";
    if (!showStateTextBox) {
      result = this.validate(
        jobTypeId,
        startDate,
        endDate,
        company,
        group,
        address1,
        country,
        countryState,
        city,
        contactName,
        contactEmail,
        position,
        description,
        travelRequired,
        telephone,
        zip,
        jobLength,
        industry,
        postLink,
        payRate
      );
    } else {
      if (showStateTextBox) {
        result = this.validate(
          jobTypeId,
          startDate,
          endDate,
          company,
          group,
          address1,
          country,
          stateName,
          city,
          contactName,
          contactEmail,
          position,
          description,
          travelRequired,
          telephone,
          zip,
          jobLength,
          industry,
          postLink,
          payRate
        );
      }
    }
    // console.log('result', result, resultState);

    // let linkValid = "";
    // if (jobTypeId === "3") {
    //   let link = this.validateLink(postLink);
    //   console.log(link);
    //   if (link === true) {
    //     linkValid = true;
    //   } else linkValid = false;
    // } else {
    //   linkValid = true;
    // }

    if (result) {
      var start = moment(startDate).format("YYYY/MM/DD");

      var end =
        moment(endDate).format("YYYY/MM/DD") +
        " " +
        moment(new Date()).format("hh:mm:ss a");
      var UTCJobEndDate = new Date(end).toISOString();

      let payload = {
        JobTypeId: jobTypeId,
        JobDescription: description,
        JobStatus: jobPostType,
        IndustryId: industry,
        PositionTitle: position.trim(),
        PayRate: payRate,
        ContactName: contactName,
        ContactEmail: contactEmail,
        CompanyName: company,
        ContactPhone: telephone,
        Telecommute: telecommute,
        PreferredQualification: pQualification,
        JobLength: jobLength,
        TravelRequired: travelRequired,
        PositionResponsibilities: responsibilities,
        MinimumQualification: mQualification,
        EmploymentPolicy: policy,
        JobPostingLink: postLink,
        //"http://www.postlink.com",
        JobStartDate: start,
        JobEndDate: end,
        UTCJobEndDate: UTCJobEndDate,
        AddressLine1: address1,
        AddressLine2: address2,
        City: city,
        StateId: showStateTextBox ? 0 : countryState,
        StateName: showStateTextBox ? stateName : null,
        CountryId: country,
        ZipCode: zip,
        GroupIds: group,
        IndustryName: industryNameForActivity,
        CurrentDate: moment(new Date()).format("YYYY/MM/DD"),
      };

      const toastId = "custom_toast";
      const headers = {
        Authorization: "Bearer " + getData("token"),
        "content-type": "application/json",
      };
      this.setState({ disablePost: true, disableDraft: true });
      post(API_URL + `job/createjob`, payload)
        .then((response) => {
          // console.log(response, response.message, "response success");
          this.setState({ disablePost: false, disableDraft: false });
          if (response.status === 200) {
            setTimeout(this.setRefresh, 7000);
            // console.log(response);
            toast.success(response.data.response, { toastId: toastId });
            window.location.href = `${process.env.PUBLIC_URL}/#/jobslist`;
          }
        })
        .catch(function (error) {
          this.setState({ disablePost: false, disableDraft: false });
          if (error.response) {
            toast.error(error.response);
            console.log(error.response);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error);
        });

      // axios
      //   .post(
      //     `http://gateway.jin.jobinfonetwork.com/api/job/createjob`,
      //     payload,
      //     { headers: headers }
      //   )
      //   .then((response) => {
      //     // console.log(response, response.message, "response success");
      //     if (response.status === 200) {
      //       setTimeout(this.setRefresh, 7000);
      //       toast.success(response.data.response, { toastId: toastId });
      //       window.location.href = `${process.env.PUBLIC_URL}/#/jobslist`;
      //     }
      //   })
      //   .catch(function (error) {
      //     if (error.response) {
      //       // The request was made and the server responded with a status code
      //       // that falls out of the range of 2xx
      //       toast.error(error.response);
      //       console.log(error.response);
      //     } else if (error.request) {
      //       console.log(error.request);
      //     } else {
      //       console.log("Error", error.message);
      //     }
      //     //  console.log(error.config);
      //   });
    } else {
      console.log("Invalid");
      return;
    }
  };

  renderTooltip = (props) => (
    <Tooltip
      id="button-tooltip"
      {...props}
      // style={{
      //   backgroundColor: 'black',
      //   padding: '2px 10px',
      //   color: 'black',
      //   borderRadius: 3,
      //   ...props.style,
      // }}
    >
      Employer should have a 60 day maximum from start date to end date.
    </Tooltip>
  );

  render() {
    const {
      jobTypeId,
      jobTitle,
      location,
      company,
      salary,
      description,
      address1,
      country,
      countryState,
      address2,
      city,
      state,
      zip,
      contactName,
      contactEmail,
      telephone,
      position,
      payRate,
      responsibilities,
      pQualification,
      mQualification,
      policy,
      jobLength,
      countryList,
      countryStateList,
      industryList,
      groupList,
      industry,
      joblengthError,
      postLink,
      stateName,
      showStateTextBox,
      jobTypeIdError,
      startDateError,
      endDateError,
      addressError,
      countryError,
      countryStateError,
      cityError,
      companyError,
      companyNameValidError,
      phoneValidError,
      stateValidError,
      cityValidError,
      zipValidError,
      contactNameError,
      nameValidError,
      emailError,
      emailValidError,
      positionError,
      positionValidError,
      travelRequired,
      draftsCount,

      validCompanyContentError,
      validAddress1ContentError,
      validAddress2ContentError,
      validCityContentError,
      validStateContentError,
      validZipContentError,
      validContactContentError,
      validPositionContentError,
      validCompensationContentError,
      validDescriptionContentError,
      validPositionResContentError,
      validMinimumQualContentError,
      validPreferredQualContentError,
      validEmpPolicyContentError,
      validPostLinkContentError,
    } = this.state;

    // let checkval = [];
    // if (industry) {
    //   for (let i = 0; i < industry.length; i++) {
    //     checkval.push(industry[i].IndustryId);
    //   }
    // }

    return (
      <div className="container p-0 post-job-container">
        <h1 className="page-title d-flex align-items-center">
          Post a Job
          <div className="saved-job ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/savedlaterjobs`}>
              Saved Draft Job
            </Link>
            <span className="ml-3">{draftsCount}</span>
          </div>
        </h1>
        <div className="dashboard-wrapper resumes-page">
          <Form noValidate>
            <Row>
              <Col md={12}>
                <div className="dashboard-box activity dashboard-col-bg post-job-form">
                  <p className="text-danger mb-3 text-right small">
                    All fields marked with * are required.
                  </p>
                  <Row>
                    <Col md={12}>
                      <div className="radio-buttons">
                        <label className="radio-label">Job Poster: *</label>
                      </div>
                      <div className="mb-3 d-flex radio-buttons">
                        <div className="radio mr-4">
                          <input
                            id="radio-1"
                            value="1"
                            name="radio"
                            type="radio"
                            onChange={this.buttonChange}
                          />
                          <label htmlFor="radio-1" className="radio-label">
                            Hiring Manager
                          </label>
                        </div>
                        <div className="radio mr-4">
                          <input
                            id="radio-2"
                            name="radio"
                            value="2"
                            type="radio"
                            onChange={this.buttonChange}
                          />
                          <label htmlFor="radio-2" className="radio-label">
                            Company Employee
                          </label>
                          <br />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label style={{ fontSize: 12 }}>
                            (Not Hiring manager)
                          </label>
                        </div>
                        <div className="radio">
                          <input
                            id="radio-3"
                            name="radio"
                            type="radio"
                            value="3"
                            onChange={this.buttonChange}
                          />
                          <label htmlFor="radio-3" className="radio-label">
                            Unaffiliated
                          </label>
                        </div>
                      </div>
                      {jobTypeIdError && (
                        <p style={{ color: "red" }}>
                          Job Poster type is required
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <div className="form-group">
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleDateChange}
                          minDate={this.minDate}
                          minDate={new Date()}
                          value={this.props.startDate}
                          placeholderText="Job post start date *"
                          className="inputclass form-control"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          // strictParsing={true}
                          shouldCloseOnSelect={true}
                          onCalendarClose={() => {
                            if (this.state.startDate) this.focusTextInput();
                          }}
                          required
                        />
                      </div>
                      {startDateError && (
                        <p style={{ color: "red" }}>Start Date is required</p>
                      )}
                    </Col>
                    <Col md={4} className="position-relative">
                      <span
                        className="ques-info-icon"
                        style={{ cursor: "pointer", float: "right" }}
                      >
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip}
                        >
                          <img
                            style={{
                              width: "15px",
                              bottom: "30px",
                              position: "absolute",
                              marginLeft: "4px",
                            }}
                            src={help_icon}
                          />
                        </OverlayTrigger>
                      </span>
                      <div className="form-group">
                        <DatePicker
                          // dayClassName={date => this.state.endDate.getTime() === new Date('04/25/2019').getTime() ? 'disabled-date' : undefined}
                          selected={this.state.endDate}
                          onChange={this.handleEndDateChange}
                          minDate={this.state.startDate}
                          maxDate={
                            this.state.startDate &&
                            new Date(
                              new Date(this.state.startDate).setDate(
                                new Date(this.state.startDate).getDate() + 60
                              )
                            )
                          }
                          value={this.props.endDate}
                          placeholderText="Job post end date *"
                          className="inputclass form-control"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          ref={this.textInput}
                        />
                      </div>
                      {endDateError && (
                        <p style={{ color: "red" }}>End Date is required</p>
                      )}
                    </Col>
                    <Col md={4} className={jobTypeId !== "3" ? "" : ""}>
                      <FormInput
                        value={this.state.company}
                        maxLength={30}
                        type="text"
                        placeholder={
                          jobTypeId !== "3" ? "Company *" : "Company *"
                        }
                        required="required"
                        onChange={this.handleChange("company")}
                        onBlur={this.handleOnBlur("company")}
                      />
                      {companyError && jobTypeId !== "3" && (
                        <p style={{ color: "red" }}>Company is required</p>
                      )}
                      {companyNameValidError && !companyError && (
                        <p style={{ color: "red" }}>Enter valid company name</p>
                      )}
                      {validCompanyContentError && (
                        <p style={{ color: "red" }}>Disallowed Content</p>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="dd">
                      <Form.Group controlId="dd">
                        <Form.Control
                          as="select"
                          name="group"
                          onChange={this.handleGroupChange}
                          className="inputclass form-control "
                        >
                          <option value="0">Select Group *</option>
                          {groupList &&
                            groupList.map((item) => (
                              <option value={item.groupId}>
                                {item.groupName}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                      {this.state.groupError && (
                        <p style={{ color: "red" }}>Group is required</p>
                      )}
                    </Col>
                    <Col className="">
                      <FormInput
                        value={address1}
                        maxLength={150}
                        type="text"
                        placeholder="Address line 1 *"
                        onChange={this.handleChange("address1")}
                        onBlur={this.handleOnBlur("address1")}
                      />
                      {addressError && (
                        <p style={{ color: "red" }}>Address is required</p>
                      )}
                      {validAddress1ContentError && (
                        <p style={{ color: "red" }}>Disallowed Content</p>
                      )}
                    </Col>
                    <Col>
                      <FormInput
                        value={address2}
                        maxLength={150}
                        type="text"
                        placeholder="Address line 2"
                        onChange={this.handleChange("address2")}
                        onBlur={this.handleOnBlur("address2")}
                      />
                      {validAddress2ContentError && (
                        <p style={{ color: "red" }}>Disallowed Content</p>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className={jobTypeId !== "3" ? "dd" : "dd"} md={4}>
                      <Form.Group controlId="dd">
                        <Form.Control
                          as="select"
                          name="country"
                          value={country}
                          onChange={
                            (this.handleChange("country"),
                            this.handleCountryChange)
                          }
                          className="inputclass form-control"
                        >
                          <option value="0">
                            {jobTypeId !== "3"
                              ? "Select Country *"
                              : "Select Country *"}
                          </option>
                          {countryList &&
                            countryList.map((item) => (
                              <option value={item.countryId}>
                                {item.countryName}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                      <div>
                        {countryError && jobTypeId !== "3" && (
                          <p style={{ color: "red" }}>Country is required</p>
                        )}
                      </div>
                    </Col>
                    <Col md={4} className={jobTypeId !== "3" ? "dd" : "dd"}>
                      {!showStateTextBox && (
                        <Form.Group controlId="dd">
                          <Form.Control
                            as="select"
                            name="countryState"
                            value={countryState}
                            onChange={(e) => {
                              this.setOptions(e);
                            }}
                            className="inputclass form-control"
                          >
                            <option value="0">
                              {jobTypeId !== "3"
                                ? country == 2
                                  ? "Select Province *"
                                  : "Select State *"
                                : country == 2
                                ? "Select Province *"
                                : "Select State *"}
                            </option>
                            {countryStateList &&
                              countryStateList.map((item) => (
                                <option value={item.stateId}>
                                  {item.stateName}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                      )}
                      {showStateTextBox && (
                        <div
                          className={
                            jobTypeId !== "3" ? "form-group" : "form-group"
                          }
                        >
                          <FormInput
                            value={stateName}
                            maxLength={30}
                            type="text"
                            placeholder={
                              jobTypeId !== "3" ? "Country *" : "State *"
                            }
                            onChange={this.handleChange("stateName")}
                            onBlur={this.handleOnBlur("stateName")}
                          />
                        </div>
                      )}
                      <div>
                        {countryStateError && jobTypeId !== "3" && (
                          <p style={{ color: "red" }}>
                            {" "}
                            {country == 2
                              ? "Province is required"
                              : "State is required"}
                          </p>
                        )}
                        {stateValidError && !countryStateError && (
                          <p style={{ color: "red" }}>Enter valid State </p>
                        )}
                        {validStateContentError && (
                          <p style={{ color: "red" }}>Disallowed Content</p>
                        )}
                      </div>
                    </Col>
                    <Col md={4} className={jobTypeId !== "3" ? "" : ""}>
                      <FormInput
                        value={city}
                        maxLength={30}
                        type="text"
                        placeholder={jobTypeId !== "3" ? "City *" : "City *"}
                        required="required"
                        onChange={this.handleChange("city")}
                        onBlur={this.handleOnBlur("city")}
                      />
                      {cityError && jobTypeId !== "3" && (
                        <p style={{ color: "red" }}>City is required</p>
                      )}
                      {cityValidError && !cityError && (
                        <p style={{ color: "red" }}>Enter valid City</p>
                      )}
                      {validCityContentError && (
                        <p style={{ color: "red" }}>Disallowed Content</p>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <FormInput
                        value={zip}
                        type="text"
                        placeholder="Zip"
                        maxLength={6}
                        onChange={this.handleChange("zip")}
                        onBlur={this.handleOnBlur("zip")}
                      />
                      {zipValidError && (
                        <p style={{ color: "red" }}>Enter valid ZIP</p>
                      )}
                      {validZipContentError && (
                        <p style={{ color: "red" }}>Disallowed Content</p>
                      )}
                    </Col>
                    <Col md={4} className={jobTypeId !== "3" ? "" : ""}>
                      <FormInput
                        value={this.state.contactName}
                        maxLength={60}
                        type="text"
                        placeholder={
                          jobTypeId !== "3"
                            ? "Contact Name *"
                            : "Contact Name *"
                        }
                        required="required"
                        onChange={this.handleChange("contactName")}
                        onBlur={this.handleOnBlur("contactName")}
                      />
                      {contactNameError && jobTypeId !== "3" && (
                        <p style={{ color: "red" }}>Contact Name is required</p>
                      )}
                      {nameValidError && !contactNameError && (
                        <p style={{ color: "red" }}>Enter valid Name</p>
                      )}
                      {validContactContentError && (
                        <p style={{ color: "red" }}>Disallowed Content</p>
                      )}
                    </Col>
                    <Col md={4} className={jobTypeId !== "3" ? "" : ""}>
                      <FormInput
                        value={this.state.contactEmail}
                        maxLength={100}
                        type="email"
                        placeholder={
                          jobTypeId !== "3"
                            ? "Contact Email *"
                            : "Contact Email *"
                        }
                        required="required"
                        onChange={this.handleChange("contactEmail")}
                        onBlur={this.handleOnBlur("contactEmail")}
                      />
                      {emailError && jobTypeId !== "3" && (
                        <p style={{ color: "red" }}>Email is required</p>
                      )}
                      {emailValidError && !emailError && (
                        <p style={{ color: "red" }}>Enter valid Email</p>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <FormInput
                        value={telephone}
                        maxLength={11}
                        minLength={10}
                        type="text"
                        placeholder="Telephone"
                        onChange={this.handleChange("telephone")}
                        onBlur={this.handleOnBlur("telephone")}
                      />
                      {phoneValidError && (
                        <p style={{ color: "red" }}>
                          {" "}
                          Enter valid phone number
                        </p>
                      )}
                    </Col>
                    <Col md={4}>
                      {/* <Select
                        placeholder="Select Industry *"
                        options={industryList}
                        // value={industryList && industryList.filter(obj => checkval.includes(obj.value))}
                        onChange={this.handleIndustryChange}
                        isMulti
                        className="selectd"
                      /> */}
                      <Form.Group controlId="dd">
                        <Form.Control
                          as="select"
                          name="industry"
                          value={industry}
                          onChange={this.handleIndustryChange}
                          className="inputclass form-control"
                        >
                          <option value="0">Select Industry *</option>
                          {industryList &&
                            industryList.map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                      {this.state.industryError == true && (
                        <p style={{ color: "red" }}>Industry is required</p>
                      )}
                    </Col>
                    <Col md={4}>
                      <FormInput
                        value={this.state.position}
                        type="text"
                        maxLength={50}
                        placeholder="Position *"
                        required="required"
                        onChange={this.handleChange("position")}
                        onBlur={this.handleOnBlur("position")}
                      />
                      {positionError == true && (
                        <p style={{ color: "red" }}>Position is required</p>
                      )}
                      {positionValidError && !positionError && (
                        <p style={{ color: "red" }}>Enter valid Position</p>
                      )}
                      {validPositionContentError && (
                        <p style={{ color: "red" }}>Disallowed Content</p>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <FormInput
                        value={payRate}
                        maxLength={100}
                        type="text"
                        placeholder="Compensation"
                        onChange={this.handleChange("payRate")}
                        onBlur={this.handleOnBlur("payRate")}
                      />
                      {this.state.payRateError && (
                        <p style={{ color: "red" }}>Enter valid pay rate</p>
                      )}
                      {validCompensationContentError && (
                        <p style={{ color: "red" }}>Disallowed Content</p>
                      )}
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        controlId="dd"
                        className={jobTypeId !== "3" ? "" : ""}
                      >
                        <Form.Control
                          as="select"
                          name="joblength"
                          value={jobLength}
                          onChange={(e) => {
                            // this.onChangeGroup(e);
                            this.setOptions(e);
                          }}
                          className="inputclass form-control"
                        >
                          <option value="">
                            {jobTypeId !== "3"
                              ? "Job length *"
                              : "Job length *"}
                          </option>
                          <option value="Permanant">Permanant </option>
                          <option value="Contract"> Contract </option>
                        </Form.Control>
                      </Form.Group>
                      {this.state.joblengthError && jobTypeId !== "3" && (
                        <p style={{ color: "red" }}>Job length is required</p>
                      )}
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        controlId="dd"
                        className={jobTypeId !== "3" ? "" : ""}
                      >
                        <Form.Control
                          as="select"
                          name="travel"
                          value={travelRequired}
                          onChange={(e) => {
                            this.setOptions(e);
                          }}
                          className="inputclass form-control"
                        >
                          <option value="">
                            {jobTypeId !== "3" ? "Travel *" : "Travel *"}
                          </option>
                          <option value="true">Yes </option>
                          <option value="false"> No </option>
                        </Form.Control>
                      </Form.Group>
                      {this.state.travelError && jobTypeId !== "3" && (
                        <p style={{ color: "red" }}>Travel is required</p>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <Form.Control
                        as="select"
                        name="telecommute"
                        value={this.state.telecommute}
                        onChange={(e) => {
                          this.onChangeGroup(e);
                          this.setOptions(e);
                        }}
                        className="inputclass form-control"
                      >
                        <option value="">Telecommute</option>
                        <option value="true">Yes </option>
                        <option value="false"> No </option>
                      </Form.Control>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 16 }}>
                    <Col className={jobTypeId !== "3" ? "" : ""}>
                      <FormInput
                        value={this.state.description}
                        type="textarea"
                        placeholder={
                          jobTypeId !== "3" ? "Description *" : "Description *"
                        }
                        onBlur={this.handleOnBlur("description")}
                        onChange={this.handleChange("description")}
                      />
                      {this.state.descriptionError && jobTypeId !== "3" && (
                        <p style={{ color: "red" }}>Description is required</p>
                      )}
                      {validDescriptionContentError && (
                        <p style={{ color: "red" }}>Disallowed Content</p>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormInput
                        value={responsibilities}
                        type="text"
                        placeholder="Position responsibilities"
                        onChange={this.handleChange("responsibilities")}
                        onBlur={this.handleOnBlur("responsibilities")}
                      />
                      {validPositionResContentError && (
                        <p style={{ color: "red" }}>Disallowed Content</p>
                      )}
                    </Col>

                    <Col md={6}>
                      <FormInput
                        value={mQualification}
                        maxLength={256}
                        type="text"
                        placeholder="Minimum qualification"
                        onChange={this.handleChange("mQualification")}
                        onBlur={this.handleOnBlur("mQualification")}
                      />
                      {validMinimumQualContentError && (
                        <p style={{ color: "red" }}>Disallowed Content</p>
                      )}
                    </Col>
                    <Col md={6}>
                      <FormInput
                        value={pQualification}
                        maxLength={256}
                        type="text"
                        placeholder="Preferred qualification"
                        onChange={this.handleChange("pQualification")}
                        onBlur={this.handleOnBlur("pQualification")}
                      />
                      {validPreferredQualContentError && (
                        <p style={{ color: "red" }}>Disallowed Content</p>
                      )}
                    </Col>
                    <Col md={6}>
                      <FormInput
                        value={policy}
                        type="text"
                        placeholder="Employment policy"
                        onChange={this.handleChange("policy")}
                        onBlur={this.handleOnBlur("policy")}
                      />
                      {validEmpPolicyContentError && (
                        <p style={{ color: "red" }}>Disallowed Content</p>
                      )}
                    </Col>
                    {jobTypeId === "3" && (
                      <Col md={6} className={""}>
                        <FormInput
                          value={postLink}
                          maxLength={256}
                          type="text"
                          placeholder="Job Posting Link *"
                          onChange={this.handleChange("postLink")}
                          onBlur={this.handleOnBlur("postLink")}
                        />
                        {this.state.linkError && jobTypeId === "3" && (
                          <p style={{ color: "red" }}>Post Link is required</p>
                        )}
                        {this.state.linkValidError &&
                          !this.state.linkError &&
                          jobTypeId === "3" && (
                            <p style={{ color: "red" }}>
                              Enter valid post link
                            </p>
                          )}
                        {validPostLinkContentError && (
                          <p style={{ color: "red" }}>Disallowed Content</p>
                        )}
                      </Col>
                    )}
                  </Row>

                  {this.state.startDate !== undefined &&
                    this.state.startDate > new Date() && (
                      <Row>
                        {" "}
                        <Col>
                          <p className="text-danger ml-3 mt-2 text-left small">
                            You are posting a Job for future date. Click "Save
                            for Later" and job will automatically be posted on{" "}
                            {moment(this.state.startDate).format("MMM DD, Y")}
                          </p>
                        </Col>
                      </Row>
                    )}

                  <Row>
                    <Col className="mt-4 post-job-footer-btns">
                      {/* <Link to={`${process.env.PUBLIC_URL}/jobslist`} className="text-decoration-none"> */}
                      {this.state.disablePost && (
                        <Buttons
                          disabled
                          variant="warning"
                          title="Post job"
                          type="button"
                          size="xs"
                          color="white"
                          onClick={this.handleSubmit}
                        ></Buttons>
                      )}
                      {!this.state.disablePost && (
                        <Buttons
                          variant="warning"
                          title="Post job"
                          type="button"
                          size="xs"
                          color="white"
                          onClick={this.handleSubmit}
                        ></Buttons>
                      )}
                      {/* </Link> */}
                      {this.state.disableDraft && (
                        <Buttons
                          disabled
                          variant="secondary"
                          title="Save for later"
                          onClick={this.handleSubmit}
                          type="button"
                          size="xs"
                          color="white"
                          marginLeft="ml-3"
                        ></Buttons>
                      )}
                      {!this.state.disableDraft && (
                        <Buttons
                          variant="secondary"
                          title="Save for later"
                          onClick={this.handleSubmit}
                          type="button"
                          size="xs"
                          color="white"
                          marginLeft="ml-3"
                        ></Buttons>
                      )}
                      <Buttons
                        variant="light"
                        title="Cancel"
                        onClick={() => {
                          window.location.href = `${process.env.PUBLIC_URL}/#/jobslist`;
                        }}
                        type="button"
                        size="xs"
                        color="white"
                        marginLeft="ml-3"
                      ></Buttons>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}
export default connect(null, { postJob })(PostJob);
