/* eslint-disable eqeqeq */

import React, { useState, Component } from "react";
import { Col, Row, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import FormInput from "./Shared/FormInput";
import Buttons from "./Shared/Button";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Validators } from "../Utilities/Validator";
import Dropdown from "./Shared/Dropdown";
import Select from "react-select";
import { connect } from "react-redux";
import axios from "axios";
import { addUser } from "../Redux/Jobs/Action";
import { getGroups } from "../Services/GetFields";
import { Roles } from "../Utilities/Helper";
import help_icon from "../assests/img/help_icon.png";
import { getData } from "../Utilities/StorageHelper";

// const options = [
//   { label: "Automobile", value: 1 },
//   { label: "Finance", value: 2 },
//   { label: "Healthcare", value: 3 },
// ];

class SearchJob extends Component {
  state = {
    position: "",
    city: "",
    fromDate: null,
    toDate: null,
    state: "",
    company: "",
    industryid: "",
    jobseekerid: "",
  };

  // DatePicker settings

  constructor(props) {
    super(props);
    this.minDate = new moment(props.minDate);
    this.maxDate = new moment(props.maxDate);
    this.state = {
      fromDate: props.minDate,
      toDate: props.maxDate,
      jobseekerid: "",
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
    // showClearSearch();
  };

  handleDateChange(date) {
    this.setState({ fromDate: date });
  }

  handelindustrychange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handeljobseekerchange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handleGroupChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handleStatusChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handleEndDateChange(date) {
    this.setState({ toDate: date });
  }

  clearFilter() {
    this.setState({
      jobstatusid: 1,
      groupid: "",
      position: "",
      city: "",
      fromDate: null,
      toDate: null,
      state: "",
      company: "",
      industryid: "0",
      jobseekerid: "",
    });
    this.props.searchParam("");
  }
  // showClearSearch(){
  //   for (var key in this.state) {
  //     if (this.state.hasOwnProperty(key)) {
  //       if (
  //         this.state[key] !== "" &&
  //         this.state[key] !== null &&
  //         this.state[key] !== undefined
  //       ) {

  //       }
  //     }
  //   }
  // }
  renderTooltip = (props) => (
    <Tooltip
      id="button-tooltip"
      {...props}
      // style={{
      //   backgroundColor: 'black',
      //   padding: '2px 10px',
      //   color: 'black',
      //   borderRadius: 3,
      //   ...props.style,
      // }}
    >
      {this.state.jobseekerid !== ""
        ? "Default Filter is job seeker selected industries"
        : this.props.stmindustries}
    </Tooltip>
  );

  handleSubmit = async (event) => {
    event.preventDefault();
    let searchParam = "";
    for (var key in this.state) {
      if (this.state.hasOwnProperty(key)) {
        if (
          this.state[key] !== "" &&
          this.state[key] !== null &&
          this.state[key] !== undefined
        ) {
          if (key === "fromDate" || key === "toDate") {
            var selectedDate = new Date(this.state[key]);
            var newDate = moment(selectedDate).format("YYYY/MM/DD");
            searchParam += `&${key}=${newDate}`;
          } else {
            searchParam += `&${key}=${this.state[key]}`;
          }
        }
      }
    }
    this.props.searchParam(searchParam);
  };

  render() {
    const {
      position,
      postDate,
      location,
      companyName,
      city,
      state,
      jobseekerid = "",
    } = this.state;

    let array;
    if (this.props.statusIdList) {
      array = this.props.statusIdList.filter(
        (element) => element.jobStatusName !== "Published Jobs"
      );
    }
    return (
      <div className="search mb-4">
        <Form noValidate>
          <Row>
            <Col md={3}>
              <FormInput
                value={this.state.position}
                type="text"
                placeholder="Position"
                // icon="fas fa-search"
                // withIcon="with-icon"
                onChange={this.handleChange("position")}
              />
              {this.state.positionError == true && (
                <p style={{ fontSize: "10px", color: "red" }}>
                  Position is required
                </p>
              )}
            </Col>

            <Col md={3}>
              <FormInput
                value={this.state.company}
                type="text"
                placeholder="Company Name"
                onChange={this.handleChange("company")}
              />
              {this.state.companyError == true && (
                <p style={{ fontSize: "10px", color: "red" }}>
                  Company is required
                </p>
              )}
            </Col>

            <Col md={3}>
              <div className="form-group">
                <DatePicker
                  selected={this.state.fromDate}
                  onChange={this.handleDateChange}
                  // minDate={this.minDate}
                  value={this.props.fromDate}
                  placeholderText="Job Post Start Date"
                  className="inputclass form-control"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                />
              </div>
              {this.state.fromDateError == true && (
                <p style={{ fontSize: "10px", color: "red" }}>
                  From Date is required
                </p>
              )}
            </Col>
            <Col md={3}>
              <div className="form-group">
                <DatePicker
                  selected={this.state.toDate}
                  onChange={this.handleEndDateChange}
                  // minDate={this.maxDate}
                  value={this.props.toDate}
                  placeholderText="Job Post End Date"
                  className="inputclass form-control"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                />
              </div>
              {this.state.toDateError == true && (
                <p style={{ fontSize: "10px", color: "red" }}>
                  To date is required
                </p>
              )}
            </Col>
            {/* <Col md={3}>
            <Select placeholder="Industry" options={options} isMulti  className="selectd" />
                         </Col> */}
            <Col md={3}>
              <FormInput
                value={this.state.city}
                type="text"
                placeholder="City"
                onChange={this.handleChange("city")}
              />
              {this.state.cityError == true && (
                <p style={{ fontSize: "10px", color: "red" }}>
                  City is required
                </p>
              )}
            </Col>
            <Col md={3}>
              <FormInput
                value={this.state.state}
                type="text"
                placeholder="State"
                onChange={this.handleChange("state")}
              />
              {this.state.stateError == true && (
                <p style={{ fontSize: "10px", color: "red" }}>
                  State is required
                </p>
              )}
            </Col>
            <Col md={3} className="info-select-dropdown">
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  value={this.state.industryid}
                  as="select"
                  className="inputclass form-control"
                  onChange={this.handelindustrychange("industryid")}
                >
                  {" "}
                  <option value="0">Select Industry</option>
                  {this.props.industriesList.map((item) => (
                    <option key={item.industryId} value={item.industryId}>
                      {item.industryName}
                    </option>
                  ))}
                </Form.Control>{" "}
                {getData("role") === Roles.stm && (
                  <span className="info-i">
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={this.renderTooltip}
                    >
                      <img
                        style={{
                          width: "15px",
                          bottom: "30px",
                          position: "absolute",
                          marginLeft: "4px",
                        }}
                        src={help_icon}
                      />
                    </OverlayTrigger>
                  </span>
                )}
              </Form.Group>
            </Col>

            {getData("role") === Roles.stm && (
              <Col md={3} className="">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Control
                    value={this.state.jobseekerid}
                    as="select"
                    className="inputclass form-control"
                    onChange={this.handeljobseekerchange("jobseekerid")}
                  >
                    <option value="">Select Job Seeker</option>
                    {this.props.jobSeekerList.map((item) => (
                      <option key={item.jobSeekerId} value={item.jobSeekerId}>
                        {item.firstName + " " + item.lastName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            )}
            <Col md={3} className="draftdropdown">
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  as="select"
                  value={this.state.jobstatusid}
                  className="inputclass form-control"
                  onChange={this.handleStatusChange("jobstatusid")}
                >
                  <option value="1">Published Jobs</option>
                  {array &&
                    array.map((item) => (
                      <option key={item.jobStatusId} value={item.jobStatusId}>
                        {item.jobStatusName}
                      </option>
                    ))}
                  <option value="0">All Jobs</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3} className="draftdropdown">
              <Form.Group controlId="dd">
                <Form.Control
                  as="select"
                  value={this.state.groupid}
                  className="inputclass form-control"
                  onChange={this.handleGroupChange("groupid")}
                >
                  <option value="0">Select Group</option>
                  {this.props.groupsList &&
                    this.props.groupsList.map((item) => (
                      <option key={item.groupId} value={item.groupId}>
                        {item.groupName}
                      </option>
                    ))}
                  <option value="0">All Groups</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="col-auto text-right">
              <Buttons
                variant="warning"
                title="Search"
                type="submit"
                size="xs"
                color="white"
                onClick={this.handleSubmit}
              ></Buttons>
              <span style={{ marginLeft: 18 }}></span>
              <Buttons
                variant="warning"
                title="Reset"
                size="xs"
                color="white"
                onClick={(e) => this.clearFilter(e)}
              ></Buttons>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default connect(null, { addUser })(SearchJob);
